import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useWatch } from 'react-hook-form-mui';
import { Box, Unstable_Grid2 as Grid } from '@mui/material';
import PropTypes from 'prop-types';
import SelectLabel from './SelectLabel';

const PublicationArticleSelect = (props) => {
    const {
        control,
        linkedArticle,
        linkedPage,
        publicationArticles,
        children,
        sx
    } = props;

    const [options1, setOptions1] = useState([]);
    const [options2, setOptions2] = useState([]);
    const { formatMessage } = useIntl();

    const linkedArticleId = useWatch({ control, name: 'linkedArticle' });

    useEffect(() => {
        const opts1 = [];
        const opts2 = [];

        Object.keys(publicationArticles).forEach((key) => {
            const value = publicationArticles[key];

            opts1.push({
                key,
                value: key,
                groupHead: true,
            });

            value.forEach((pubArticle) => {
                opts1.push({
                    key: pubArticle.id,
                    value: pubArticle.fullName,
                });

                opts2[pubArticle.id] = Array
                    .from(Array(pubArticle.quantityPages))
                    .map((_, i) => ({ key: i + 1, value: i + 1, }));
            });
        });

        setOptions1(opts1);
        setOptions2(opts2);
    }, [publicationArticles]);

    return (
        <Box sx={{ ...sx }}>
            <Grid
                alignItems='center'
                container
                spacing={3}
            >
                <Grid item sx={{ display: 'flex', justifyContent: 'center' }} xs={6}>
                    <SelectLabel
                        control={control}
                        defaultValue={linkedArticle}
                        disableAdditionalLabel
                        label={formatMessage({
                            defaultMessage: 'Select a publication',
                            id: 'highlights.form.selectPublication'
                        })}
                        name='linkedArticle'
                        options={options1}
                        width='100%'
                    />
                </Grid>
                <Grid item sx={{ display: 'flex', justifyContent: 'center' }} xs={6}>
                    <SelectLabel
                        control={control}
                        defaultValue={linkedPage}
                        disableAdditionalLabel
                        disabled={!linkedArticleId}
                        label={formatMessage({
                            defaultMessage: 'Select a page',
                            id: 'highlights.form.selectPage'
                        })}
                        name='linkedPage'
                        options={options2[linkedArticle] || []}
                        width='100%'
                    />
                </Grid>
            </Grid>
            {children}
        </Box>
    );
};

PublicationArticleSelect.propTypes = {
    children: PropTypes.node,
    control: PropTypes.object.isRequired,
    linkedArticle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    linkedPage: PropTypes.oneOfType([PropTypes.number]),
    publicationArticles: PropTypes.object.isRequired,
    sx: PropTypes.object
};

PublicationArticleSelect.defaultProps = {
    linkedArticle: null,
    linkedPage: null,
    children: [],
    sx: null,
};

export default PublicationArticleSelect;
