import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const CheckboxDataGridSelect = (props) => {
    const {
        data,
        columns,
        onChange,
        defaultValues
    } = props;

    const [selectedRows, setSelectedRows] = useState([]);

    const handleRowSelection = (selectedIds) => {
        setSelectedRows(selectedIds);
        onChange(selectedIds);
    };

    useEffect(() => {
        setSelectedRows(defaultValues);
    }, []);

    return (
        <div style={{ height: 400, width: '100%' }}>
            <DataGrid
                checkboxSelection
                columns={columns}
                disableColumnMenu
                onRowSelectionModelChange={(ids) => handleRowSelection(ids)}
                rowHeight={60}
                rows={data}
                rowSelectionModel={selectedRows}
                sx={({ palette }) => ({
                    border: 'none',
                    // '& .MuiDataGrid-row:nth-of-type(even)': {
                    //     backgroundColor: '#FAFBFC',
                    // },
                    '& .MuiDataGrid-row[aria-selected="true"]': {
                        backgroundColor: 'common.white',
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        borderBottom: `1px solid ${palette.border.listItem}`,
                    },
                    '& .MuiDataGrid-row:hover': {
                        backgroundColor: 'background.lightGrayForm',
                        cursor: 'pointer',
                    },
                    '& .MuiDataGrid-row > *': {
                        border: 'none',
                    },
                    '& .MuiDataGrid-footerContainer': {
                        border: 'none',
                    },
                    '& .MuiDataGrid-columnHeader:focus': {
                        outline: 'none',
                    },
                    '& .MuiDataGrid-cell:focus': {
                        outline: 'none',
                    },
                })}
            />
        </div>
    );
};

CheckboxDataGridSelect.propTypes = {
    columns: PropTypes.array,
    data: PropTypes.array,
    defaultValues: PropTypes.array,
    onChange: PropTypes.func,
};

CheckboxDataGridSelect.defaultProps = {
    columns: [],
    data: [],
    defaultValues: [],
    onChange: () => {
    }
};

export default CheckboxDataGridSelect;
