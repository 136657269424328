import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import {
    Box,
    Button,
    Divider,
    Typography,
    Unstable_Grid2 as Grid
} from '@mui/material';
import {
    DeleteOutlined as DeleteIcon
} from '@mui/icons-material';
import { ChromePicker } from 'react-color';

import { useTheme } from '@mui/material/styles';
import RequestButton from '../../../components/RequestButton';
import { useAccountInfo } from '../../../contexts/AccountInfo';
import { digiMagsDefaultColors } from '../../../themes/default';

const ColorPicker = ({
    category, item, data, changeColor
}) => {
    const [displayColorPicker, setDisplayColorPicker] = React.useState(false);
    const { palette } = useTheme();
    const defaultColor = category
        ? digiMagsDefaultColors[category][item]
        : digiMagsDefaultColors[item];
    const currentColor = category
        ? data?.colors[category][item]
        : data?.colors[item];

    const getBorderStyle = () => (currentColor === palette.common.white
        ? `1px solid ${palette.border.topBar}`
        : 'none');

    return (
        <Grid alignItems='center' display='inline-flex'>
            <Box
                onClick={() => setDisplayColorPicker(!displayColorPicker)}
                sx={{
                    backgroundColor: currentColor,
                    borderRadius: 0.5,
                    border: getBorderStyle,
                    height: 32,
                    width: 84
                }}
            />
            <Button
                onClick={() => changeColor(category, item, defaultColor)}
                startIcon={<DeleteIcon />}
                variant='onlyIcon'
            />
            { displayColorPicker && (
                <Box sx={{ position: 'absolute', zIndex: 5 }}>
                    <Box
                        onClick={() => setDisplayColorPicker(false)}
                        sx={{
                            position: 'fixed', top: 0, right: 0, bottom: 0, left: 0
                        }}
                    />
                    <ChromePicker
                        color={currentColor}
                        disableAlpha
                        onChangeComplete={(color) => changeColor(category, item, color.hex)}
                    />
                </Box>
            )}
        </Grid>
    );
};

ColorPicker.propTypes = {
    category: PropTypes.string,
    changeColor: PropTypes.func.isRequired,
    data: PropTypes.object,
    item: PropTypes.string.isRequired
};

ColorPicker.defaultProps = {
    category: null,
    data: null
};

const PreviewBox = ({
    backgroundColor, fontColor, padding, children
}) => (
    <Box
        sx={{
            backgroundColor,
            color: fontColor,
            minWidth: 380,
            px: padding,
            py: 2,
            borderRadius: 1,
            textAlign: 'center'
        }}
    >
        {children}
    </Box>
);

PreviewBox.propTypes = {
    backgroundColor: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    fontColor: PropTypes.string.isRequired,
    padding: PropTypes.number
};

PreviewBox.defaultProps = {
    padding: 7
};

const Color = (props) => {
    const { getData, submitData } = props;
    const [data, setData] = React.useState(null);
    const { palette } = useTheme();
    const { triggerReload } = useAccountInfo();

    React.useEffect(() => {
        getData().then(setData);
    }, []);

    const changeColor = ((category, item, newColor) => {
        setData((prevData) => {
            const newData = { ...prevData };
            if (category) newData.colors[category][item] = newColor;
            else newData.colors[item] = newColor;
            return newData;
        });
    });

    return (
        <Grid className='alternate-rows' container direction='column' spacing={3} sx={{ px: 2 }} xs={12}>
            <Grid container sx={{ my: 0.5 }}>
                <Grid xs={4}>
                    <Typography variant='sectionHeader'>
                        <FormattedMessage
                            defaultMessage='General'
                            id='newsstand.layout.colors.general'
                        />
                    </Typography>
                </Grid>
                <Grid container flexWrap='nowrap' xs={8}>
                    <Grid container direction='column' xs={6}>
                        <Grid>
                            <Typography variant='layoutOption'>
                                <FormattedMessage
                                    defaultMessage='Background'
                                    id='newsstand.layout.colors.background'
                                />
                            </Typography>
                        </Grid>
                        <ColorPicker
                            category='general'
                            changeColor={changeColor}
                            data={data}
                            item='background'
                        />
                    </Grid>
                    <Grid container direction='column' xs={6}>
                        <Grid>
                            <Typography variant='layoutOption'>
                                <FormattedMessage
                                    defaultMessage='Font'
                                    id='newsstand.layout.colors.font'
                                />
                            </Typography>
                        </Grid>
                        <ColorPicker
                            category='general'
                            changeColor={changeColor}
                            data={data}
                            item='font'
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container sx={{ my: 0.5 }}>
                <Grid xs={4}>
                    <Typography variant='sectionHeader'>
                        <FormattedMessage
                            defaultMessage='Header'
                            id='newsstand.layout.colors.header'
                        />
                    </Typography>
                </Grid>
                <Grid container flexWrap='nowrap' xs={8}>
                    <Grid container direction='column' xs={6}>
                        <Grid>
                            <Typography variant='layoutOption'>
                                <FormattedMessage
                                    defaultMessage='Background'
                                    id='newsstand.layout.colors.background'
                                />
                            </Typography>
                        </Grid>
                        <ColorPicker
                            category='header'
                            changeColor={changeColor}
                            data={data}
                            item='background'
                        />
                    </Grid>
                    <Grid container direction='column' xs={6}>
                        <Grid>
                            <Typography variant='layoutOption'>
                                <FormattedMessage
                                    defaultMessage='Font'
                                    id='newsstand.layout.colors.font'
                                />
                            </Typography>
                        </Grid>
                        <ColorPicker
                            category='header'
                            changeColor={changeColor}
                            data={data}
                            item='font'
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container sx={{ my: 0.5 }}>
                <Grid xs={4}>
                    <Typography variant='sectionHeader'>
                        <FormattedMessage
                            defaultMessage='Footer'
                            id='newsstand.layout.colors.footer'
                        />
                    </Typography>
                </Grid>
                <Grid container flexWrap='nowrap' xs={8}>
                    <Grid container direction='column' xs={6}>
                        <Grid>
                            <Typography variant='layoutOption'>
                                <FormattedMessage
                                    defaultMessage='Background'
                                    id='newsstand.layout.colors.background'
                                />
                            </Typography>
                        </Grid>
                        <ColorPicker
                            category='footer'
                            changeColor={changeColor}
                            data={data}
                            item='background'
                        />
                    </Grid>
                    <Grid container direction='column' xs={6}>
                        <Grid>
                            <Typography variant='layoutOption'>
                                <FormattedMessage
                                    defaultMessage='Font'
                                    id='newsstand.layout.colors.font'
                                />
                            </Typography>
                        </Grid>
                        <ColorPicker
                            category='footer'
                            changeColor={changeColor}
                            data={data}
                            item='font'
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container direction='column' sx={{ my: 0.5 }}>
                <Grid container>
                    <Grid xs={4}>
                        <Typography variant='sectionHeader'>
                            <FormattedMessage
                                defaultMessage='Container'
                                id='newsstand.layout.colors.container'
                            />
                        </Typography>
                    </Grid>
                    <Grid container flexWrap='nowrap' xs={8}>
                        <Grid container direction='column' xs={6}>
                            <Grid>
                                <Typography variant='layoutOption'>
                                    <FormattedMessage
                                        defaultMessage='Background'
                                        id='newsstand.layout.colors.background'
                                    />
                                </Typography>
                            </Grid>
                            <ColorPicker
                                category='container'
                                changeColor={changeColor}
                                data={data}
                                item='background'
                            />
                        </Grid>
                        <Grid container direction='column' xs={6}>
                            <Grid>
                                <Typography variant='layoutOption'>
                                    <FormattedMessage
                                        defaultMessage='Font'
                                        id='newsstand.layout.colors.font'
                                    />
                                </Typography>
                            </Grid>
                            <ColorPicker
                                category='container'
                                changeColor={changeColor}
                                data={data}
                                item='font'
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid xs={8} xsOffset={4}>
                        <PreviewBox
                            backgroundColor={data?.colors.general.background || palette.background.colorPicker}
                            fontColor={data?.colors.container.font || palette.text.default}
                        >
                            <Box
                                sx={{
                                    backgroundColor: data?.colors.container.background
                                        || palette.background.colorPicker,
                                    p: 1,
                                    borderRadius: 1
                                }}
                            >
                                <Typography sx={{ fontSize: 13 }}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                    sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                </Typography>
                            </Box>
                        </PreviewBox>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container direction='column' sx={{ my: 0.5 }}>
                <Grid container>
                    <Grid xs={4}>
                        <Typography variant='sectionHeader'>
                            <FormattedMessage
                                defaultMessage='Highlight'
                                id='newsstand.layout.colors.highlight'
                            />
                        </Typography>
                    </Grid>
                    <Grid container flexWrap='nowrap' xs={8}>
                        <Grid container direction='column' xs={6}>
                            <Grid>
                                <Typography variant='layoutOption'>
                                    <FormattedMessage
                                        defaultMessage='Color'
                                        id='newsstand.layout.colors.color'
                                    />
                                </Typography>
                            </Grid>
                            <ColorPicker
                                changeColor={changeColor}
                                data={data}
                                item='highlight'
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid xs={8} xsOffset={4}>
                        <PreviewBox
                            backgroundColor={data?.colors.general.background || palette.background.colorPicker}
                            fontColor={data?.colors.general.font || palette.text.default}
                        >
                            <Typography sx={{ fontSize: 13 }}>
                                Lorem ipsusm&nbsp;
                                <Typography
                                    component='span'
                                    sx={{ fontSize: 13, color: data?.colors.highlight, fontWeight: 600 }}
                                >
                                    dolor&nbsp;
                                </Typography>
                                sit amet, consectetur adipiscing elit,
                                sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                            </Typography>
                        </PreviewBox>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container direction='column' sx={{ mt: 0.5, mb: 2 }}>
                <Grid container>
                    <Grid xs={4}>
                        <Typography variant='sectionHeader'>
                            <FormattedMessage
                                defaultMessage='Line'
                                id='newsstand.layout.colors.line'
                            />
                        </Typography>
                    </Grid>
                    <Grid container flexWrap='nowrap' xs={8}>
                        <Grid container direction='column' xs={6}>
                            <Grid>
                                <Typography variant='layoutOption'>
                                    <FormattedMessage
                                        defaultMessage='Color'
                                        id='newsstand.layout.colors.color'
                                    />
                                </Typography>
                            </Grid>
                            <ColorPicker
                                changeColor={changeColor}
                                data={data}
                                item='line'
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid xs={8} xsOffset={4}>
                        <PreviewBox
                            backgroundColor={data?.colors.general.background || palette.background.colorPicker}
                            fontColor={data?.colors.general.font || palette.text.default}
                            padding={1}
                        >
                            <Typography sx={{ fontSize: 13 }}>
                                Lorem ipsum dolor sit amet, consectetur
                            </Typography>
                            <Divider
                                sx={{
                                    borderColor: data?.colors.line,
                                    my: 1.5
                                }}
                            />
                            <Typography sx={{ fontSize: 13 }}>
                                Sed do eiusmod tempor incididunt
                            </Typography>
                        </PreviewBox>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container sx={{ zIndex: 2 }}>
                <Grid
                    display='flex'
                    justifyContent='flex-end'
                    xs={12}
                >
                    <RequestButton
                        onSuccess={triggerReload}
                        sendData={() => submitData(data)}
                        size='large'
                        type='submit'
                        variant='contained'
                    >
                        <FormattedMessage defaultMessage='Update' id='common.update' />
                    </RequestButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

Color.propTypes = {
    getData: PropTypes.func.isRequired,
    submitData: PropTypes.func.isRequired
};

export default Color;
