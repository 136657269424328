import React from 'react';
import { Controller } from 'react-hook-form-mui';
import { Slider } from '@mui/material';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

export default function CustomSlider({
    control, name, min, max
}) {
    const { formatMessage } = useIntl();
    const getSliderValueText = (value) => `${value}`;

    const sliderMarks = [
        {
            value: min,
            label: `${min}%`,
        },
        {
            value: max,
            label: `${max}%`,
        },
    ];

    return (
        <Controller
            control={control}
            name={name}
            render={({ field }) => {
                if (!field.value) return null;
                return (
                    <Slider
                        getAriaValueText={getSliderValueText}
                        label={formatMessage({ defaultMessage: 'Size', id: 'common.image.size' })}
                        marks={sliderMarks}
                        max={max}
                        min={min}
                        onChangeCommitted={(e, value) => field.onChange(value)}
                        size='small'
                        value={field.value || 100}
                        valueLabelDisplay='on'
                        valueLabelFormat={getSliderValueText}
                    />
                );
            }}
        />
    );
}

CustomSlider.propTypes = {
    control: PropTypes.object.isRequired,
    max: PropTypes.number.isRequired,
    min: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
};
