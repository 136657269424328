import React from 'react';
import PropTypes from 'prop-types';

import {
    FormControl,
    InputLabel,
    MenuItem,
    Typography,
    Select
} from '@mui/material';

import { Controller } from 'react-hook-form-mui';

const CustomMenuItem = ({ children, groupHead, ...props }) => {
    const { sx } = props;
    let disabled = false;
    let newSx = { ...sx, opacity: 0.5 };

    if (groupHead) {
        disabled = true;
        newSx = { ...newSx, fontWeight: 'bold', opacity: 1 };
    }

    return (
        <MenuItem
            key={props.value}
            disabled={disabled}
            {...props}
            sx={newSx}
        >
            {children}
        </MenuItem>
    );
};

CustomMenuItem.propTypes = {
    children: PropTypes.node,
    groupHead: PropTypes.bool,
    sx: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

CustomMenuItem.defaultProps = {
    children: null,
    groupHead: false,
    sx: {},
    value: null,
};

export default function SelectLabel(props) {
    const {
        control,
        defaultValue,
        label,
        disableAdditionalLabel,
        options,
        name,
        disabled,
        width
    } = props;

    return (
        <>
            {!disableAdditionalLabel && (<Typography>{label}</Typography>)}
            <FormControl sx={{ width }}>
                <InputLabel
                    id={`${name}-selectlabel`}
                    shrink={defaultValue !== null}
                    sx={{
                        backgroundColor: disabled ? 'unset' : 'common.white',
                        color: disabled ? 'text.mutedLightGray' : 'inherit',
                    }}
                >
                    {label}
                </InputLabel>
                <Controller
                    control={control}
                    defaultValue={defaultValue}
                    id={name}
                    name={name}
                    render={({ field }) => (
                        <Select
                            labelId={`${name}-selectlabel`}
                            {...field}
                            disabled={disabled}
                            onChange={(e) => field.onChange(e.target.value)}
                            sx={{
                                minWidth: '100%',
                                width: '100%',
                                '& .Mui-disabled': {
                                    cursor: 'not-allowed', // Ensure cursor style is applied to the disabled state
                                },
                            }}
                            value={field.value || defaultValue}
                        >
                            {options.map((option) => (
                                <CustomMenuItem
                                    key={option.key}
                                    groupHead={option.groupHead}
                                    value={option.key}
                                >
                                    {option.value}
                                </CustomMenuItem>
                            ))}
                        </Select>
                    )}
                />
            </FormControl>
        </>
    );
}

SelectLabel.propTypes = {
    control: PropTypes.object.isRequired,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disableAdditionalLabel: PropTypes.bool,
    disabled: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    sx: PropTypes.object,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

SelectLabel.defaultProps = {
    defaultValue: '',
    width: 200,
    disableAdditionalLabel: false,
    sx: {},
    disabled: false,
};
