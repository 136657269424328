import React from 'react';
import PropTypes from 'prop-types';

import { Unstable_Grid2 as Grid } from '@mui/material';
import { useIntl } from 'react-intl';

import ApiUtils from '../base/Api';
import { useAuth } from '../../contexts/Auth';

import TabsPage from '../../modules/TabsPage';
import SettingsForm from './settings/Settings';
import AccessDataForm from './settings/AccessData';
import Phone from '../../modules/Phone';
import { formatDate } from '../../utils/utils';

const SettingsView = (props) => {
    const {
        downloadAccessData,
        downloadRegistrations,
        retrieveData,
        submitAccessData,
        submitSettings
    } = props;
    const { formatMessage } = useIntl();

    return (
        <Grid container spacing={3}>
            <Grid alignSelf='flex-start' container xs={8}>
                <TabsPage
                    pages={[
                        {
                            name: formatMessage({ defaultMessage: 'Settings', id: 'newsstand.settings' }),
                            component: (
                                <SettingsForm
                                    getData={retrieveData}
                                    submitData={submitSettings}
                                />
                            )
                        },
                        {
                            name: formatMessage({
                                defaultMessage: 'Subscriber and User Data',
                                id: 'newsstand.accessData'
                            }),
                            component: (
                                <AccessDataForm
                                    downloadData={downloadAccessData}
                                    downloadRegistrations={downloadRegistrations}
                                    getData={retrieveData}
                                    submitData={submitAccessData}
                                />
                            )
                        }
                    ]}
                />
            </Grid>
            <Grid xs={4}>
                <Phone />
            </Grid>
        </Grid>
    );
};

SettingsView.propTypes = {
    downloadAccessData: PropTypes.func.isRequired,
    downloadRegistrations: PropTypes.func.isRequired,
    retrieveData: PropTypes.func.isRequired,
    submitAccessData: PropTypes.func.isRequired,
    submitSettings: PropTypes.func.isRequired,
};

const Settings = () => {
    const authContext = useAuth();
    const intlContext = useIntl();
    const { Api } = ApiUtils.useApi(authContext, intlContext);

    const retrieveData = async () => {
        const response = await Api.getNewsstandSettings();
        if (response.success && response.digiDepartment) {
            const data = response.digiDepartment;
            /*
                domain: 'Touchtree.com',
                disableSpeech: true,
                emailRequired: true,
                immediateAccess: true,
                enableEmailRegistration: true,
                immediateEmailAccess: false,
                privacyPolicy:
            */
            const res = {
                domain: data.relationData?.domainName,
                disableSpeech: data.relationData.hasSpeechBlock,
                enableAdverts: data.relationData.hasAdvert,
                emailRequired: data.hasEmailAccess,
                immediateAccess: !data.hasRegistrationDirectToken,
                enableEmailRegistration: data.hasEmailRegistration,
                immediateEmailAccess: data.hasEmailAccessDirectToken,
                privacyPolicy: data.privacyPolicyContent,
                uploads: data.emailAccessUploadLog?.map((upload) => ({
                    id: upload.id,
                    date: (upload.dateCreated && formatDate(upload.dateCreated)) || null,
                    name: upload.fileName,
                    entries: upload.quantity,
                })) || [{
                    id: 0,
                    date: 'N/A',
                    name: 'Unknown',
                    entries: 0,
                }]
            };
            return res;
        }
        return null;
    };

    const submitDataSettings = async (data) => {
        const toSend = {
            domainName: data.domain,
            hasSpeechBlock: data.disableSpeech,
            hasEmailAccess: data.emailRequired,
            hasAdvert: data.enableAdverts,
            hasRegistrationDirectToken: !data.immediateAccess,
            hasEmailRegistration: data.enableEmailRegistration,
            hasEmailAccessDirectToken: data.immediateEmailAccess,
            privacyPolicyContent: data.privacyPolicy,
        };
        const ret = await Api.updateNewsstandSettings(toSend);
        return ret;
    };

    const submitAccessData = async (fileName, fileData) => {
        console.log(fileName, fileData);
        const ret = await Api.uploadEmailsAccessData({
            fileName,
            fileData
        });
        return ret;
    };

    const downloadAccessData = async () => {
        await Api.downloadEmailsAccessData();
    };

    const downloadRegistrations = async () => {
        await Api.downloadDepartmentRegistrants();
    };

    return (
        <SettingsView
            downloadAccessData={downloadAccessData}
            downloadRegistrations={downloadRegistrations}
            retrieveData={retrieveData}
            submitAccessData={submitAccessData}
            submitSettings={submitDataSettings}
        />
    );
};

export default Settings;
