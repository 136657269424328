import React from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import { Button } from '@mui/material';

import FileButton from './FileButton';

export default function FileInput(props) {
    const {
        name,
        control,
        disabled,
        required,
        sx,
        validate,
        maxFileSize,
    } = props;
    let { children } = props;
    const [selectName, setSelectName] = React.useState(null);
    const { field, fieldState: { invalid } } = useController({
        name,
        control,
        disabled,
        rules: {
            required,
            validate
        }
    });
    const { value } = field;
    // Children from props or fileName
    if (value && selectName) {
        children = selectName;
    }

    return (
        <FileButton
            {...props}
            handleUpload={(fileName, fileData) => {
                setSelectName(fileName);
                field.onChange({ name: fileName, data: fileData });
            }}
            maxFileSize={maxFileSize}
            sx={{
                ...sx,
                // Add error border if invalid
                ...(invalid && { border: '1px solid red' })
            }}
            value={value}
        >
            {children}
        </FileButton>
    );
}

FileInput.propTypes = {
    ...Button.propTypes,
    accept: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    control: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    maxFileSize: PropTypes.number,
    name: PropTypes.string.isRequired,
    required: PropTypes.bool,
    validate: PropTypes.func
};

FileInput.defaultProps = {
    ...Button.defaultProps,
    accept: '*',
    maxFileSize: 10,
};
