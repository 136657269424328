import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import {
    Unstable_Grid2 as Grid,
    Avatar,
    ListItemSecondaryAction,
    IconButton,
    Popper,
    Paper,
    ClickAwayListener,
    MenuList,
    MenuItem,
    Chip,
    ListItem,
    List,
    Button,
    ListItemText,
    AvatarGroup,
    Fade,
} from '@mui/material';

import {
    MoreVert as MenuIcon,
    QrCodeRounded as QrCodeIcon
} from '@mui/icons-material';

import { useIntl, FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useStatus } from './base/Status';
import { useDialog, DialogTypes } from './base/Dialog';
import { useApi, api } from './base/Request';

import QrModal from '../modules/QrModal';
import campaignTypes from './campaigns/campaignTypes';
import { useSortFilterSearch } from '../contexts/SortFilterSearch';
import StatusFilterSelect from '../components/StatusFilterSelect';
import FadeInBox from '../components/Animation/FadeInBox';
import CenteredCircularProgress from '../components/CenteredCircularProgress';
import DefaultFormattedDate from '../components/DefaultFormattedDate';

const Campaigns = () => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const expiredIdsRef = useRef([]);
    const { setFilter, filter, clearAllFilters } = useSortFilterSearch('campaigns');

    const { request } = useApi();
    const { createDialog } = useDialog();

    const getCampaignsFromResponse = (response) => {
        const campaigns = response?.digiCampaigns;

        if (response.success && Array.isArray(campaigns)) {
            return campaigns;
        }

        console.error('Error retrieving campaigns data');

        return [];
    };

    const retrieveData = async () => {
        setIsLoading(true);
        const response = await request(api.getCampaigns).send();

        expiredIdsRef.current.splice(0);

        const campaigns = await Promise.all(getCampaignsFromResponse(response).map(async (campaign) => {
            let status = 'active';

            if (campaign.isLocked) {
                status = 'locked';
            } else if (campaign.status === 'expired') {
                status = 'expired';
            } else if (campaign.dateStart) {
                const startOfDay = dayjs.unix(campaign.dateStart).startOf('day');

                if (dayjs().isBefore(startOfDay)) {
                    status = 'draft';
                }
            } else if (campaign.dateFinal) {
                const endOfDay = dayjs.unix(campaign.dateFinal).endOf('day');

                if (dayjs().isAfter(endOfDay)) {
                    status = 'expired';
                    expiredIdsRef.current.push(campaign.id);
                }
            }

            const advertIds = Array.isArray(campaign?.banners) ? campaign?.banners : [];

            return {
                id: campaign.id,
                name: campaign.label,
                startDate: campaign.dateStart && dayjs.unix(campaign.dateStart).toDate(),
                endDate: campaign.dateFinal && dayjs.unix(campaign.dateFinal).toDate(),
                isLocked: campaign.isLocked,
                type: campaign.type,
                url: campaign.accessDigiUrl,
                adverts: advertIds.map((advert) => ({
                    id: advert.id,
                    image: advert.imageUrl,
                })),
                status,
            };
        }));

        setData({ campaigns });
        setIsLoading(false);
    };

    const handleLockToggle = async (id, campaign) => {
        await request(api.updateCampaign).send({ id, isLocked: !campaign.isLocked });
        await retrieveData();
    };

    useEffect(() => {
        retrieveData();
    }, []);

    const handleDuplicate = async (campaignId) => {
        const dialog = createDialog({
            type: DialogTypes.Confirm
        });

        dialog.onConfirm(async () => {
            await request(api.duplicateCampaign).send({ id: campaignId });
            await retrieveData();
        });

        dialog.show();
    };

    const handleArchive = async (id) => {
        const dialog = createDialog({
            type: DialogTypes.Confirm
        });

        dialog.onConfirm(async () => {
            await request(api.updateCampaign).send({ id, isArchived: true, });
            await retrieveData();
        });

        dialog.show();
    };

    return (
        <CampaignsView
            clearAllFilters={clearAllFilters}
            data={data}
            filter={filter}
            handleArchive={handleArchive}
            handleDuplicate={handleDuplicate}
            handleLockToggle={handleLockToggle}
            isLoading={isLoading}
            setFilter={setFilter}
        />
    );
};

const CampaignsView = (props) => {
    const {
        data,
        handleArchive,
        handleDuplicate,
        handleLockToggle,
        clearAllFilters,
        setFilter,
        filter,
        isLoading,
    } = props;

    const [showQrModal, setShowQrModal] = useState(false);
    const [selectedCampaign, setSelectedCampaign] = useState(null);

    const toggleQrModal = (campaignId = null) => {
        setShowQrModal(campaignId !== null);
        setSelectedCampaign(campaignId);
    };

    return (
        <Grid container spacing={1}>
            <Grid
                alignItems='center'
                container
                direction='row'
                justifyContent='space-between'
                spacing={2}
                xs={12}
            >
                <Grid
                    alignItems='center'
                    direction='row'
                    display='flex'
                    item
                    xs={4}
                >
                    <StatusFilterSelect
                        changeHandler={(event) => {
                            setFilter('status', event.target.value);
                        }}
                        clearAllFilters={clearAllFilters}
                        filter={filter}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <List>
                    {isLoading ? (
                        <CenteredCircularProgress />
                    ) : (
                        <FadeInBox>
                            {data?.campaigns?.filter(
                                (campaign) => !filter?.status?.length || filter.status.includes(campaign.status)
                            ).map((campaign) => (
                                <ListItem
                                    key={campaign.id}
                                    sx={({ palette }) => ({
                                        backgroundColor: palette.common.white,
                                        borderRadius: 1,
                                        border: `1px solid ${palette.border.listItem}`,
                                        mb: 2,
                                    })}
                                >
                                    <CampaignRow
                                        campaign={campaign}
                                        handleArchive={handleArchive}
                                        handleDuplicate={handleDuplicate}
                                        handleLockToggle={handleLockToggle}
                                        showQrModal={toggleQrModal}
                                    />
                                </ListItem>
                            ))}
                        </FadeInBox>
                    )}
                </List>
            </Grid>
            <QrModal
                closeHandler={() => setShowQrModal(false)}
                show={showQrModal}
                url={data?.campaigns?.find((c) => c.id === selectedCampaign)?.url || ''}
            />
        </Grid>
    );
};

CampaignsView.propTypes = {
    clearAllFilters: PropTypes.func.isRequired,
    data: PropTypes.object,
    filter: PropTypes.object.isRequired,
    handleArchive: PropTypes.func.isRequired,
    handleDuplicate: PropTypes.func.isRequired,
    handleLockToggle: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    setFilter: PropTypes.func.isRequired,
};

CampaignsView.defaultProps = {
    data: null,
};

const CampaignRow = (props) => {
    const {
        campaign,
        handleArchive,
        handleDuplicate,
        handleLockToggle,
        showQrModal
    } = props;

    const { formatMessage } = useIntl();
    const [openMenu, setOpenMenu] = useState(false);
    const prevOpenMenu = useRef(openMenu);
    const anchorRef = useRef(null);
    const navigate = useNavigate();

    const { getContent, getType } = useStatus(campaign.status);

    const handleToggle = () => {
        setOpenMenu((prevState) => !prevState);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpenMenu(false);
    };

    const handleEdit = () => {
        navigate(`/campaigns/edit/${campaign.id}`);
    };

    const handleView = () => {
        navigate(`/campaigns/view/${campaign.id}`);
    };

    useEffect(() => {
        if (prevOpenMenu.current === true && openMenu === false) {
            anchorRef.current.focus();
        }

        prevOpenMenu.current = openMenu;
    }, [openMenu]);

    return (
        <Grid columns={24} container flexWrap='nowrap' spacing={4} xs={24}>
            <Grid alignItems='center' xs={8}>
                <ListItemText
                    primary={campaign.name}
                    secondary={campaignTypes.find((type) => type.id === campaign.type)?.label || ''}
                />
            </Grid>
            <Grid alignItems='center' xs={4}>
                <ListItemText
                    primary={campaign.startDate
                        ? <DefaultFormattedDate value={campaign.startDate} />
                        : formatMessage({
                            defaultMessage: 'Date not set',
                            id: 'common.label.noDateSet',
                        })}
                    secondary={formatMessage({
                        defaultMessage: 'Start date',
                        id: 'common.item.startDate'
                    })}
                />
            </Grid>
            <Grid alignItems='center' xs={4}>
                <ListItemText
                    primary={campaign.endDate
                        ? <DefaultFormattedDate value={campaign.endDate} />
                        : formatMessage({
                            defaultMessage: 'Date not set',
                            id: 'common.label.noDateSet',
                        })}
                    secondary={formatMessage({
                        defaultMessage: 'End date',
                        id: 'common.item.endDate'
                    })}
                />
            </Grid>
            <Grid alignItems='center' display='flex' xs={4.5}>
                <AvatarGroup total={campaign.adverts?.length} variant='rounded'>
                    {campaign.adverts.sort((a, b) => a.id - b.id)?.map((advert) => (
                        <Avatar
                            key={`avatar-${advert.id}`}
                            alt={advert.image}
                            src={advert.image}
                        />
                    ))}
                </AvatarGroup>
            </Grid>
            <Grid alignItems='center' display='flex' xs={2.5}>
                <Chip
                    color={getType()}
                    label={getContent()}
                    size='small'
                    variant='outlined'
                />
            </Grid>
            <Grid alignItems='center' display='flex' xs='auto'>
                <Button
                    onClick={() => {
                        showQrModal(campaign.id);
                    }}
                    size='small'
                    startIcon={<QrCodeIcon sx={{ color: 'icon.secondaryGray' }} />}
                    variant='onlyIcon'
                />
                <ListItemSecondaryAction>
                    <IconButton
                        ref={anchorRef}
                        edge='end'
                        onClick={handleToggle}
                        size='large'
                    >
                        <MenuIcon />
                    </IconButton>
                    <Popper
                        anchorEl={anchorRef.current}
                        open={openMenu}
                        placement='bottom-start'
                        role={undefined}
                        sx={{
                            borderRadius: 1,
                            boxShadow: 'none',
                        }}
                        transition
                    >
                        {({ TransitionProps, placement }) => (
                            <Fade
                                {...TransitionProps}
                                style={{
                                    transformOrigin:
                                        placement === 'bottom-start' ? 'left top' : 'left bottom',
                                }}
                            >
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList
                                            aria-labelledby='composition-button'
                                            autoFocusItem={openMenu}
                                            id='composition-menu'
                                            sx={{
                                                px: 0,
                                                py: 0.5
                                            }}
                                        >

                                            <MenuItem onClick={handleEdit}>
                                                <FormattedMessage defaultMessage='Edit' id='common.item.edit' />
                                            </MenuItem>
                                            <MenuItem onClick={handleView}>
                                                <FormattedMessage defaultMessage='View' id='common.item.view' />
                                            </MenuItem>
                                            <MenuItem onClick={() => {
                                                handleLockToggle(campaign.id, campaign);
                                            }}
                                            >
                                                {campaign.isLocked ? (
                                                    <FormattedMessage
                                                        defaultMessage='Unlock'
                                                        id='common.item.unlock'
                                                    />
                                                ) : (
                                                    <FormattedMessage
                                                        defaultMessage='Lock'
                                                        id='common.item.lock'
                                                    />
                                                )}
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() => {
                                                    handleDuplicate(campaign.id);
                                                    setOpenMenu(false);
                                                }}
                                            >
                                                <FormattedMessage
                                                    defaultMessage='Duplicate'
                                                    id='common.item.duplicate'
                                                />
                                            </MenuItem>
                                            <MenuItem
                                                onClick={() => {
                                                    handleArchive(campaign.id);
                                                    setOpenMenu(false);
                                                }}
                                            >
                                                <FormattedMessage
                                                    defaultMessage='Archive'
                                                    id='common.item.archive'
                                                />
                                            </MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Fade>
                        )}
                    </Popper>
                </ListItemSecondaryAction>
            </Grid>
        </Grid>
    );
};

CampaignRow.propTypes = {
    campaign: PropTypes.object.isRequired,
    handleArchive: PropTypes.func.isRequired,
    handleDuplicate: PropTypes.func.isRequired,
    handleLockToggle: PropTypes.func.isRequired,
    showQrModal: PropTypes.func.isRequired
};

export default Campaigns;
