import React from 'react';

import { useNavigate } from 'react-router-dom';
import { useApi, api } from '../base/Request';

import CampaignFormView from './FormView';

export default function CampaignAdd() {
    const { request } = useApi();
    const navigate = useNavigate();

    const submitData = async (data) => {
        const response = await request(api.createCampaign).send({
            label: data.name,
            hasEmailAccess: data.verifyEmail,
            hasEmailRegistration: data.emailRequired,
            tokenTypeId: data.tokenTypeId,
            linkedArticleId: data.linkedArticle,
            linkedPage: data.linkedPage,
            dateStart: data.startDate?.unix(),
            dateFinal: data.endDate?.unix(),
            listBannerId: data?.adverts || [],
            hasEmailAccessDirectToken: true,
            hasRegistrationDirectToken: true,
        });

        if (response?.success) {
            if (Array.isArray(data.geoCoordinates)) {
                await Promise.all(
                    data.geoCoordinates.map(async (geoCoordinate) => {
                        await request(api.updateCoordinate).send({
                            campaignId: response.campaignId,
                            id: geoCoordinate.id,
                            latitude: geoCoordinate.latitude,
                            longitude: geoCoordinate.longitude,
                            maxGeoMetres: geoCoordinate.radius,
                        });
                    })
                );
            }

            if (Array.isArray(data.ipAddresses)) {
                await Promise.all(
                    data.ipAddresses.map(async (address) => {
                        await request(api.updateIpAddress).send({
                            campaignId: response.campaignId,
                            id: address.id,
                            ip: address.address,
                            label: address.label,
                            ipMask: address.range,
                        });
                    })
                );
            }

            navigate('/campaigns');
        }
    };

    return (
        <CampaignFormView
            isUpdate={false}
            submitData={submitData}
        />
    );
}
