import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { api, useApi } from '../../base/Request';

import SelectLabel from '../../../components/SelectLabel';

const AccessDuration = (props) => {
    const { control } = props;

    const [options, setOptions] = useState([]);
    const { formatMessage } = useIntl();

    const { request } = useApi();

    const retrieveData = async () => {
        const response = await request(api.getTokenTypes).send();
        const tokens = response?.digiTokenTypes ?? [];

        const items = tokens.map((option) => ({
            key: option.id,
            value: option.dictionary
        }));

        setOptions(items);

        return items;
    };

    useEffect(() => {
        retrieveData();
    }, []);

    return (
        <Grid
            alignItems='center'
            container
            display='flex'
            flexDirection='row'
            sx={{ p: 3 }}
        >
            <Grid xs={3}>
                <Typography variant='sectionHeader'>
                    <FormattedMessage
                        defaultMessage='Duration of access'
                        id='campaigns.form.accessDuration'
                    />
                </Typography>
            </Grid>
            <Grid container spacing={2} xs={9}>
                <Grid xs={12}>
                    <SelectLabel
                        control={control}
                        defaultValue={1}
                        disableAdditionalLabel
                        label={formatMessage({
                            id: 'campaigns.form.timeBased.durationToken',
                            defaultMessage: 'Duration'
                        })}
                        name='tokenTypeId'
                        options={options}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

AccessDuration.propTypes = {
    control: PropTypes.object.isRequired,
};

export default AccessDuration;
