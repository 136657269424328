import React from 'react';
import PropTypes from 'prop-types';

import {
    Button, Dialog, DialogTitle, DialogActions, IconButton, DialogContent
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import { FormattedMessage } from 'react-intl';

export default function DefaultDialog(props) {
    const {
        show,
        size,
        closeHandler,
        saveButton,
        title,
        children,
        cancelDisabled
    } = props;

    return (
        <Dialog
            aria-labelledby='customized-dialog-title'
            fullWidth
            maxWidth={size}
            onClose={closeHandler}
            open={show}
            PaperProps={{
                sx: {
                    borderRadius: 1,
                    boxShadow: 'none',
                }
            }}
            spacing={3}
        >
            <DialogTitle
                id='customized-dialog-title'
                sx={{
                    m: 0, px: 4.5, pt: 4, pb: 0,
                }}
            >
                {title}
            </DialogTitle>
            <IconButton
                aria-label='close'
                onClick={closeHandler}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: 'text.light',
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{ px: 4.5, pb: 4 }}>
                {children}
            </DialogContent>
            {saveButton && (
                <DialogActions sx={{ pb: 4, px: 4, pt: 2.5 }}>
                    {!cancelDisabled && (
                        <Button autoFocus onClick={closeHandler} size='large'>
                            <FormattedMessage defaultMessage='Cancel' id='button.cancel' />
                        </Button>
                    )}
                    {saveButton}
                </DialogActions>
            )}
        </Dialog>
    );
}

DefaultDialog.propTypes = {
    cancelDisabled: PropTypes.bool,
    children: PropTypes.node.isRequired,
    closeHandler: PropTypes.func.isRequired,
    saveButton: PropTypes.node,
    show: PropTypes.bool.isRequired,
    size: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
};

DefaultDialog.defaultProps = {
    saveButton: null,
    size: 'md',
    cancelDisabled: false
};
