import React from 'react';
import PropTypes from 'prop-types';

import { Button, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { FilterAltOffOutlined as FilterOffIcon } from '@mui/icons-material';
import { StatusFilters, StatusTranslations, useStatus } from '../routes/base/Status';

import FilterSelect from './FilterSelect';

export default function StatusFilterSelect(props) {
    const {
        changeHandler,
        filter,
        clearAllFilters
    } = props;

    const { getContent } = useStatus();

    const statusList = (statuses) => statuses
        .map((status) => getContent(status))
        .join(', ');

    return (
        <>
            <FilterSelect
                changeHandler={changeHandler}
                getValueText={
                    (statuses) => (
                        statusList(statuses ?? []) || getContent(StatusTranslations.Status.name)
                    )
                }
                multiple
                options={
                    StatusFilters.map((status) => (
                        {
                            label: getContent(status),
                            value: status.toLowerCase(),
                            checked: filter?.status?.includes(status.toLowerCase())
                        }
                    ))
                }
                value={filter?.status || []}
            />
            {!!filter?.status?.length && (
                <Button
                    onClick={clearAllFilters}
                    sx={{
                        whiteSpace: 'nowrap',
                        marginLeft: '15px',
                        borderRadius: '5px',
                    }}
                >
                    <FilterOffIcon sx={{ height: 24 }} />
                    <Typography color='primary' variant='medium500'>
                        <FormattedMessage
                            defaultMessage='Clear Filters'
                            id='common.filter.clear'
                        />
                    </Typography>
                </Button>
            )}
        </>
    );
}

StatusFilterSelect.propTypes = {
    changeHandler: PropTypes.func.isRequired,
    clearAllFilters: PropTypes.func.isRequired,
    filter: PropTypes.object.isRequired,
};
