import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import {
    Alert,
    Avatar,
    AvatarGroup,
    Box,
    Button,
    ButtonGroup,
    Chip,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
    Unstable_Grid2 as Grid
} from '@mui/material';

import {
    CheckboxElement,
    Controller,
    DatePickerElement,
    TextFieldElement,
    useWatch
} from 'react-hook-form-mui';

import {
    CheckCircle as CheckCircleIcon,
    CircleOutlined as CircleIcon,
    EditOutlined as EditIcon,
    HorizontalRule as HorizontalRuleIcon
} from '@mui/icons-material';

import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { api, useApi } from '../../base/Request';
import { useCustomForm } from '../../base/Form';
import { formatDate, groupListByParent } from '../../../utils/utils';
import { useStatus } from '../../base/Status';

import PublicationArticleSelect from '../../../components/PublicationArticleSelect';
import CheckboxTableSelect from '../../../components/CheckboxTableSelect';

import campaignTypes from '../campaignTypes';
import { useErrorBag } from '../../base/ErrorBag';

const AdvertActions = (props) => {
    const {
        id,
        editAdvert,
    } = props;

    return (
        <ButtonGroup>
            <Button
                onClick={() => {
                    editAdvert(id);
                }}
                size='small'
                startIcon={<EditIcon />}
                variant='onlyIcon'
            />
        </ButtonGroup>
    );
};

AdvertActions.propTypes = {
    editAdvert: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
};

function shouldDisableCampaignTypeBox(dataType, campaignTypeKey) {
    const CAMPAIGN_TYPE_TIME = 'time';
    const CAMPAIGN_TYPE_GEOLOCATION = 'geolocation';
    const CAMPAIGN_TYPE_IP = 'ip';

    if (dataType === 1 && (campaignTypeKey === CAMPAIGN_TYPE_TIME || campaignTypeKey === CAMPAIGN_TYPE_IP)) {
        return true;
    }

    return dataType === 2 && (campaignTypeKey === CAMPAIGN_TYPE_TIME || campaignTypeKey === CAMPAIGN_TYPE_GEOLOCATION);
}

export default function GeneralSettings(props) {
    const {
        campaignType: selectedCampaignType,
        data,
        formHandler,
        setCampaignType,
        show
    } = props;

    const { formatMessage } = useIntl();
    const navigate = useNavigate();
    const errorBag = useErrorBag();

    const retrieveDefaultData = async () => {
        if (data === null || data === {}) {
            return {};
        }

        setCampaignType(
            campaignTypes.find((campaignType) => campaignType.id === data.type)?.key ?? 'time'
        );

        return {
            campaignName: data.name,
            emailRequired: data.emailRequired,
            endDate: dayjs.unix(data.endDate),
            startDate: dayjs.unix(data.startDate),
            verifyEmail: data.verifyEmail,
            linkedArticle: data.linkedArticle,
            linkedPage: data.linkedPage,
            adverts: data.adverts,
            type: data.type,
        };
    };

    const {
        control,
        handleSubmit,
        setValues,
    } = useCustomForm({ defaultValues: retrieveDefaultData });

    const LinkTypes = {
        None: 0,
        Publications: 2,
    };

    const [linkType, setLinkType] = useState(LinkTypes.None);
    const [loadedValues, setLoadedValues] = useState(false);
    const [publicationArticles, setPublicationArticles] = useState({});
    const [adverts, setAdverts] = useState([]);
    const values = useWatch({ control });

    const { request } = useApi();

    const retrieveData = async () => {
        const defaults = await retrieveDefaultData();

        setValues({
            campaignType: selectedCampaignType,
            adverts: defaults.adverts,
        });

        if (!defaults) {
            setLoadedValues(true);

            return;
        }

        if (defaults.linkedArticle) {
            setLinkType(LinkTypes.Publications);

            setValues({
                linkedArticle: defaults.linkedArticle,
                linkedPage: defaults.linkedPage
            });
        }

        setLoadedValues(true);
    };

    const retrieveAdverts = async () => {
        const response = await request(api.getAdverts).send();

        setAdverts(response?.digiBanners);
    };

    const retrievePublicationArticles = async () => {
        const response = await request(api.getPublicationArticles).send();

        setPublicationArticles(
            groupListByParent(response?.publicationArticles, 'rootName')
        );
    };

    const resetValuesOnTypeChange = () => {
        setValues({
            linkedArticle: null,
            linkedPage: null
        });
    };

    const handleLinkTypeChange = (event) => {
        setLinkType(event.target.value);

        resetValuesOnTypeChange();
    };

    const editAdvert = (id) => {
        navigate(`/adverts/edit/${id}`);
    };

    const advertColumns = [
        {
            field: 'imageUrl',
            headerName: 'Image',
            flex: 1,
            sortable: false,
            renderCell: (value) => (
                <AvatarGroup variant='rounded'>
                    <Avatar
                        key={`avatar-${value.row.id}`}
                        alt={value.row.imageUrl}
                        src={value.row.imageUrl}
                    />
                </AvatarGroup>
            )
        },
        {
            field: 'dateStart',
            headerName: 'Start date',
            flex: 1,
            valueFormatter: ({ value }) => (value ? formatDate(value) : value)
        },
        {
            field: 'dateFinal',
            headerName: 'End date',
            flex: 1,
            valueFormatter: ({ value }) => (value ? formatDate(value) : value)
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1,
            renderCell: (value) => {
                let status = 'active';

                if (value.row.isLocked) {
                    status = 'locked';
                } else if (value.row.dateFinal) {
                    const endOfDay = dayjs.unix(value.row.dateFinal).endOf('day');

                    if (dayjs().isAfter(endOfDay)) {
                        status = 'expired';
                    }
                }

                const { getContent, getType } = useStatus(status);

                return (
                    <Chip
                        color={getType()}
                        label={getContent()}
                        size='small'
                        variant='outlined'
                    />
                );
            },
        },
        {
            field: 'actions',
            headerName: '',
            align: 'right',
            sortable: false,
            renderCell: (value) => (
                <AdvertActions editAdvert={editAdvert} id={value.row.id} />
            )
        }
    ];

    useEffect(() => {
        retrievePublicationArticles();
        retrieveAdverts();
    }, []);

    useEffect(() => {
        retrieveData();
    }, [loadedValues]);

    const [startDate, endDate] = useWatch({
        control,
        name: ['startDate', 'endDate']
    });

    useEffect(() => {
        errorBag.set({
            endDateBeforeStartDate: new Date(endDate) < new Date(startDate)
        });
    }, [startDate, endDate]);

    return (
        <Grid
            className='alternate-rows'
            container
            direction='column'
            spacing={2}
            sx={{ px: 2, my: 2, display: show ? 'flex' : 'none' }}
            xs={12}
        >
            <Grid container sx={{ p: 3, pb: 5 }}>
                <Grid xs={3}>
                    <Typography variant='sectionHeader'>
                        <FormattedMessage
                            defaultMessage='Campaign name'
                            id='campaigns.form.name'
                        />
                    </Typography>
                </Grid>
                <Grid xs={9}>
                    <TextFieldElement
                        control={control}
                        label={(
                            <FormattedMessage
                                defaultMessage='Campaign name'
                                id='campaigns.form.name'
                            />
                        )}
                        name='campaignName'
                        required
                        type='text'
                    />
                </Grid>
            </Grid>
            <Grid container sx={{ p: 3 }}>
                <Grid sx={{ mb: 3 }} xs={12}>
                    <Typography variant='sectionHeader'>
                        <FormattedMessage
                            defaultMessage='Choose the campaign type'
                            id='campaigns.form.type'
                        />
                    </Typography>
                </Grid>
                <Grid columnSpacing={3} container xs={12}>
                    {campaignTypes.map((campaignType) => (
                        <Grid
                            key={campaignType.key}
                            xs={4}
                        >
                            <Box
                                onClick={() => {
                                    if (shouldDisableCampaignTypeBox(data.type, campaignType.key)) {
                                        return;
                                    }

                                    setCampaignType(campaignType.key);
                                }}
                                sx={{
                                    border: '1px solid',
                                    borderColor: campaignType.key === selectedCampaignType
                                        ? 'primary.main'
                                        : 'text.mutedLightGray',
                                    borderRadius: 1,
                                    padding: 2,
                                    cursor: 'pointer',
                                    background: 'common.white',

                                    ...(shouldDisableCampaignTypeBox(data.type, campaignType.key) && {
                                        color: 'text.mutedLightGray',
                                        cursor: 'unset',
                                        background: 'transparent',
                                    })
                                }}
                            >
                                <Grid container>
                                    <Grid xs={2}>
                                        <Box color='primary'>
                                            {campaignType.key === selectedCampaignType
                                                ? <CheckCircleIcon color='primary' />
                                                : (<CircleIcon color='disabled' />)}
                                        </Box>
                                    </Grid>
                                    <Grid container direction='column' xs={10}>
                                        <Typography variant='medium500'>
                                            {campaignType.label}
                                        </Typography>
                                        <Typography
                                            color='text.light'
                                            sx={{
                                                ...(shouldDisableCampaignTypeBox(data.type, campaignType.key) && {
                                                    color: 'text.mutedLightGray',
                                                })
                                            }}
                                            variant='small400'
                                        >
                                            {campaignType.description}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid container sx={{ p: 3, pt: 5 }}>
                <Grid xs={3}>
                    <Typography variant='sectionHeader'>
                        <FormattedMessage
                            defaultMessage='Select adverts'
                            id='campaigns.form.adverts'
                        />
                    </Typography>
                </Grid>
                <Grid container direction='column' spacing={1} xs={9}>
                    <Grid container sx={{ mb: 2 }} xs={12}>
                        <CheckboxTableSelect
                            columns={advertColumns}
                            data={adverts}
                            defaultValues={data?.adverts || []}
                            onChange={(items) => {
                                setValues({ adverts: items });
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container sx={{ p: 3 }}>
                <Grid xs={3}>
                    <Typography variant='sectionHeader'>
                        <FormattedMessage
                            defaultMessage='Dates'
                            id='campaigns.form.dates'
                        />
                    </Typography>
                </Grid>
                <Grid container direction='column' spacing={1} xs={9}>
                    {!!errorBag.length && (
                        <Grid sx={{ mb: 3 }}>
                            {errorBag.has(['endDateBeforeStartDate']) && (
                                <Alert severity='error'>
                                    <FormattedMessage
                                        defaultMessage='End date cannot be before start date'
                                        id='publication.error.endDateBeforeStartDate'
                                    />
                                </Alert>
                            )}
                        </Grid>
                    )}
                    <Grid container sx={{ mb: 2 }} xs={12}>
                        <Grid xs={5.5}>
                            <DatePickerElement
                                control={control}
                                label={formatMessage({
                                    defaultMessage: 'Start date',
                                    id: 'campaigns.form.startDate'
                                })}
                                name='startDate'
                                required
                            />
                        </Grid>
                        <Grid alignItems='center' display='flex' justifyContent='center' xs={1}>
                            <HorizontalRuleIcon
                                sx={{
                                    color: 'border.input',
                                }}
                            />
                        </Grid>
                        <Grid xs={5.5}>
                            <DatePickerElement
                                control={control}
                                label={formatMessage({
                                    defaultMessage: 'End date',
                                    id: 'campaigns.form.endDate'
                                })}
                                name='endDate'
                                required
                            />
                        </Grid>
                    </Grid>
                    <Grid>
                        <CheckboxElement
                            control={control}
                            label={formatMessage({
                                defaultMessage: 'Registration form',
                                id: 'campaigns.form.general.emailRequired'
                            })}
                            name='emailRequired'
                        />
                    </Grid>
                    <Grid>
                        <CheckboxElement
                            control={control}
                            label={formatMessage({
                                defaultMessage: 'Email validation',
                                id: 'campaigns.form.general.verifyEmail'
                            })}
                            name='verifyEmail'
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container sx={{ p: 3, pt: 5 }}>
                <Grid xs={3}>
                    <Typography variant='sectionHeader'>
                        <FormattedMessage
                            defaultMessage='Add link'
                            id='highlights.section.addLink'
                        />
                    </Typography>
                </Grid>
                <Grid container spacing={4} xs={9}>
                    <Grid xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id='link-type-label'>Link type</InputLabel>
                            <Controller
                                control={control}
                                defaultValue={linkType}
                                name='linkType'
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        label='Link Type'
                                        labelId='link-type-label'
                                        onChange={(event) => {
                                            handleLinkTypeChange(event);
                                            field.onChange(event);
                                        }}
                                        value={linkType}
                                    >
                                        <MenuItem value={LinkTypes.None}>None</MenuItem>
                                        <MenuItem value={LinkTypes.Publications}>Publications</MenuItem>
                                    </Select>
                                )}
                            />
                        </FormControl>

                        {linkType === LinkTypes.Publications && loadedValues && (
                            <Grid item sx={{ mt: 3, mb: 1 }} xs={12}>
                                <PublicationArticleSelect
                                    control={control}
                                    linkedArticle={values.linkedArticle}
                                    linkedPage={values.linkedPage}
                                    publicationArticles={publicationArticles}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Grid container sx={{ my: 3 }}>
                <Grid
                    display='flex'
                    justifyContent='flex-end'
                    xs={12}
                >
                    <Button
                        onClick={() => {
                            navigate('/campaigns');
                        }}
                        size='large'
                    >
                        <FormattedMessage defaultMessage='Cancel' id='button.cancel' />
                    </Button>
                    <Button
                        disabled={!!errorBag.length}
                        onClick={handleSubmit(formHandler)}
                        size='large'
                        type='submit'
                        variant='contained'
                    >
                        <FormattedMessage defaultMessage='Next' id='button.next' />
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}

GeneralSettings.propTypes = {
    campaignType: PropTypes.string,
    data: PropTypes.object,
    formHandler: PropTypes.func.isRequired,
    setCampaignType: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
};

GeneralSettings.defaultProps = {
    campaignType: null,
    data: null
};
