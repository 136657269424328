import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
    Unstable_Grid2 as Grid,
    Typography,
    Box,
} from '@mui/material';

import { FormattedMessage } from 'react-intl';
import { useForm, useWatch } from 'react-hook-form-mui';

import GeneralSettings from './forms/GeneralSettings';
import GeoLocation from './forms/GeoLocation';
import IpAddress from './forms/IpAddress';
import TimeBased from './forms/TimeBased';
import ProgressSlider from '../../components/ProgressSlider';

import campaignTypes from './campaignTypes';

export default function CampaignFormView(props) {
    const {
        isUpdate,
        retrieveData,
        submitData
    } = props;

    const [step, setStep] = useState(1);
    const [campaignData, setCampaignData] = useState(null);

    const totalSteps = 2;

    const getDefaultData = async () => {
        if (isUpdate) {
            const data = await retrieveData();

            setCampaignData(data);

            return {
                tokenTypeId: data.tokenTypeId,
                campaignType: campaignTypes.find((campaignType) => campaignType.id === data.type)?.key,
            };
        }

        setCampaignData({});

        return { campaignType: 'time' };
    };

    const {
        control,
        getValues,
        setValue
    } = useForm({ defaultValues: getDefaultData });

    const [campaignType] = useWatch({
        control,
        name: ['campaignType']
    });

    const submitGeneralSettings = (formData) => {
        if (campaignType) {
            setCampaignData({
                ...campaignData,
                name: formData.campaignName,
                startDate: formData.startDate,
                endDate: formData.endDate,
                emailRequired: formData.emailRequired,
                verifyEmail: formData.verifyEmail,
                linkedArticle: formData.linkedArticle,
                linkedPage: formData.linkedPage,
                tokenTypeId: formData.tokenTypeId,
                adverts: formData.adverts,
            });

            setStep(2);
        }
    };

    const backToGeneralSettings = () => {
        setStep(1);
    };

    const submitForm = (extraValues) => {
        const values = getValues();

        const settings = {
            ...campaignData,
            ...values,
            ...extraValues
        };

        submitData(settings);
    };

    const getSliderTitle = () => {
        if (step === 1) {
            return (
                <FormattedMessage
                    defaultMessage='1. Campaign settings'
                    id='campaigns.form.title'
                />
            );
        }

        switch (campaignType) {
            case 'geolocation':
                return (
                    <FormattedMessage
                        defaultMessage='2. Geo Coordinates and Access'
                        id='campaigns.form.geolocation.title'
                    />
                );
            case 'ip':
                return (
                    <FormattedMessage
                        defaultMessage='2. IP addresses and Access'
                        id='campaigns.form.ip.title'
                    />
                );
            case 'time':
                return (
                    <FormattedMessage
                        defaultMessage='2. Time-based access'
                        id='campaigns.form.time.title'
                    />
                );
            default:
                return (
                    <FormattedMessage
                        defaultMessage='2. Campaign settings'
                        id='campaigns.form.title'
                    />
                );
        }
    };

    return (
        <>
            <Grid className='alternate-rows-odd' container direction='column' spacing={2} sx={{ px: 2, my: 2 }} xs={12}>
                <Grid container direction='column' spacing={1} sx={{ py: 3, px: 3, mb: 3 }}>
                    <Grid container display='flex' justifyContent='space-between' sx={{ mb: 2 }}>
                        <Typography variant='sectionHeader'>
                            {getSliderTitle()}
                        </Typography>
                        <Box>
                            <Typography color='primary' variant='medium500'>
                                <FormattedMessage
                                    defaultMessage='Step {currentStep} / '
                                    id='common.step'
                                    values={{ currentStep: step, totalSteps }}
                                />
                            </Typography>
                            <Typography variant='medium500'>
                                {totalSteps}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid>
                        <ProgressSlider progress={step / totalSteps} />
                    </Grid>
                </Grid>
            </Grid>
            {campaignData !== null && (
                <>
                    <GeneralSettings
                        campaignType={campaignType}
                        control={control}
                        data={campaignData}
                        formHandler={submitGeneralSettings}
                        setCampaignType={
                            (type) => {
                                setValue('campaignType', type);
                            }
                        }
                        show={step === 1}
                    />
                    <GeoLocation
                        backHandler={backToGeneralSettings}
                        campaignId={campaignData.id}
                        control={control}
                        data={campaignData}
                        formHandler={submitForm}
                        isUpdate={isUpdate}
                        show={step === 2 && campaignType === 'geolocation'}
                    />
                    <IpAddress
                        backHandler={backToGeneralSettings}
                        campaignId={campaignData.id}
                        control={control}
                        data={campaignData}
                        formHandler={submitForm}
                        isUpdate={isUpdate}
                        show={step === 2 && campaignType === 'ip'}
                    />
                    <TimeBased
                        backHandler={backToGeneralSettings}
                        control={control}
                        data={campaignData}
                        formHandler={submitForm}
                        isUpdate={isUpdate}
                        show={step === 2 && campaignType === 'time'}
                    />
                </>
            )}
        </>

    );
}

CampaignFormView.propTypes = {
    isUpdate: PropTypes.bool,
    retrieveData: PropTypes.func,
    submitData: PropTypes.func.isRequired
};

CampaignFormView.defaultProps = {
    isUpdate: false,
    retrieveData: () => {
    }
};
