import React, { useEffect } from 'react';
import {
    Button, Paper, Typography, Unstable_Grid2 as Grid
} from '@mui/material';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '../contexts/Auth';
import ApiUtils from './base/Api';

import Phone from '../modules/Phone';

const DashboardBlock = styled(Paper)(({ theme }) => ({
    border: `1px solid ${theme.palette.border.topBar}`,
    boxShadow: 'none',
    textAlign: 'center',
    color: theme.palette.text.primary,
}));

const DashboardItem = ({
    title, viewHandler, addHandler, children
}) => (
    <Grid xs={6}>
        <DashboardBlock sx={{ p: 2, pb: 6 }}>
            <Grid container direction='column' spacing={1}>
                <Grid alignItems='center' container justifyContent='space-between' wrap='nowrap'>
                    <Grid sx={{ pl: 1 }} xs='auto'>
                        <Typography variant='sectionHeader'>{title}</Typography>
                    </Grid>
                    <Grid xs='auto'>
                        <Button onClick={viewHandler} size='small' sx={{ pl: 2, pr: 2 }}>
                            <FormattedMessage defaultMessage='View' id='dashboard.buttons.view' />
                        </Button>
                        {addHandler && (
                            <Button onClick={addHandler} size='small' startIcon={<AddIcon />} variant='contained'>
                                <FormattedMessage defaultMessage='Add' id='dashboard.buttons.add' />
                            </Button>
                        )}
                    </Grid>
                </Grid>
                <Grid container sx={{ justifyContent: 'space-evenly', pt: 6 }} xs='12'>
                    {children}
                </Grid>
            </Grid>
        </DashboardBlock>
    </Grid>
);

DashboardItem.propTypes = {
    addHandler: PropTypes.func,
    children: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    viewHandler: PropTypes.func.isRequired,
};

DashboardItem.defaultProps = {
    addHandler: null,
};

const Counter = ({ count, unit, active }) => {
    const numberStyle = active
        ? { fontSize: 80, fontWeight: 800, lineHeight: 1 }
        : {
            fontSize: 46, fontWeight: 700, color: 'text.zeroStateNumber', lineHeight: 1
        };

    return (
        <Grid container direction='column' justifyContent='flex-end'>
            <Typography sx={numberStyle} variant='dashboardCounter'>
                {count}
            </Typography>
            <Typography
                sx={{
                    fontSize: 14, fontWeight: 400, color: 'text.light', mt: '10px'
                }}
                variant='counterUnit'
            >
                {unit}
            </Typography>
        </Grid>
    );
};

Counter.propTypes = {
    active: PropTypes.bool.isRequired,
    count: PropTypes.number.isRequired,
    unit: PropTypes.string.isRequired,
};

const DashboardView = (props) => {
    const { data, goToRoute, retrieveData } = props;
    const { formatMessage } = useIntl();
    useEffect(() => {
        retrieveData();
    }, []);
    let newsstands = {};
    let campaigns = {};
    let adverts = {};
    let highlights = {};
    if (data && data.newsstands) {
        newsstands = data.newsstands;
    }
    if (data && data.campaigns) {
        campaigns = data.campaigns;
    }
    if (data && data.adverts) {
        adverts = data.adverts;
    }
    if (data && data.highlights) {
        highlights = data.highlights;
    }
    return (
        <Grid container spacing={3}>
            <Grid container direction='column' xs={8}>
                <Grid container>
                    <DashboardItem
                        title={formatMessage({ defaultMessage: 'Newsstands', id: 'pages.newsstands' })}
                        viewHandler={() => goToRoute('/newsstand')}
                    >
                        <Counter
                            active
                            count={newsstands.amount || 0}
                            unit={formatMessage({ defaultMessage: 'Newsstands', id: 'pages.newsstands' })}
                        />
                    </DashboardItem>
                    <DashboardItem
                        addHandler={() => goToRoute('/campaigns/add')}
                        title={formatMessage({ defaultMessage: 'Campaigns', id: 'pages.campaigns' })}
                        viewHandler={() => goToRoute('/campaigns')}
                    >
                        <Counter
                            active
                            count={campaigns.active || 0}
                            unit={formatMessage({ defaultMessage: 'Active', id: 'dashboard.active' })}
                        />
                        <Counter
                            active={false}
                            count={campaigns.inactive || 0}
                            unit={formatMessage({ defaultMessage: 'Inactive', id: 'dashboard.inactive' })}
                        />
                    </DashboardItem>
                </Grid>
                <Grid container>
                    <DashboardItem
                        addHandler={() => goToRoute('/adverts/add')}
                        title={formatMessage({ defaultMessage: 'Adverts', id: 'pages.adverts' })}
                        viewHandler={() => goToRoute('/adverts')}
                    >
                        <Counter
                            active
                            count={adverts.active || 0}
                            unit={formatMessage({ defaultMessage: 'Active', id: 'dashboard.active' })}
                        />
                        <Counter
                            active={false}
                            count={adverts.inactive || 0}
                            unit={formatMessage({ defaultMessage: 'Inactive', id: 'dashboard.inactive' })}
                        />
                    </DashboardItem>
                    <DashboardItem
                        addHandler={() => goToRoute('/highlights/add')}
                        title={formatMessage({ defaultMessage: 'Highlights', id: 'pages.highlights' })}
                        viewHandler={() => goToRoute('/highlights')}
                    >
                        <Counter
                            active
                            count={highlights.active || 0}
                            unit={formatMessage({ defaultMessage: 'Active', id: 'dashboard.active' })}
                        />
                        <Counter
                            active={false}
                            count={highlights.inactive || 0}
                            unit={formatMessage({ defaultMessage: 'Inactive', id: 'dashboard.inactive' })}
                        />
                    </DashboardItem>
                </Grid>
            </Grid>
            <Grid xs={4}>
                <Phone />
            </Grid>
        </Grid>
    );
};
DashboardView.propTypes = {
    data: PropTypes.object,
    goToRoute: PropTypes.func.isRequired,
    retrieveData: PropTypes.func.isRequired,
};
DashboardView.defaultProps = {
    data: {}
};

const Dashboard = () => {
    const [data, setData] = React.useState(null);
    const authContext = useAuth();
    const intlContext = useIntl();
    const { Api } = ApiUtils.useApi(authContext, intlContext);

    const retrieveData = async () => {
        const response = await Api.getDashboard();
        if (response.success && response.dashboard) {
            const {
                digiUrl,
                quantityDepartments,
                quantityActiveCampaigns,
                quantityInactiveCampaigns,
                quantityActiveAdverts,
                quantityInactiveAdverts,
                quantityActiveHighlights,
                quantityInactiveHighlights,
                quantityActiveArticles,
                quantityInactiveArticles,
            } = response.dashboard;

            setData({
                digiUrl,
                newsstands: {
                    amount: quantityDepartments,
                },
                campaigns: {
                    active: quantityActiveCampaigns,
                    inactive: quantityInactiveCampaigns
                },
                adverts: {
                    active: quantityActiveAdverts,
                    inactive: quantityInactiveAdverts
                },
                articles: {
                    active: quantityActiveArticles,
                    inactive: quantityInactiveArticles
                },
                highlights: {
                    active: quantityActiveHighlights,
                    inactive: quantityInactiveHighlights
                }
            });
            return response.success;
        }
        console.error('Error retrieving dashboard data');
        return null;
    };
    // Go to route using react-router
    const navigate = useNavigate();
    const goToRoute = (route) => {
        navigate(route);
    };

    return (
        <DashboardView
            data={data}
            goToRoute={goToRoute}
            retrieveData={retrieveData}
        />
    );
};

export default Dashboard;
