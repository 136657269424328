import React, {
    useEffect, useMemo, useRef, useState
} from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import {
    Link as LinkIcon,
    MoreVert as MoreVertIcon,
    DeleteOutlined as DeleteIcon,
} from '@mui/icons-material';

import {
    Unstable_Grid2 as Grid,
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Typography,
    Popper,
    Fade,
    Paper,
    ClickAwayListener,
    MenuList,
    MenuItem,
    Chip,
    Button,
    Stack,
    Box,
    Link
} from '@mui/material';

import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

import { useSortFilterSearch } from '../contexts/SortFilterSearch';
import { useStatus } from './base/Status';
import { useApi, api, noCacheUrl } from './base/Request';

import StatusFilterSelect from '../components/StatusFilterSelect';
import { DialogTypes, useDialog } from './base/Dialog';
import DefaultDialog from '../modules/DefaultDialog';
import CenteredCircularProgress from '../components/CenteredCircularProgress';
import FadeInBox from '../components/Animation/FadeInBox';
import DefaultFormattedDate from '../components/DefaultFormattedDate';

const Adverts = () => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const expiredIdsRef = useRef([]);

    const { setFilter, filter, clearAllFilters } = useSortFilterSearch('adverts');

    const { createDialog } = useDialog();
    const { request } = useApi();

    const getAdvertsFromResponse = (response) => {
        const adverts = response?.digiBanners;

        if (response.success && Array.isArray(adverts)) {
            return adverts;
        }

        console.error('error retrieving adverts data');

        return [];
    };

    const retrieveData = async () => {
        setIsLoading(true);
        const response = await request(api.getAdverts).send();

        expiredIdsRef.current.splice(0);

        const adverts = getAdvertsFromResponse(response).map((advert) => {
            let status = 'active';

            if (advert.isLocked) {
                status = 'locked';
            } else if (advert.status === 'expired') {
                status = 'expired';
            } else if (advert.dateFinal) {
                const endOfDay = dayjs.unix(advert.dateFinal).endOf('day');

                if (dayjs().isAfter(endOfDay)) {
                    status = 'expired';
                    expiredIdsRef.current.push(advert.id);
                }
            }

            return {
                id: advert.id,
                startDate: advert.dateStart && dayjs.unix(advert.dateStart).toDate(),
                endDate: advert.dateFinal && dayjs.unix(advert.dateFinal).toDate(),
                image: noCacheUrl(advert.imageUrl),
                linkedCampaigns: advert.linkedCampaigns ?? [],
                isLocked: advert.isLocked,
                status,
            };
        });

        setData({ adverts });
        setIsLoading(false);
    };

    const deleteAdvert = async (id) => {
        const dialog = createDialog({
            type: DialogTypes.Confirm
        });

        dialog.onConfirm(async () => {
            await request(api.deleteAdvert).send({ id });
            await retrieveData();
        });

        dialog.show();
    };

    const handleDelete = async (id) => {
        await deleteAdvert(id);
        await retrieveData();
    };

    const handleLockToggle = async (id, advert) => {
        await request(api.updateAdvert).send({ id, isLocked: !advert.isLocked });
        await retrieveData();
    };

    const handleDeleteExpired = async () => {
        const dialog = createDialog({
            type: DialogTypes.Confirm
        });

        dialog.onConfirm(async () => {
            expiredIdsRef.current.forEach((id) => {
                request(api.deleteAdvert).send({ id });
            });

            await retrieveData();
        });

        dialog.show();
    };

    useEffect(() => {
        retrieveData();
    }, []);

    return (
        <AdvertsView
            clearAllFilters={clearAllFilters}
            data={data}
            filter={filter}
            handleDelete={handleDelete}
            handleDeleteExpired={handleDeleteExpired}
            handleLockToggle={handleLockToggle}
            isLoading={isLoading}
            setFilter={setFilter}
        />
    );
};

const AdvertsView = (props) => {
    const {
        data,
        handleLockToggle,
        handleDelete,
        handleDeleteExpired,
        clearAllFilters,
        setFilter,
        filter,
        isLoading
    } = props;

    const { formatMessage } = useIntl();

    return (
        <Grid container spacing={1}>
            <Grid
                alignItems='center'
                container
                direction='row'
                justifyContent='space-between'
                spacing={2}
                xs={12}
            >
                <Grid
                    alignItems='center'
                    direction='row'
                    display='flex'
                    item
                    xs={4}
                >
                    <StatusFilterSelect
                        changeHandler={(event) => {
                            setFilter('status', event.target.value);
                        }}
                        clearAllFilters={clearAllFilters}
                        filter={filter}
                    />
                </Grid>
                <Grid>
                    <Button
                        onClick={handleDeleteExpired}
                        variant='outlined'
                    >
                        <Stack
                            alignItems='center'
                            direction='row'
                            gap={1}
                        >
                            <DeleteIcon sx={{ height: 24 }} />
                            <Typography variant='medium500'>
                                <FormattedMessage defaultMessage='Delete expired' id='button.delete.expired' />
                            </Typography>
                        </Stack>
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <List>
                    {isLoading ? (
                        <CenteredCircularProgress />
                    ) : (
                        <FadeInBox>
                            {data?.adverts
                                ?.filter(
                                    (advert) => !filter?.status?.length || filter.status.includes(advert.status)
                                )
                                .map((advert) => (
                                    <ListItem
                                        key={advert.id}
                                        sx={{
                                            backgroundColor: 'common.white',
                                            borderRadius: 1,
                                            border: ({ palette }) => `1px solid ${palette.border.listItem}`,
                                            px: 1,
                                            py: 2,
                                            marginBottom: 2
                                        }}
                                    >
                                        <ListItemRow
                                            advert={advert}
                                            formatMessage={formatMessage}
                                            handleDelete={() => (
                                                handleDelete(advert.id)
                                            )}
                                            handleLockToggle={() => (
                                                handleLockToggle(advert.id, advert)
                                            )}
                                        />
                                    </ListItem>
                                ))}
                        </FadeInBox>
                    )}
                </List>
            </Grid>
        </Grid>
    );
};

const CampaignListPopup = (props) => {
    const { campaigns, isOpen, onClose } = props;
    const { formatMessage } = useIntl();

    return (
        <DefaultDialog
            closeHandler={onClose}
            show={isOpen}
            size='xs'
            title={formatMessage({
                defaultMessage: 'Linked campaigns',
                id: 'adverts.campaignLinkedList'
            })}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                {campaigns.map((campaign) => (
                    <Link
                        component={RouterLink}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            textDecoration: 'none',
                        }}
                        to={`/campaigns/view/${campaign.campaignId}`}
                    >
                        <LinkIcon
                            sx={{
                                color: 'icon.secondaryGray',
                                fontSize: '1.4rem !important',
                                marginRight: 1,
                                rotate: '-45deg',
                            }}
                        />
                        <Typography
                            sx={{
                                color: 'text.blackTransparent60',
                                position: 'relative',
                                display: 'inline-block',
                                fontSize: '17px',
                                lineHeight: '1.5',
                                transition: 'color 0.2s',
                                '&:hover': {
                                    color: 'text.primary',
                                },
                            }}
                        >
                            {campaign.campaignLabel}
                        </Typography>
                    </Link>
                ))}
            </Box>
        </DefaultDialog>
    );
};

CampaignListPopup.propTypes = {
    campaigns: PropTypes.arrayOf(
        PropTypes.shape({
            campaignLabel: PropTypes.string,
            id: PropTypes.string.isRequired,
        })
    ).isRequired,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

AdvertsView.propTypes = {
    clearAllFilters: PropTypes.func.isRequired,
    data: PropTypes.object,
    filter: PropTypes.object,
    handleDelete: PropTypes.func.isRequired,
    handleDeleteExpired: PropTypes.func.isRequired,
    handleLockToggle: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    setFilter: PropTypes.func.isRequired,
};

AdvertsView.defaultProps = {
    data: null,
    filter: null,
};

const ListItemRow = (props) => {
    const {
        advert,
        handleDelete,
        handleLockToggle,
        formatMessage
    } = props;

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isCampaignListOpen, setIsCampaignListOpen] = useState(false);
    const prevOpenMenu = useRef(isMenuOpen);
    const anchorRef = useRef(null);
    const navigate = useNavigate();
    const { getContent, getType } = useStatus(advert.status);

    const campaigns = Array.isArray(advert?.linkedCampaigns) ? advert.linkedCampaigns : null;

    const linkedCampaignText = useMemo(() => {
        if (campaigns) {
            const count = campaigns.length;
            const isPlural = count !== 1;

            const formatOptions = isPlural
                ? { defaultMessage: 'campaigns linked', id: 'adverts.campaigns.linked.plural' }
                : { defaultMessage: 'campaign linked', id: 'adverts.campaigns.linked.single' };

            return `${count} ${formatMessage(formatOptions)}`;
        }

        return '';
    }, [campaigns]);

    const handleCampaignListOpen = () => {
        setIsCampaignListOpen(true);
    };

    const handleCampaignListClose = () => {
        setIsCampaignListOpen(false);
    };

    const handleToggle = () => {
        setIsMenuOpen((prevState) => !prevState);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setIsMenuOpen(false);
    };

    const handleEdit = () => {
        navigate(`/adverts/edit/${advert.id}`);
    };

    useEffect(() => {
        if (prevOpenMenu.current === true && isMenuOpen === false) {
            anchorRef.current.focus();
        }

        prevOpenMenu.current = isMenuOpen;
    }, [isMenuOpen]);

    return (
        <Grid container spacing={4} xs={12}>
            <Grid xs>
                <ListItemAvatar sx={{ height: 75, paddingLeft: 1 }}>
                    <Avatar
                        src={advert.image}
                        sx={{ height: '100%', width: '100px', borderRadius: 0.5 }}
                        variant='square'
                    />
                </ListItemAvatar>
            </Grid>
            <Grid alignItems='center' container spacing={2} xs={4}>
                <Grid xs={6}>
                    <ListItemText
                        primary={advert.startDate
                            ? <DefaultFormattedDate value={advert.startDate} />
                            : formatMessage({
                                defaultMessage: 'Date not set',
                                id: 'common.label.noDateSet',
                            })}
                        secondary={formatMessage({
                            defaultMessage: 'Start date',
                            id: 'common.item.startDate'
                        })}
                    />
                </Grid>
                <Grid xs={6}>
                    <ListItemText
                        primary={advert.endDate
                            ? <DefaultFormattedDate value={advert.endDate} />
                            : formatMessage({
                                defaultMessage: 'Date not set',
                                id: 'common.label.noDateSet',
                            })}
                        secondary={formatMessage({
                            defaultMessage: 'End date',
                            id: 'common.item.endDate'
                        })}
                    />
                </Grid>
            </Grid>
            <Grid alignItems='center' container spacing={2} xs={6}>
                <Grid rowSpacing={0} xs={9}>
                    {campaigns && (
                        <>
                            <Typography
                                onClick={handleCampaignListOpen}
                                sx={{
                                    color: 'text.blackTransparent60',
                                    display: 'flex',
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    transition: 'color 0.2s',
                                    '&:hover': {
                                        color: 'text.primary',
                                    },
                                    '&:hover .MuiSvgIcon-root': {
                                        color: 'text.primary',
                                    }
                                }}
                            >
                                <LinkIcon
                                    sx={{
                                        color: 'icon.secondaryGray',
                                        fontSize: '1.4rem !important',
                                        marginRight: 1,
                                        rotate: '-45deg',
                                        transition: 'color 0.2s',
                                    }}
                                />
                                {linkedCampaignText}
                            </Typography>

                            <CampaignListPopup
                                campaigns={campaigns}
                                isOpen={isCampaignListOpen}
                                onClose={handleCampaignListClose}
                            />
                        </>
                    )}
                </Grid>
                <Grid display='flex' justifyContent='left' xs={3}>
                    <Chip
                        color={getType()}
                        label={getContent()}
                        size='small'
                        variant='outlined'
                    />
                    <ListItemSecondaryAction>
                        <IconButton
                            ref={anchorRef}
                            edge='end'
                            onClick={handleToggle}
                            size='large'
                        >
                            <MoreVertIcon />
                        </IconButton>
                        <Popper
                            anchorEl={anchorRef.current}
                            open={isMenuOpen}
                            placement='bottom-start'
                            role={undefined}
                            sx={{
                                borderRadius: 0.5,
                                boxShadow: 'none',
                            }}
                            transition
                        >
                            {({ TransitionProps, placement }) => (
                                <Fade
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin: placement === 'bottom-start'
                                            ? 'left top'
                                            : 'left bottom',
                                    }}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList
                                                aria-labelledby='composition-button'
                                                autoFocusItem={isMenuOpen}
                                                id='composition-menu'
                                                sx={{
                                                    padding: 0
                                                }}
                                            >
                                                <MenuItem onClick={handleEdit}>
                                                    <FormattedMessage defaultMessage='Edit' id='common.item.edit' />
                                                </MenuItem>

                                                {advert.isLocked ? (
                                                    <MenuItem onClick={handleLockToggle}>
                                                        <FormattedMessage
                                                            defaultMessage='Unlock'
                                                            id='common.item.unlock'
                                                        />
                                                    </MenuItem>
                                                ) : (
                                                    <MenuItem onClick={handleLockToggle}>
                                                        <FormattedMessage
                                                            defaultMessage='Lock'
                                                            id='common.item.lock'
                                                        />
                                                    </MenuItem>
                                                )}

                                                <MenuItem onClick={handleDelete}>
                                                    <FormattedMessage
                                                        defaultMessage='Delete'
                                                        id='common.item.delete'
                                                    />
                                                </MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Fade>
                            )}
                        </Popper>
                    </ListItemSecondaryAction>
                </Grid>
            </Grid>
        </Grid>
    );
};

ListItemRow.propTypes = {
    advert: PropTypes.shape({
        endDate: PropTypes.instanceOf(Date).isRequired,
        id: PropTypes.number.isRequired,
        image: PropTypes.string.isRequired,
        isLocked: PropTypes.bool.isRequired,
        linkedCampaigns: PropTypes.array,
        startDate: PropTypes.instanceOf(Date).isRequired,
        status: PropTypes.string.isRequired
    }).isRequired,
    formatMessage: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    handleLockToggle: PropTypes.func.isRequired,
};

export default Adverts;
