import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Box, Button, Unstable_Grid2 as Grid
} from '@mui/material';

import {
    QrCodeRounded as QrCodeIcon,
} from '@mui/icons-material';

import { FormattedMessage } from 'react-intl';

import QrModal from './QrModal';
import { useAccountInfo } from '../contexts/AccountInfo';
import { Colors } from '../themes/default';

const designToEmRatio = 18;

const buttonStyles = {
    position: 'absolute',
    width: `${3 / designToEmRatio}em`,
    background: `linear-gradient(90deg, ${Colors.seaFoam} 4.71%, ${Colors.main} 95.81%)`,
    borderTopLeftRadius: `${2 / designToEmRatio}em`,
    borderBottomLeftRadius: `${2 / designToEmRatio}em`,
};

const leftButtonStyles = {
    ...buttonStyles,
    left: -6
};

const rightButtonStyles = {
    ...buttonStyles,
    right: -6,
    transform: 'rotate(180deg)'
};

const PhoneElement = ({ children, sx }) => (
    <Grid
        sx={{
            aspectRatio: 0.5086, // according to the dimensions of the design
            border: 3,
            borderColor: 'primary.main',
            borderRadius: `${24 / designToEmRatio}em`,
            width: '20em',
            maxWidth: '85%',
            position: 'relative',
            ...sx
        }}
        xs={12}
    >
        <Box sx={{ ...leftButtonStyles, top: `${98 / designToEmRatio}em`, height: `${24 / designToEmRatio}em` }} />
        <Box sx={{ ...leftButtonStyles, top: `${144 / designToEmRatio}em`, height: `${43 / designToEmRatio}em` }} />
        <Box sx={{ ...leftButtonStyles, top: `${197 / designToEmRatio}em`, height: `${42 / designToEmRatio}em` }} />
        <Grid
            sx={{
                overflow: 'hidden',
                borderRadius: `${20 / designToEmRatio}em`,
                height: '100%',
            }}
        >
            {children}
        </Grid>
        <Box sx={{ ...rightButtonStyles, top: `${150 / designToEmRatio}em`, height: `${83 / designToEmRatio}em` }} />
    </Grid>
);

PhoneElement.propTypes = {
    children: PropTypes.node,
    sx: PropTypes.object
};

PhoneElement.defaultProps = {
    children: null,
    sx: {}
};

/* eslint-disable */
export default function Phone({ phoneSx }) {
    const [showModal, setShowModal] = React.useState(false);
    const [iframeUrl, setIframeUrl] = React.useState(null);
    const { accountData, refreshId } = useAccountInfo();
    const { previewUrl, sanitizedUrl } = accountData;

    useEffect(() => {
        setIframeUrl(null);
        setTimeout(() => setIframeUrl(previewUrl), 100);
    }, [previewUrl, refreshId]);

    return (
        <Grid alignContent='center' container direction='column'>
            <PhoneElement sx={phoneSx}>
                {iframeUrl && <iframe
                    allowFullScreen
                    frameBorder='0'
                    height='126%'
                    src={previewUrl}
                    title='phone'
                    width='126%'
                    style={{ transform: 'scale(0.79365)', transformOrigin: '0 0' }}
                />}
            </PhoneElement>
            <Grid display='flex' justifyContent='center' mt={2}>
                <Button
                    onClick={() => setShowModal(!!previewUrl)}
                    size='medium'
                    startIcon={<QrCodeIcon />}
                    sx={{ px: 2.5 }}
                    variant='outlined'
                >
                    <FormattedMessage defaultMessage='QR code and URL' id='phone.modal.title' />
                </Button>
            </Grid>
            <QrModal closeHandler={() => setShowModal(false)} show={showModal} url={sanitizedUrl} />
        </Grid>
    );
}
Phone.propTypes = {
    phoneSx: PropTypes.object
};
Phone.defaultProps = {
    phoneSx: {}
};
