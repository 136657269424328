// eslint-disable-next-line no-unused-vars
import { UseFormReturn, UseFormProps } from 'react-hook-form';

import { useForm } from 'react-hook-form-mui';
import { useCallback } from 'react';

/**
 * @typedef {UseFormReturn & { setValues: (values: Record<string, any>) => void }} CustomFormReturn
 */

/**
 * @param {Record<string, any>} values
 * @param {UseFormReturn<any, any>} form
 */
export const setValues = (form, values = {}) => {
    Object.entries(values).forEach(([k, v]) => form.setValue(k, v));
};

/**
 * @param {UseFormProps|Object} props
 * @returns CustomFormReturn
 */
export const useCustomForm = (props = {}) => {
    const form = useForm(props);

    return {
        ...form,
        setValues: useCallback((values) => setValues(form, values), [form.setValue]),
    };
};
