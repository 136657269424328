import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { api, useApi } from '../base/Request';
import { groupListByParent } from '../../utils/utils';

import AdvertsFormView from './FormView';

export default function AdvertAdd() {
    const [publicationArticles, setPublicationArticles] = useState({});

    const { request } = useApi();
    const navigate = useNavigate();

    const submitData = async (data) => {
        try {
            const response = await request(api.createAdvert).send({
                dateStart: data?.startDate?.unix() ?? null,
                dateFinal: data?.endDate?.unix() ?? null,
                imageData: data?.advertImage ?? null,
                linkedArticleId: data?.linkedArticle ?? null,
                linkedPage: data?.linkedPage ?? null,
                url: data?.url ?? null,
                isHighlight: false,
                label: data.label || null,
            });

            if (response?.success) {
                navigate('/adverts');
            }
        } catch (err) {
            console.error(err);
        }
    };

    const retrievePublicationArticles = async () => {
        const response = await request(api.getPublicationArticles).send();

        setPublicationArticles(
            groupListByParent(response?.publicationArticles, 'rootName')
        );
    };

    useEffect(() => {
        retrievePublicationArticles();
    }, []);

    return (
        <AdvertsFormView
            isUpdate={false}
            publicationArticles={publicationArticles}
            submitData={submitData}
        />
    );
}
