import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import Swal from 'sweetalert2';
import { useTheme } from '@mui/material/styles';
import DefaultDialog from '../modules/DefaultDialog';

export default function ConfirmationDialog({
    disableAnimation, message, isOpen, onClose, onConfirm
}) {
    const theme = useTheme();
    const { formatMessage } = useIntl();
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const handleConfirm = async () => {
        setIsLoading(true);
        await onConfirm();
        setIsSuccess(true);
        setIsLoading(false);
        onClose();
    };

    useEffect(() => {
        if (isSuccess && !disableAnimation) {
            Swal.fire({
                icon: 'success',
                padding: '0 0 1.9em',
                width: 444,
                timer: 1000,
                showConfirmButton: false,
                scrollbarPadding: false,
                iconColor: theme.palette.primary.main,
                willOpen: () => {
                    // a workaround (not ideal => requires improvement) to decrease visual scrollbar offset
                    // when switching from mui (dialog) to swal (alert)
                    const styleTag = document.createElement('style');
                    styleTag.innerHTML = `
                      body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
                        overflow: auto;
                      }`;
                    document.head.appendChild(styleTag);

                    document.querySelector('.swal2-success-ring')
                        .style.borderColor = theme.palette.primary.mainTransparent;
                },
            }).then(() => {
                setIsSuccess(false);
            });
        }
    }, [isSuccess]);

    return (
        <DefaultDialog
            closeHandler={onClose}
            saveButton={(
                <Button
                    disabled={isLoading}
                    onClick={handleConfirm}
                    size='large'
                    type='submit'
                    variant='contained'
                >
                    {isLoading
                        ? <CircularProgress size={22} />
                        : (
                            <FormattedMessage
                                defaultMessage='Confirm'
                                id='button.confirm'
                            />
                        )}
                </Button>
            )}
            show={isOpen}
            size='xs'
            title={formatMessage({
                defaultMessage: 'Confirmation',
                id: 'common.confirmationTitle'
            })}

        >
            {message || (
                <FormattedMessage
                    defaultMessage='Are you sure you want to proceed?'
                    id='common.confirmationMessage'
                />
            )}
        </DefaultDialog>
    );
}

ConfirmationDialog.propTypes = {
    disableAnimation: PropTypes.bool,
    isOpen: PropTypes.bool.isRequired,
    message: PropTypes.node,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

ConfirmationDialog.defaultProps = {
    disableAnimation: false,
    message: '',
};
