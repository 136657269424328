import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { useParams } from 'react-router-dom';
import { groupListByParent } from '../../utils/utils';
import { useApi, api, noCacheUrl } from '../base/Request';

import HighlightsFormView from './FormView';

export default function HighlightsEdit() {
    const { id } = useParams();

    const [publicationArticles, setPublicationArticles] = useState({});

    const { request } = useApi();

    const retrieveData = async () => {
        const response = await request(api.getHighlight).send({ id });
        const data = response.digiBanner;

        if (data) {
            return {
                startDate: data.dateStart && dayjs.unix(data.dateStart),
                endDate: data.dateFinal && dayjs.unix(data.dateFinal),
                highlightImage: noCacheUrl(data.imageUrl),
                title: data.highlightTitle1,
                subtitle: data.highlightTitle2,
                tagline: data.highlightTitle3,
                lockHighlight: data.isLocked,
                linkedArticle: data.linkedArticleId,
                linkedPage: data.linkedPage,
            };
        }

        return null;
    };

    const submitData = async (data) => {
        const toUpdate = {
            id,
            dateStart: data.startDate?.unix(),
            dateFinal: data.endDate?.unix(),
            highlightTitle1: data.title,
            highlightTitle2: data.subtitle,
            highlightTitle3: data.tagline,
            isLocked: data.lockHighlight,
            linkedArticleId: data?.linkedArticle ?? null,
            linkedPage: data?.linkedPage ?? null,
            url: data?.url ?? null,
            isHighlight: true,
        };

        if (
            data.highlightImage
            && data.highlightImage.startsWith('data:')
            && data.highlightImage.includes('base64')
        ) {
            toUpdate.imageData = data.highlightImage;
        }

        const response = await request(api.updateHighlight).send(toUpdate);

        await retrieveData();

        return response;
    };

    const retrievePublicationArticles = async () => {
        const response = await request(api.getPublicationArticles).send();

        setPublicationArticles(
            groupListByParent(response?.publicationArticles, 'rootName')
        );
    };

    useEffect(() => {
        retrievePublicationArticles();
    }, []);

    return (
        <HighlightsFormView
            isUpdate
            publicationArticles={publicationArticles}
            retrieveData={retrieveData}
            submitData={submitData}
        />
    );
}
