import React from 'react';
import PropTypes from 'prop-types';

const AccountInfoContext = React.createContext(null);

function AccountInfoProvider({ children }) {
    const [accountData, setAccountData] = React.useState({});
    const [updateId, setUpdateId] = React.useState(0);

    const value = React.useMemo(
        () => ({
            accountData,
            setAccountData,
            triggerReload: () => setUpdateId(updateId + 1),
            refreshId: updateId
        }),
        [accountData, updateId]
    );

    return (
        <AccountInfoContext.Provider
            value={value}
        >
            {children}
        </AccountInfoContext.Provider>
    );
}
AccountInfoProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

function useAccountInfo() {
    return React.useContext(AccountInfoContext);
}

export { AccountInfoContext, AccountInfoProvider, useAccountInfo };
