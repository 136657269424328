import React from 'react';
import {
    Box, Card, CardMedia, Typography, Unstable_Grid2 as Grid
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';

export default function PublicationImagePreview({ src, sx }) {
    const theme = useTheme();

    if (src) {
        return (
            <Card
                sx={{
                    display: 'flex',
                    position: 'relative',
                    width: '100%',
                    border: `1px dashed ${theme.palette.border.imageDropzone}`,
                    borderRadius: 0,
                    ...sx
                }}
            >
                <CardMedia
                    alt='publication preview'
                    component='img'
                    src={src}
                    sx={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                />
            </Card>
        );
    }

    return (
        <Box
            color={theme.palette.border.imageDropzone}
            sx={{
                display: 'grid',
                alignItems: 'center',
                pl: 8,
                pr: 8,
                border: `1px dashed ${theme.palette.border.imageDropzone}`,
                borderRadius: 1,
                backgroundColor: theme.palette.background.lightCyan,
                ...sx
            }}
        >
            <Grid
                alignItems='center'
                container
                direction='column'
            >
                <Typography sx={{ color: 'text.darkCharcoal' }} variant='small500'>
                    <FormattedMessage
                        defaultMessage='No publication image selected'
                        id='highlights.form.publication.noImageSelected'
                    />
                </Typography>
                <Typography sx={{ color: 'text.darkCharcoal', mt: 1.5 }} variant='smaller400'>
                    <FormattedMessage
                        defaultMessage='Select an image and a page from the publications list'
                        id='highlights.form.publication.selectImageAndPage'
                    />
                </Typography>
            </Grid>
        </Box>
    );
}

PublicationImagePreview.propTypes = {
    src: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    sx: PropTypes.object
};

PublicationImagePreview.defaultProps = {
    src: null,
    sx: {},
};
