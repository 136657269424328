// const baseUrl = 'https://localhost:80/api/';
export const baseUrl = process.env.REACT_APP_NODE_ENV === 'production'
    ? process.env.REACT_APP_API_URL_PRODUCTION
    : process.env.REACT_APP_API_URL_DEVELOPMENT;

export const actions = {
    login: {
        path: 'index.php?action=login',
        method: 'post',
        includeToken: false
    },
    resetPassword: {
        path: 'index.php?action=resetPassword',
        method: 'post',
        includeToken: false
    },
    getAccount: {
        path: 'index.php?action=relation',
        method: 'get'
    },
    getStatistics: {
        path: 'index.php?action=digiStatistics',
        method: 'get'
    },
    updateAccount: {
        path: 'index.php?action=relation',
        method: 'patch'
    },
    updateContact: {
        path: 'index.php?action=contact',
        method: 'patch'
    },
    getDashboard: {
        path: 'index.php?action=dashboard',
        method: 'get'
    },
    getHighlights: {
        path: 'index.php?action=digiBanners&isHighlight=true',
        method: 'get'
    },
    getHighlight: {
        path: 'index.php?action=digiBanner&isHighlight=true',
        method: 'get'
    },
    updateHighlight: {
        path: 'index.php?action=digiBanner',
        method: 'patch'
    },
    createHighlight: {
        path: 'index.php?action=digiBanner',
        method: 'post'
    },
    deleteHighlight: {
        path: 'index.php?action=digiBanner',
        method: 'delete'
    },
    getAdverts: {
        path: 'index.php?action=digiBanners&isHighlight=false',
        method: 'get'
    },
    getAdvert: {
        path: 'index.php?action=digiBanner&isHighlight=false',
        method: 'get'
    },
    updateAdvert: {
        path: 'index.php?action=digiBanner',
        method: 'patch'
    },
    createAdvert: {
        path: 'index.php?action=digiBanner',
        method: 'post'
    },
    deleteAdvert: {
        path: 'index.php?action=digiBanner',
        method: 'delete'
    },
    getPublicationPages: {
        path: 'index.php?action=publicationPages',
        method: 'get'
    },
    getNewsstandSettings: {
        path: 'index.php?action=digiDepartment',
        method: 'get'
    },
    updateNewsstandSettings: {
        path: 'index.php?action=digiDepartment',
        method: 'patch'
    },
    getNewsstandCustom: {
        path: 'index.php?action=digiPlatformCustomisation',
        method: 'get'
    },
    updateNewsstandCustom: {
        path: 'index.php?action=digiPlatformCustomisation',
        method: 'put'
    },
    getCampaign: {
        path: 'index.php?action=digiCampaign',
        method: 'get'
    },
    getCampaigns: {
        path: 'index.php?action=digiCampaigns',
        method: 'get',
    },
    updateCampaign: {
        path: 'index.php?action=campaign',
        method: 'patch'
    },
    createCampaign: {
        path: 'index.php?action=campaign',
        method: 'post'
    },
    duplicateCampaign: {
        path: 'index.php?action=duplicateCampaign',
        method: 'post'
    },
    getIpAddresses: {
        path: 'index.php?action=campaignIpAddresses',
        method: 'get'
    },
    getIpAddress: {
        path: 'index.php?action=campaignIpAddress',
        method: 'get'
    },
    updateIpAddress: {
        path: 'index.php?action=campaignIpAddress',
        method: 'patch'
    },
    createIpAddress: {
        path: 'index.php?action=campaignIpAddress',
        method: 'post'
    },
    deleteIpAddress: {
        path: 'index.php?action=campaignIpAddress',
        method: 'delete'
    },
    getArticlePublications: {
        path: 'index.php?action=articlePublications',
        method: 'get'
    },
    getPublicationArticles: {
        path: 'index.php?action=publicationArticles',
        method: 'get'
    },
    getPublicationArticle: {
        path: 'index.php?action=publicationArticle',
        method: 'get'
    },
    updatePublicationArticle: {
        path: 'index.php?action=article',
        method: 'patch'
    },
    getSupplements: {
        path: 'index.php?action=privatePublications',
        method: 'get'
    },
    downloadEmailsAccessData: {
        path: 'index.php?action=downloadDepartmentEmails',
        method: 'download'
    },
    downloadDepartmentRegistrants: {
        path: 'index.php?action=downloadDepartmentRegistrants',
        method: 'download'
    },
    uploadEmailsAccessData: {
        path: 'index.php?action=uploadDepartmentEmails',
        method: 'post'
    },
    getTokenTypes: {
        path: 'index.php?action=digiTokenTypes',
        method: 'get'
    },
    getCoordinates: {
        path: 'index.php?action=campaignCoordinates',
        method: 'get'
    },
    getCoordinate: {
        path: 'index.php?action=campaignCoordinate',
        method: 'get'
    },
    createCoordinate: {
        path: 'index.php?action=campaignCoordinate',
        method: 'post'
    },
    updateCoordinate: {
        path: 'index.php?action=campaignCoordinate',
        method: 'patch'
    },
    deleteCoordinate: {
        path: 'index.php?action=campaignCoordinate',
        method: 'delete'
    },
    createSupplement: {
        path: 'index.php?action=digiRelationPrivateDocument',
        method: 'post'
    },
    updateSupplement: {
        path: 'index.php?action=digiRelationPrivateDocument',
        method: 'patch'
    },
    createSupplementCategory: {
        path: 'index.php?action=digiRelationPrivateDocumentCategory',
        method: 'post'
    },
    deleteSupplementCategory: {
        path: 'index.php?action=digiRelationPrivateDocumentCategory',
        method: 'delete'
    },
};

export default class Api {
    constructor(databaseName, token, onError = null) {
        this.databaseName = databaseName;
        this.token = token;
        this.onError = onError;

        this.get = this.get.bind(this);
        this.post = this.post.bind(this);
        this.put = this.put.bind(this);
        this.delete = this.delete.bind(this);
        this.request = this.request.bind(this);
        this.requestUrl = this.requestUrl.bind(this);

        Object.keys(actions).forEach((action) => {
            this[action] = (formData) => {
                const { path, method, includeToken } = actions[action];
                return this[method](path, formData, includeToken);
            };
        });
    }

    get(targetUrl, params, includeToken = true) {
        if (!params || !Object.keys(params).length) {
            return this.request(targetUrl, [], 'GET', includeToken);
        }
        // add extra params to path
        let newTargetUrl = targetUrl + (targetUrl.includes('?') ? '&' : '?');
        newTargetUrl += Object.keys(params).map((key) => `${key}=${params[key]}`).join('&');
        return this.request(newTargetUrl, [], 'GET', includeToken);
    }

    patch(targetUrl, formData, includeToken = true) {
        return this.request(targetUrl, formData, 'PATCH', includeToken);
    }

    post(targetUrl, formData, includeToken = true) {
        return this.request(targetUrl, formData, 'POST', includeToken);
    }

    put(targetUrl, formData, includeToken = true) {
        return this.request(targetUrl, formData, 'PUT', includeToken);
    }

    delete(targetUrl, formData, includeToken = true) {
        return this.request(targetUrl, formData, 'DELETE', includeToken);
    }

    download(targetUrl, defaultFilename = 'download', includeToken = true) {
        return this.downloadUrl(baseUrl, targetUrl, defaultFilename, includeToken);
    }

    request(targetUrl, formData, method, includeToken = true) {
        return this.requestUrl(baseUrl, targetUrl, formData, method, includeToken);
    }

    // TODO: Improve API integration to handle TTL and fallback features for better data management
    requestUrl(base, targetUrl, formData, method, includeToken = true) {
        let target = base + targetUrl;

        const config = {
            method
        };
        if (method !== 'GET') {
            config.headers = {
                'Content-Type': 'application/json'
            };
            config.body = formData;
            if (includeToken) {
                config.body = {
                    ...config.body,
                    token: this.token
                };
            }
            config.body = JSON.stringify(config.body);
        } else if (includeToken) {
            // add token to path, or extend query string
            target += target.includes('?') ? `&token=${this.token}` : `?token=${this.token}`;
        }
        // Use fetch
        return fetch(target, config)
            .then((response) => {
                if (response.status >= 400) {
                    if (this.onError) {
                        this.onError(response);
                    }
                }
                if (response.status === 204) {
                    return null;
                }
                return response.json();
            })
            .then((response) => {
                if (!response) {
                    throw new Error('An error occurred');
                }

                return response;
            });
    }

    downloadUrl(base, targetUrl, defaultFilename, includeToken) {
        let target = base + targetUrl;

        const config = {
            method: 'GET'
        };
        if (includeToken) {
            // add token to path, or extend query string
            target += target.includes('?') ? `&token=${this.token}` : `?token=${this.token}`;
        }

        let headers = {};
        return fetch(target, config)
            .then((response) => {
                if (response.status >= 400) {
                    if (this.onError) {
                        this.onError(response);
                    }
                }
                if (response.status === 204) {
                    return null;
                }
                headers = response.headers;
                return response.blob();
            })
            .then((blob) => {
                const urlBlob = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = urlBlob;
                // Get filename from header
                const filename = headers.get('Content-Disposition')?.split('filename=')[1] || defaultFilename;
                a.download = filename.replace(/"/g, '');
                a.click();
            });
    }
}
