import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import TabsPage from '../../modules/TabsPage';

import DocumentsForm from './supplements/Documents';
import MenuForm from './supplements/Menu';

import { useAuth } from '../../contexts/Auth';
import ApiUtils from '../base/Api';
import { formatDate } from '../../utils/utils';

const SupplementsView = (props) => {
    const {
        retrieveCustomisation,
        retrieveDocuments,
        submitCustomisation,
        updateCategory,
        updateDocument,
    } = props;
    return (
        <TabsPage
            pages={[
                {
                    name: 'Documents',
                    component: (
                        <DocumentsForm
                            getData={retrieveDocuments}
                            submitCategory={updateCategory}
                            submitDocument={updateDocument}
                        />
                    )
                },
                {
                    name: 'Menu settings',
                    component: (
                        <MenuForm
                            getData={retrieveCustomisation}
                            submitData={submitCustomisation}
                        />
                    )
                }
            ]}
        />
    );
};
SupplementsView.propTypes = {
    retrieveCustomisation: PropTypes.func.isRequired,
    retrieveDocuments: PropTypes.func.isRequired,
    submitCustomisation: PropTypes.func.isRequired,
    updateCategory: PropTypes.func.isRequired,
    updateDocument: PropTypes.func.isRequired,
};

const Supplements = () => {
    const authContext = useAuth();
    const intlContext = useIntl();
    const { Api } = ApiUtils.useApi(authContext, intlContext);
    const originalCustomData = React.useRef({});

    const retrieveDocuments = async () => {
        const response = await Api.getSupplements();
        if (response.success
            && response.privatePublications
        ) {
            const categories = response.privatePublications.categories || [];
            const documents = response.privatePublications.documents || [];
            // documents: [
            // {
            //     id: 1,
            //     number: 1,
            //     date: dayjs('2024-01-08'),
            //     name: 'Document 1 name',
            //     description: 'This is document one',
            //     category: 1,
            //     pages: 12,
            // },
            return {
                categories,
                documents: documents.map((doc) => ({
                    id: doc.id,
                    date: (doc.dateCreated && formatDate(doc.dateCreated)) || 'N/A',
                    number: doc.id,
                    name: doc.label,
                    description: doc.description,
                    category: doc.categoryId,
                    pages: doc.quantityProcessedContent,
                })),
            };
        }
        return {
            documents: [],
            categories: []
        };
    };

    const updateDocument = async (data, isDelete) => {
        if (isDelete) {
            // Its a delete and data structure from the API
            return Api.updateSupplement({
                id: data.id,
                label: data.name,
                isArchived: true
            });
        }
        // Its an update or create
        const toSend = {
            label: data.documentName,
            description: data.documentDescription,
            categoryId: data.documentCategory,
        };
        // Add id in case we are updating
        if (data.currentDocumentId) {
            toSend.id = data.currentDocumentId;
            return Api.updateSupplement(toSend);
        }
        toSend.file = data.documentFile.data;
        toSend.fileName = data.documentFile.name;
        return Api.createSupplement(toSend);
    };

    const updateCategory = async (data, isDelete) => {
        if (isDelete) {
            return Api.deleteSupplementCategory({
                id: data.id,
                label: data.label
            });
        }
        const toCreate = {
            label: data.categoryName,
        };
        // Create new category
        return Api.createSupplementCategory(toCreate);
    };

    const retrieveCustomisation = async () => {
        const response = await Api.getNewsstandCustom();
        if (response.success && response.digiPlatformCustomisation) {
            const data = response.digiPlatformCustomisation?.relationDetails || {};
            const customData = response.digiPlatformCustomisation?.customData;
            if (customData) {
                // Set original custom data to use in submitData
                originalCustomData.current = customData;
            }
            return {
                title: data.documentTitle,
                documentViewDefault: data.hasDefaultDocumentView,
                allowDownload: data.allowDocumentDownload,
                sideMenuBackground: customData?.privatePdfBackgroundSrc,
            };
        }
        return null;
    };

    const submitCustomisation = async (data) => {
        const toSend = {
            documentTitle: data.title,
            hasDocumentViewAsDefault: !!data.documentViewDefault,
            hasDocumentDownloadOption: !!data.allowDownload,
            objCustomisation: {
                ...originalCustomData.current,
                privatePdfBackgroundSrc: data.sideMenuBackground
            }
        };
        const ret = await Api.updateNewsstandCustom(toSend);
        return ret;
    };

    return (
        <SupplementsView
            retrieveCustomisation={retrieveCustomisation}
            retrieveDocuments={retrieveDocuments}
            submitCustomisation={submitCustomisation}
            updateCategory={updateCategory}
            updateDocument={updateDocument}
        />
    );
};

export default Supplements;
