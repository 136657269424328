import React from 'react';
import {
    AppBar, Box, Button, IconButton, Toolbar, Typography
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import stringToCase from 'to-case';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ArrowBackIos as BackIcon } from '@mui/icons-material';

import {
    getHeaderButton, getPageName, getMainPageName, getParentPath
} from '../utils/routing';

export default function TopBar() {
    const location = useLocation().pathname;
    const pageName = getPageName(location);
    const mainPageName = getMainPageName(location);
    const showBackButton = pageName !== mainPageName;
    const parentPage = getParentPath(location);
    const headerButton = getHeaderButton(location);
    const navigate = useNavigate();

    return (
        <AppBar
            color='default'
            position='fixed'
            sx={{
                border: 'none',
                borderBottom: '1px solid',
                borderBottomColor: 'text.mutedLightGray'
            }}
        >
            <Toolbar>
                {showBackButton && (
                    <Link to={parentPage}>
                        <Box
                            sx={{
                                height: '100%',
                                width: '36px',
                                mr: 3
                            }}
                        >
                            <IconButton
                                aria-label='menu'
                                edge='end'
                                size='small'
                                sx={{
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    height: 80,
                                    width: 60,
                                    pl: '14px',
                                    borderRadius: 0,
                                    borderRight: '1px solid',
                                    borderRightColor: 'text.mutedLightGray',
                                }}
                            >
                                <BackIcon fontSize='small' sx={{ height: 18 }} />
                            </IconButton>
                        </Box>
                    </Link>
                )}
                <Typography component='div' noWrap variant='h4'>
                    <FormattedMessage defaultMessage={mainPageName} id={`pages.${stringToCase.camel(mainPageName)}`} />
                </Typography>
                {headerButton && (
                    <Box sx={{ ml: 'auto', display: 'flex', justifyContent: 'center' }}>
                        <Button
                            color='primary'
                            disableElevation
                            onClick={() => {
                                navigate(headerButton.route);
                            }}
                            size='large'
                            startIcon={(<headerButton.icon />)}
                            sx={{
                                borderRadius: 0.5,
                                paddingTop: 1,
                                paddingBottom: 1
                            }}
                            variant='contained'
                        >
                            <FormattedMessage
                                defaultMessage={headerButton.text}
                                id={`pages.${stringToCase.camel(headerButton.text)}.add`}
                            />
                        </Button>
                    </Box>
                )}
            </Toolbar>
        </AppBar>
    );
}
