import { createTheme } from '@mui/material/styles';

export const Colors = {
    lightCyan: '#f8fdfd',
    softMint: '#d3f2f0',
    middleBlueGreen: '#8fddd9',
    seaFoam: '#91e0dc',
    aqua: '#67d0cb',
    main: '#25bcb4',
    mainTransparent: '#25bcb44d',
    frostedTeal: '#25BCB41C',
    whiteGhost: '#fafbfc',
    white: '#ffffff',
    alabaster: '#f3f3f3',
    lightSilver: '#e0e0e0',
    silver: '#cccccc',
    topBarBorders: '#d9e0e7',
    inactiveWhite: '#ffffffb2',
    lightGray: '#b6b6b6',
    neutralGray: '#afafaf',
    grayishBlue: '#889198',
    textLight: '#73797e',
    charcoalGray: '#757575',
    whiteTransparent30: '#ffffff4c',
    darkGrayOpaque: '#5d6267ff',
    darkSlate: '#3c3c3c',
    darkCharcoal: '#333333',
    charcoalBlack: '#262a2d',
    faintRose: '#ee61610c',
    softCoral: '#e78d8d93',
    faintCrimson: '#ff000038',
    red: '#d32f2f',
    azureBlue: '#1b77bc',
    blue: '#1976d2',
    midnightBlue: '#15222d',
    textDark: '#171f26',
    nightshade: '#1c2224',
    blackTransparent: '#0000003a',
    blackTransparent60: '#00000099',
    black: '#000000',
};

export const digiMagsDefaultColors = {
    general: {
        background: Colors.charcoalBlack,
        font: Colors.silver
    },
    header: {
        background: Colors.nightshade,
        font: Colors.silver
    },
    footer: {
        background: Colors.nightshade,
        font: Colors.silver
    },
    container: {
        background: Colors.alabaster,
        font: Colors.darkSlate
    },
    highlight: Colors.main,
    line: Colors.alabaster
};

// Old colors DEPRECATED
// const main = '#25bcb4';
// const topBarBorders = '#d9e0e7';
// const textDark = '#171f26';
// const textLight = '#73797e';
// const lightGrayFormBackground = '#fafbfc';
// const alertRed = '#d32f2f';
// const aquaBackground = '#67D0CB';
// const softMint = '#d3f2f0';

export default createTheme({
    palette: {
        primary: {
            main: Colors.main,
            mainTransparent: Colors.mainTransparent,
            delete: Colors.red,
        },
        text: {
            primary: Colors.black,
            button: Colors.white,
            light: Colors.textLight,
            dark: Colors.textDark,
            darkGray: Colors.grayishBlue,
            mutedLightGray: Colors.lightGray,
            darkCharcoal: Colors.darkCharcoal,
            blackTransparent60: Colors.blackTransparent60,
            zeroStateNumber: Colors.darkGrayOpaque,
            default: Colors.silver
        },
        background: {
            lightGreen: Colors.softMint,
            lightCyan: Colors.lightCyan,
            lightGrayForm: Colors.whiteGhost,
            colorPicker: Colors.charcoalBlack,
        },
        border: {
            topBar: Colors.topBarBorders,
            input: Colors.blackTransparent,
            imageDropzone: Colors.middleBlueGreen,
            listItem: Colors.lightSilver
        },
        icon: {
            mutedGray: Colors.neutralGray,
            secondaryGray: Colors.charcoalGray,
        },
        custom: {
            azureBlue: Colors.azureBlue,
        }
        // Old colors DEPRECATED
        // primary: {
        //     main,
        //     delete: alertRed,
        // },
        // text: {
        //     primary: '#000000',
        //     button: '#ffffff'
        // },
        // mainTransparent: '#25bcb44d',
        // textLight,
        // textDark,
        // textDarkGray: '#889198',
        // topBarBorders,
        // lightGreenBackground: '#e9f8f7',
        // lightGrayFormBackground,
        // inputBorder: 'rgba(0, 0, 0, 0.23)',
        // zeroStateNumber: '#5d6267ff',
    },
    typography: {
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 16,
        color: Colors.textDark,
        smaller400: {
            fontSize: 12,
            fontWeight: 400
        },
        small400: {
            fontSize: 14,
            fontWeight: 400
        },
        small500: {
            fontSize: 14,
            fontWeight: 500
        },
        small600: {
            fontSize: 14,
            fontWeight: 600
        },
        medium400: {
            fontSize: 16,
            fontWeight: 400
        },
        medium500: {
            fontSize: 16,
            fontWeight: 500
        },
        medium600: {
            fontWeight: 600
        },
        large400: {
            fontSize: 18,
            fontWeight: 400
        },
        h4: {
            fontWeight: '700',
            fontSize: '24px'
        },
        button: {
            textTransform: 'none'
        },
        buttonTextIcon: {
            fontSize: 29,
            lineHeight: '12px',
            fontWeight: '300'
        },
        sectionHeader: {
            fontSize: 18,
            fontWeight: 600,
        },
        blockTitle: {
            fontSize: 16,
            fontWeight: 500,
        },
        blockSubtitle: {
            fontSize: 14,
            fontWeight: 400,
            color: Colors.textLight
        },
        layoutOption: {
            fontSize: 14,
            fontWeight: 400,
            color: Colors.textDark
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            modalSm: 600,
            modalMd: 750,
            modalLg: 800,
            modalXl: 900,
        }
    },
    shape: {
        borderRadius: 8
    },
    components: {
        MuiPopover: {
            styleOverrides: {
                paper: {
                    boxShadow: 'none',
                    border: `1px solid ${Colors.lightGray}`,
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    boxShadow: 'none',
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                a: {
                    textDecoration: 'none'
                },
                '.alternate-rows > div:nth-child(even):not(:last-child)': {
                    backgroundColor: Colors.whiteGhost,
                    borderRadius: 12,
                },
                '.alternate-rows-odd > div:nth-child(odd):not(:last-child)': {
                    backgroundColor: Colors.whiteGhost,
                    borderRadius: 12,
                }
            }
        },
        MuiContainer: {
            styleOverrides: {
                maxWidthXl: {
                    paddingLeft: 32,
                    paddingRight: 32
                }
            }
        },
        MuiGrid2: {
            styleOverrides: {
                root: {
                    ' .fui-dropzone-root': {
                        fontSize: 'inherit',
                        fontFamily: 'inherit',
                        lineHeight: 1.4
                    }
                }
            }
        },
        MuiPopper: {
            styleOverrides: {
                root: {
                    border: `1px solid ${Colors.lightGray}`,
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                },
            },
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    height: 80
                }
            }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    width: 'calc(100% - 248px)',
                    ml: 248
                },
                colorDefault: {
                    backgroundColor: Colors.white,
                    borderBottom: '1px solid',
                    borderBottomColor: Colors.topBarBorders,
                    boxShadow: 'none'
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    background: `linear-gradient(90deg, ${Colors.main} 0%, ${Colors.main} 100%);`,
                    backgroundSize: 'calc(100% - 32px)',
                    backgroundRepeat: 'no-repeat',
                    backgroundPositionX: 16
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    '&:hover': {
                        color: Colors.black
                    },
                    '&.Mui-selected': {
                        color: Colors.textDark
                    }
                }
            }
        },
        MuiTextField: {
            defaultProps: {
                fullWidth: true
            },
            styleOverrides: {
                root: {
                    background: Colors.white
                }
            }
        },
        MuiTiptap: {
            styleOverrides: {
                root: {
                    '&.tiptap': {
                        height: 200
                    },
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    background: 'unset',
                    paddingTop: 0,
                    paddingBottom: 0,
                    '&:not(.Mui-checked) > svg': {
                        fill: Colors.neutralGray
                    },
                    '&:hover': {
                        background: 'none'
                    }
                }
            }
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    '& .MuiTouchRipple-root': {
                        color: Colors.whiteGhost,
                    },
                },
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                    paddingLeft: 16,
                    paddingRight: 16,
                    boxShadow: 'none !important',
                },
                sizeSmall: {
                    height: 36,
                    fontSize: 14
                },
                sizeMedium: {
                    height: 40,
                    paddingLeft: 20,
                    paddingRight: 20,
                    fontSize: 14
                },
                sizeLarge: {
                    height: 44,
                    paddingLeft: 24,
                    paddingRight: 24,
                    fontSize: 16
                },
                contained: {
                    color: Colors.white,
                    '&:hover': {
                        backgroundColor: Colors.aqua
                    }
                }
            },
            variants: [
                {
                    props: { variant: 'delete' },
                    style: {
                        color: Colors.red,
                        border: '1px solid',
                        borderColor: Colors.softCoral,
                        '&:hover': {
                            backgroundColor: Colors.faintRose,
                            borderColor: Colors.red
                        },
                        '& .MuiTouchRipple-root': {
                            color: Colors.faintCrimson,
                        },
                    },
                },
                {
                    props: { variant: 'text' },
                    style: {
                        '&:hover': {
                            backgroundColor: Colors.frostedTeal
                        }
                    }
                },
                {
                    props: { variant: 'onlyIcon' },
                    style: {
                        paddingLeft: 0,
                        paddingRight: 0,
                        color: Colors.textLight,
                        '& > span': {
                            marginLeft: 0,
                            marginRight: 0
                        },
                        '&:hover': {
                            backgroundColor: 'transparent',
                            color: Colors.textDark
                        }
                    }
                },
                {
                    props: { variant: 'onlyIcon', size: 'small' },
                    style: {
                        minWidth: 32,
                    }
                },

                {
                    props: { variant: 'onlyIcon', size: 'large' },
                    style: {
                        minWidth: 36,
                    }
                },
                {
                    props: { variant: 'iconWithCheck' },
                    style: {
                        '& ::before': {
                            content: '"\\2713"',
                            fontSize: 10,
                            color: Colors.main,
                            position: 'absolute',
                            top: 5,
                            left: 14,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: Colors.softMint,
                            width: 14,
                            height: 14,
                            borderRadius: '100%',
                            fontWeight: 900,
                        },
                        paddingLeft: 0,
                        paddingRight: 0,
                        color: Colors.textLight,
                        '& > span': {
                            marginLeft: 0,
                            marginRight: 0
                        },
                        '&:hover': {
                            backgroundColor: 'transparent',
                            color: Colors.textDark
                        }
                    }
                },
                {
                    props: { variant: 'iconWithCheck', size: 'small' },
                    style: {
                        minWidth: 32,
                    }
                },

                {
                    props: { variant: 'iconWithCheck', size: 'large' },
                    style: {
                        minWidth: 36,
                    }
                },

            ]
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: 0
                }
            }
        },
        MuiSlider: {
            styleOverrides: {
                markLabel: {
                    color: Colors.textLight,
                    fontSize: 14,
                    fontWeight: 400
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    '&.MuiChip-clickableColorPrimary': {
                        '&:hover': {
                            backgroundColor: Colors.aqua,
                        },
                    },
                },
                colorPrimary: {
                    color: Colors.white
                },
                deleteIcon: {
                    fontSize: 16,
                    color: Colors.textLight,
                    marginLeft: -4,
                    marginRight: 8
                },
                icon: {
                    fontSize: 20,
                    marginLeft: 8
                }
            }
        },
    }
});
