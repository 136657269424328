import Swal from 'sweetalert2';

import { useIntl } from 'react-intl';
import { useAuth } from '../../contexts/Auth';

import ApiClass from '../../utils/api';

class ApiUtils {
    static useApi(authContext, intlContext) {
        const { stored, signout } = authContext;
        const { formatMessage } = intlContext;
        const { token, database } = stored || {};

        const Api = new ApiClass(database, token, (errorResponse) => {
            if (errorResponse && errorResponse.status === 401) {
                signout();
            } else {
                errorResponse.clone().json().then((data) => {
                    if (data.serverStatus) {
                        Swal.fire({
                            title: formatMessage({
                                defaultMessage: 'Error',
                                id: 'common.error'
                            }),
                            text: data.serverStatus,
                            icon: 'error',
                            confirmButtonText: formatMessage({
                                defaultMessage: 'OK',
                                id: 'common.ok'
                            })
                        });
                    }
                });
            }
        });
        return { Api };
    }
}

export function useApiClass(auth = useAuth(), intl = useIntl()) {
    return ApiUtils.useApi(auth, intl).Api;
}

export default ApiUtils;
