import localStorageFallback from 'local-storage-fallback';
import ApiClass from './api';

const Api = new ApiClass();

const fakeAuthProvider = {
    retrieveSignedIn: () => ({
        database: localStorageFallback.getItem('database'),
        token: localStorageFallback.getItem('token')
    }),
    signin: (database, email, password, callback) => {
        Api.login({
            databaseName: database,
            email,
            password
        })
            .then((response) => {
                if (response && response.token) {
                    localStorageFallback.setItem('database', database);
                    localStorageFallback.setItem('token', response.token);
                    callback(response.token);
                } else if (response && !response.success && response.serverStatus) {
                    callback(null, response.serverStatus);
                } else {
                    throw new Error('Invalid login attempt');
                }
            })
            .catch((error) => {
                console.error('Login error:', error);
                callback(null, 'Network error');
            });
    },
    signout: (callback) => {
        localStorageFallback.removeItem('database');
        localStorageFallback.removeItem('token');
        if (callback) callback();
    },
};

export default fakeAuthProvider;
