import { useState } from 'react';

/**
 * @param {object} [initialState={}]
 */
// eslint-disable-next-line import/prefer-default-export
export const useErrorBag = (initialState = {}) => {
    const [errors, setErrors] = useState(initialState);

    /**
     * @param {array} err
     * @return {boolean}
     */
    const has = (err) => {
        if (!Array.isArray(err) || !err.length) {
            return false;
        }

        return err.every((e) => Boolean(errors[e]) === true);
    };

    /**
     * @param {array} err
     */
    const clear = (err) => {
        if (!Array.isArray(err)) {
            return;
        }

        const newErrors = { ...errors };

        err.forEach((e) => delete newErrors[e]);

        setErrors(newErrors);
    };

    /**
     * @param {Record<string, boolean>} err
     */
    const set = (err) => {
        setErrors((prev) => ({ ...prev, ...err }));
    };

    /**
     * @private
     */
    const _getErrorAmount = () => Object.values(errors).filter((value) => value === true).length;

    return {
        has,
        clear,
        set,
        errors,
        length: _getErrorAmount()
    };
};
