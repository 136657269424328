import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { DialogContext } from '../../contexts/Dialog';

/**
 * @typedef {Object} DialogTypes
 * @property {number} Error
 * @property {number} Info
 * @property {number} Confirm
 * @property {number} Default
 * @property {number} Warning
 */

/**
 * @typedef {Object} DialogButtonTypes
 * @property {number} Cancel
 */

/**
 * @typedef {Object} DialogEventTypes
 * @property {number} Confirmed
 * @property {number} Dismissed
 */

/**
 * @typedef {Object} DialogOptions
 * @property {typeof DialogTypes} [type=null]
 * @property {string[]|string|null|ReadableStream<Uint8Array>} [body=null]
 * @property {string[]|string|null} [title=null]
 * @property {string} [size='large']
 * @property {SweetAlertIcon} [icon=null]
 * @property {string[]|string|null} [confirmButtonText=null]
 * @property {boolean|false} [disableCancelButton=false]
 * @property {DialogButtonTypes[]|null} [buttons=null]
 */

/**
 * @readonly
 * @enum {number}
 * @type {DialogTypes}
 */
export const DialogTypes = {
    Default: 1,
    Error: 0,
    Info: 1,
    Confirm: 2,
    Warning: 3,
};

/**
 * @readonly
 * @enum {number}
 * @type {DialogButtonTypes}
 */
export const DialogButtonTypes = {
    Cancel: 0,
};

/**
 * @readonly
 * @enum {number}
 * @type {DialogEventTypes}
 */
export const DialogEventTypes = {
    Confirmed: 0,
    Dismissed: 1,
};

/**
 * @param {DialogOptions} options
 * @param {DialogContextOptions} context
 */
export const dialog = (options, context) => {
    let methods;

    /**
     * @type {Record<string, (options: DialogOptions) => void|Promise<void>>}
     */
    const events = {
        [DialogEventTypes.Confirmed]: () => {
        },
        [DialogEventTypes.Dismissed]: () => {
        }
    };

    /**
     * @param {function(DialogOptions): void} callback
     */
    const onConfirm = (callback) => {
        events[DialogEventTypes.Confirmed] = callback;

        return methods;
    };

    /**
     * @param {function(DialogOptions): void} callback
     */
    const onDismissed = (callback) => {
        events[DialogEventTypes.Dismissed] = callback;

        return methods;
    };

    const show = () => {
        const { onClose, onSubmit, show: _show } = context;

        onClose(() => {
            events[DialogEventTypes.Dismissed]?.(options);
        });

        onSubmit(() => {
            events[DialogEventTypes.Confirmed]?.(options);
        });

        _show(true, options);
    };

    methods = {
        show,
        onConfirm,
        onDismissed
    };

    return methods;
};

/**
 * @param {Array<string|any>} data
 * @param intl
 * @return {string}
 */
export const translate = (data, intl = useIntl()) => (
    Array.isArray(data) && data.length >= 2 ? intl.formatMessage({ defaultMessage: data?.[0], id: data?.[1] }) : data
);

/**
 * @param intl
 * @param context
 */
export const useDialog = (intl = useIntl(), context = useContext(DialogContext)) => {
    /**
     * @param {DialogOptions} options
     * @private
     */
    const toOptions = (options) => {
        const temp = {};

        if (options?.title) {
            temp.title = translate(options.title, intl);
        }

        if (options?.body) {
            temp.body = translate(options.body, intl);
        }

        if (options?.confirmButtonText) {
            temp.confirmButtonText = translate(options.confirmButtonText, intl);
        }

        if (options?.size) {
            temp.size = options.size;
        }

        if (options?.disableCancelButton) {
            temp.disableCancelButton = options.disableCancelButton;
        }

        return temp;
    };

    /**
     * @type {Record<DialogTypes, function>}
     */
    const config = {
        [DialogTypes.Error]: (options) => toOptions({
            icon: 'error',
            title: [
                'Error',
                'notification.default.error.title'
            ],
            ...options,
        }),

        [DialogTypes.Info]: (options) => toOptions({
            title: [
                'Info',
                'notification.default.info.title'
            ],
            ...options,
        }),

        [DialogTypes.Confirm]: (options) => toOptions({
            body: [
                'Are you sure?',
                'notification.default.confirm'
            ],
            title: [
                'Confirm',
                'notification.default.confirm.title'
            ],
            buttons: [
                DialogButtonTypes.Cancel
            ],
            ...options,
        }),

        [DialogTypes.Warning]: (options) => toOptions({
            icon: 'warning',
            title: [
                'Are you sure?',
                'notification.default.confirm.title'
            ],
            ...options,
        }),
    };

    /**
     * @param {DialogOptions} options
     */
    const createDialog = (options = {}) => {
        const data = config[options?.type in config ? options?.type : DialogTypes.Default]?.(options);

        return dialog(data, context);
    };

    return { createDialog };
};
