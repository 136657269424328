import React from 'react';
import dayjs from 'dayjs';

import { useNavigate, useParams } from 'react-router-dom';
import { useApi, api } from '../base/Request';

import CampaignFormView from './FormView';

export default function CampaignEdit() {
    const { id } = useParams();

    const { request } = useApi();
    const navigate = useNavigate();

    const submitData = async (data) => {
        const geoId = data.campaignType === 'geolocation' ? 1 : null;

        const response = await request(api.updateCampaign).send({
            id,
            geoId,
            label: data.name,
            hasEmailAccess: data.verifyEmail,
            hasEmailRegistration: data.emailRequired,
            tokenTypeId: data.tokenTypeId,
            linkedArticleId: data.linkedArticle,
            linkedPage: data.linkedPage,
            dateStart: data.startDate?.unix(),
            dateFinal: data.endDate?.unix(),
            listBannerId: data.adverts,
            hasEmailAccessDirectToken: true,
            hasRegistrationDirectToken: true,
        });

        if (response?.success) {
            navigate('/campaigns');
        }
    };

    const getIpAddresses = (addresses) => {
        if (!addresses) {
            return [];
        }

        return addresses.map((address) => ({
            label: address.label,
            address: address.ip,
            range: address.ipMask,
        }));
    };

    const getTokenTypes = (tokenTypes) => {
        if (!tokenTypes) {
            return [];
        }

        return tokenTypes.map((token) => ({
            id: token.id,
            dictionary: token.dictionary,
        }));
    };

    const retrieveData = async () => {
        const response = await request(api.getCampaign).send({ campaignId: id });
        const campaign = response?.digiCampaign ?? null;

        if (campaign) {
            return {
                id: campaign.id,
                name: campaign.label,
                emailRequired: campaign.hasEmailRegistration,
                verifyEmail: campaign.hasEmailAccess,
                startDate: campaign.dateStart,
                endDate: dayjs(campaign.dateFinal),
                isLocked: dayjs(campaign.isLocked),
                type: campaign.type,
                tokenTypes: getTokenTypes(campaign?.objectTokenTypes),
                ipAddresses: getIpAddresses(campaign?.objectIpAddresses),
                tokenTypeId: campaign.tokenTypeId,
                adverts: campaign?.listBannerId || [],
                linkedArticle: campaign.linkedArticleId,
                linkedPage: campaign.linkedPage,
            };
        }

        return null;
    };

    return (
        <CampaignFormView
            isUpdate
            retrieveData={retrieveData}
            submitData={submitData}
        />
    );
}
