import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { useParams } from 'react-router-dom';
import { groupListByParent } from '../../utils/utils';

import AdvertsFormView from './FormView';

import { api, noCacheUrl, useApi } from '../base/Request';
import { imageUrlToBase64 } from '../../utils/image';

export default function AdvertEdit() {
    const { id } = useParams();
    const [publicationArticles, setPublicationArticles] = useState({});
    const [formData, setFormData] = useState(null);

    const { request } = useApi();

    const retrieveData = async () => {
        try {
            const response = await request(api.getAdvert).send({ id });
            const data = response?.digiBanner;

            if (data) {
                const retrievedData = {
                    startDate: data.dateStart ? dayjs.unix(data.dateStart) : null,
                    endDate: data.dateFinal ? dayjs.unix(data.dateFinal) : null,
                    advertImage: noCacheUrl(data.imageUrl) || null,
                    url: data.url || null,
                    linkedArticle: data.linkedArticleId || null,
                    linkedPage: data.linkedPage || null,
                    label: data.label || null,
                };

                setFormData(retrievedData);

                return retrievedData;
            }
        } catch (err) {
            console.error(err);
        }

        return {};
    };

    const submitData = async (data) => {
        try {
            const response = await request(api.updateAdvert).send({
                id,
                dateStart: data.startDate ? data.startDate.unix() : null,
                dateFinal: data.endDate ? data.endDate.unix() : null,
                imageData: await imageUrlToBase64(data.advertImage),
                linkedArticleId: data.linkedArticle || null,
                linkedPage: data.linkedPage || null,
                url: data.url || null,
                label: data.label || null,
            });

            await retrieveData();

            return response;
        } catch (err) {
            console.error(err);
        }

        return null;
    };

    const retrievePublicationArticles = async () => {
        try {
            const response = await request(api.getPublicationArticles).send();

            setPublicationArticles(
                groupListByParent(response?.publicationArticles, 'rootName')
            );
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        retrievePublicationArticles();
        retrieveData();
    }, []);

    return (
        <AdvertsFormView
            formData={formData}
            isUpdate
            publicationArticles={publicationArticles}
            retrieveData={retrieveData}
            submitData={submitData}
        />
    );
}
