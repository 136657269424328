import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/Auth';

export default function Logout() {
    const auth = useAuth();
    const navigate = useNavigate();

    const logoutUser = () => {
        auth.signout(() => {
            navigate('/login', { replace: true });
        });
    };

    useEffect(() => {
        logoutUser();
    }, []);
}
