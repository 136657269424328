import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Button, Typography, Unstable_Grid2 as Grid
} from '@mui/material';
import {
    DeleteOutlined as DeleteIcon,
} from '@mui/icons-material';

import {
    CheckboxElement, FormContainer, TextFieldElement, useForm, useWatch
} from 'react-hook-form-mui';
import InputImageDropzone from '../../../components/InputImageDropzone';
import RequestButton from '../../../components/RequestButton';
import Phone from '../../../modules/Phone';

// title: '',
// documentViewDefault: true,
// allowDownload: false,
// sideMenuBackground: `https://picsum.photos/190/100?random=${Math.random()}`

export default function Menu(props) {
    const { getData, submitData } = props;
    const { formatMessage } = useIntl();
    const {
        control,
        formContext,
        handleSubmit,
        reset,
        setValue
    } = useForm({ defaultValues: getData });

    const submitDataSuccess = async () => {
        const newData = await getData();
        reset(newData);
    };

    // Check image value with useWatch
    const sideMenuBackground = useWatch({ control, name: 'sideMenuBackground' });

    const deleteUpload = (name) => {
        setValue(name, null);
    };

    return (
        <FormContainer formContext={formContext}>
            <Grid container>
                <Grid className='alternate-rows' container direction='column' spacing={2} sx={{ px: 2 }} xs={8}>
                    <Grid container>
                        <Grid xs={4}>
                            <Typography variant='sectionHeader'>
                                <FormattedMessage
                                    defaultMessage='Menu title'
                                    id='newsstand.supplements.menu.title'
                                />
                            </Typography>
                        </Grid>
                        <Grid container direction='column' xs={8}>
                            <Grid>
                                <TextFieldElement
                                    control={control}
                                    label={formatMessage(
                                        { defaultMessage: 'Title', id: 'newsstand.supplements.form.title' }
                                    )}
                                    name='title'
                                />
                            </Grid>
                            <Grid>
                                <CheckboxElement
                                    control={control}
                                    label={formatMessage(
                                        {
                                            defaultMessage: 'Document-view as default',
                                            id: 'newsstand.supplements.menu.documentViewDefault'
                                        }
                                    )}
                                    name='documentViewDefault'
                                />
                            </Grid>
                            <Grid>
                                <CheckboxElement
                                    control={control}
                                    label={formatMessage(
                                        {
                                            defaultMessage: 'Allow download of documents',
                                            id: 'newsstand.supplements.menu.allowDownload'
                                        }
                                    )}
                                    name='allowDownload'
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container sx={{ py: 3, my: 2 }}>
                        <Grid xs={4}>
                            <Typography variant='sectionHeader'>
                                <FormattedMessage
                                    defaultMessage='Side menu background'
                                    id='newsstand.supplements.menu.background'
                                />
                            </Typography>
                        </Grid>
                        <Grid container xs={8}>
                            <Grid alignItems='center' container flexWrap='nowrap' xs={12}>
                                <InputImageDropzone
                                    control={control}
                                    maxFileSize={5}
                                    minimumImageSize={{ width: 300, height: 300 }}
                                    name='sideMenuBackground'
                                    previewStyle={{ width: 180, height: 95 }}
                                    required
                                    style={{ height: 124 }}
                                />
                                {sideMenuBackground && (
                                    <Button
                                        onClick={() => deleteUpload('sideMenuBackground')}
                                        size='large'
                                        startIcon={<DeleteIcon />}
                                        variant='onlyIcon'
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid
                            display='flex'
                            justifyContent='flex-end'
                            xs={12}
                        >
                            <RequestButton
                                injectFormData={handleSubmit}
                                onSuccess={submitDataSuccess}
                                sendData={submitData}
                                size='large'
                                type='submit'
                                variant='contained'
                            >
                                <FormattedMessage defaultMessage='Update' id='common.update' />
                            </RequestButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid xs={4}>
                    <Phone
                        phoneSx={{ maxWidth: '68%' }}
                    />
                </Grid>
            </Grid>
        </FormContainer>
    );
}

Menu.propTypes = {
    getData: PropTypes.func.isRequired,
    submitData: PropTypes.func.isRequired,
};
