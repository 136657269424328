import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Navigate,
    useLocation,
    useNavigate
} from 'react-router-dom';

import authProviderUtil from '../utils/auth';

const AuthContext = React.createContext(null);

function AuthProvider({ children }) {
    const [storedState, setStored] = React.useState(null);
    let stored = storedState;
    // Retrieve stored data if available
    if (!stored || !stored.token) {
        const { token: storedToken, database: storedDatabase } = authProviderUtil.retrieveSignedIn();
        if (storedToken) {
            stored = {
                token: storedToken,
                database: storedDatabase,
            };
            setStored(stored);
        }
    }

    const signin = (database, email, password, callback) => (
        authProviderUtil.signin(database, email, password, (newToken, error) => {
            if (newToken) {
                setStored({
                    token: newToken,
                    database,
                });
            }
            callback(error);
        })
    );

    const signout = (callback) => {
        authProviderUtil.signout(() => {
            setStored(null);
            if (callback) callback();
        });
    };

    const value = React.useMemo(
        () => ({
            stored,
            signin,
            signout,
            signedIn: stored && stored.token
        }),
        [stored && stored.token]
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

function useAuth() {
    return React.useContext(AuthContext);
}

function RequireAuth({ children }) {
    const auth = useAuth();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        // Check if user is signedIn
        if (!auth.signedIn) {
            // Redirect them to the /login page, but save the current location they were
            // trying to go to when they were redirected.
            navigate('/login', { state: { from: location } });
        }
    }, [auth]);

    if (!auth.signedIn) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected.
        return <Navigate replace state={{ from: location }} to='/login' />;
    }

    return children;
}
RequireAuth.propTypes = {
    children: PropTypes.node.isRequired,
};

export {
    AuthProvider,
    useAuth,
    RequireAuth,
    AuthContext
};
