import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

import {
    useWatch,
    FormContainer,
    Controller,
    DatePickerElement, TextFieldElement,
} from 'react-hook-form-mui';

import {
    Unstable_Grid2 as Grid,
    Button,
    Typography,
    FormControl,
    MenuItem,
    Select,
    InputLabel,
    CircularProgress,
    Alert,
} from '@mui/material';

import {
    DeleteOutlined, Done as DoneIcon,
    Error as ErrorIcon,
    HorizontalRule
} from '@mui/icons-material';

import { useCustomForm } from '../base/Form';

import ImageDropzone from '../../components/ImageDropzone';
import PublicationArticleSelect from '../../components/PublicationArticleSelect';
import { useErrorBag } from '../base/ErrorBag';

const ActionButtons = (props) => {
    const { deleteUpload, name } = props;

    return (
        <Grid display='flex' justifyContent='center'>
            <Button
                onClick={() => deleteUpload(name)}
                size='large'
                startIcon={<DeleteOutlined />}
                variant='onlyIcon'
            />
        </Grid>
    );
};

ActionButtons.propTypes = {
    deleteUpload: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired
};

const AdvertsFormView = (props) => {
    const {
        isUpdate,
        retrieveData,
        submitData,
        publicationArticles
    } = props;

    const { formatMessage } = useIntl();

    const LinkTypes = {
        None: 0,
        Url: 1,
        Publications: 2,
    };

    const [linkType, setLinkType] = useState(LinkTypes.None);
    const [submitState, setSubmitState] = useState(null);
    const [loadedValues, setLoadedValues] = useState(false);
    const errorBag = useErrorBag();

    const formOptions = {
        defaultValues: isUpdate ? retrieveData : {},
    };

    const {
        control,
        formContext,
        handleSubmit,
        setValue,
        setValues,
    } = useCustomForm(formOptions);

    const values = useWatch({ control });

    const handleUpload = (name, newValue) => {
        setValue(name, newValue);
    };

    const deleteUpload = (name) => {
        setValue(name, null);
    };

    const resetValuesOnTypeChange = () => {
        setValues({
            url: null,
            linkedArticle: null,
            linkedPage: null
        });
    };

    const handleLinkTypeChange = (event) => {
        setLinkType(event.target.value);

        resetValuesOnTypeChange();
    };

    const retrieveDefaultData = async () => {
        const { defaultValues } = formOptions;

        if (!defaultValues || typeof defaultValues !== 'function') {
            setLoadedValues(true);
            return;
        }

        const defaults = await defaultValues();

        if (defaults.url) {
            setLinkType(LinkTypes.Url);
            setValues({ url: defaults.url });
        } else if (defaults.linkedArticle) {
            setLinkType(LinkTypes.Publications);
            setValues({
                linkedArticle: defaults.linkedArticle,
                linkedPage: defaults.linkedPage
            });
        }

        setLoadedValues(true);
    };

    const submitDataWrapper = (data) => {
        setSubmitState('loading');

        submitData(data).then((response) => {
            setSubmitState(response?.success ? 'completed' : 'error');
        });
    };

    const icons = {
        completed: <DoneIcon />,
        loading: <CircularProgress color='inherit' size={22} />,
        error: <ErrorIcon />
    };

    const buttonEndIcon = icons[submitState] || null;

    useEffect(() => {
        retrieveDefaultData();
    }, [loadedValues]);

    useEffect(() => {
        resetValuesOnTypeChange();
    }, [linkType]);

    const [startDate, endDate] = useWatch({
        control,
        name: ['startDate', 'endDate']
    });

    useEffect(() => {
        errorBag.set({
            endDateBeforeStartDate: new Date(endDate) < new Date(startDate)
        });
    }, [startDate, endDate]);

    return (
        <FormContainer formContext={formContext}>
            <Grid
                className='alternate-rows'
                container
                direction='column'
                spacing={2}
                sx={{ px: 2, my: 2 }}
                xs={12}
            >
                <Grid container sx={{ p: 3 }}>
                    <Grid xs={3}>
                        <Typography variant='sectionHeader'>
                            <FormattedMessage
                                defaultMessage='Upload image'
                                id='common.uploadImage'
                            />
                        </Typography>
                    </Grid>
                    <Grid container spacing={0} xs={9}>
                        <Grid sx={{ pb: 2 }} xs={6}>
                            <ImageDropzone
                                handleUpload={handleUpload}
                                maxFileSize={5}
                                name='advertImage'
                                value={values.advertImage}
                            />
                        </Grid>
                        <Grid alignItems='center' display='flex' sx={{ paddingBottom: 3 }}>
                            {values.advertImage && (
                                <ActionButtons
                                    deleteUpload={deleteUpload}
                                    name='advertImage'
                                />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container sx={{ p: 3 }}>
                    <Grid xs={3}>
                        <Typography variant='sectionHeader'>
                            <FormattedMessage
                                defaultMessage='Dates'
                                id='adverts.section.dates'
                            />
                        </Typography>
                    </Grid>
                    <Grid container direction='column' spacing={4} xs={9}>
                        {loadedValues && !!errorBag.length && (
                            <Grid>
                                {errorBag.has(['endDateBeforeStartDate']) && (
                                    <Alert severity='error'>
                                        <FormattedMessage
                                            defaultMessage='End date cannot be before start date'
                                            id='publication.error.endDateBeforeStartDate'
                                        />
                                    </Alert>
                                )}
                            </Grid>
                        )}
                        <Grid container xs={12}>
                            <Grid xs={5.5}>
                                <DatePickerElement
                                    control={control}
                                    label={formatMessage({
                                        defaultMessage: 'Start date',
                                        id: 'adverts.form.startDate'
                                    })}
                                    name='startDate'
                                />
                            </Grid>
                            <Grid alignItems='center' display='flex' justifyContent='center' xs={1}>
                                <HorizontalRule
                                    sx={{
                                        color: 'border.input',
                                    }}
                                />
                            </Grid>
                            <Grid xs={5.5}>
                                <DatePickerElement
                                    control={control}
                                    label={formatMessage({
                                        defaultMessage: 'End date',
                                        id: 'adverts.form.endDate'
                                    })}
                                    name='endDate'
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container sx={{ p: 3 }}>
                    <Grid xs={3}>
                        <Typography variant='sectionHeader'>
                            <FormattedMessage
                                defaultMessage='Add name'
                                id='adverts.section.label'
                            />
                        </Typography>
                    </Grid>
                    <Grid xs={9}>
                        <TextFieldElement
                            control={control}
                            label={(
                                <FormattedMessage
                                    defaultMessage='Advert name'
                                    id='adverts.form.name'
                                />
                            )}
                            name='label'
                            type='text'
                        />
                    </Grid>
                </Grid>
                <Grid container sx={{ p: 3, my: 1 }}>
                    <Grid xs={3}>
                        <Typography variant='sectionHeader'>
                            <FormattedMessage
                                defaultMessage='Add a link'
                                id='adverts.section.addLink'
                            />
                        </Typography>
                    </Grid>
                    <Grid container spacing={4} xs={9}>
                        {loadedValues && (
                            <Grid xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel id='link-type-label'>Link type</InputLabel>
                                    <Controller
                                        control={control}
                                        defaultValue={linkType}
                                        name='linkType'
                                        render={({ field }) => (
                                            <Select
                                                {...field}
                                                label='Link Type'
                                                labelId='link-type-label'
                                                onChange={(event) => {
                                                    handleLinkTypeChange(event);
                                                    field.onChange(event);
                                                }}
                                                value={linkType}
                                            >
                                                <MenuItem value={LinkTypes.None}>None</MenuItem>
                                                <MenuItem value={LinkTypes.Url}>URL</MenuItem>
                                                <MenuItem value={LinkTypes.Publications}>Publications</MenuItem>
                                            </Select>
                                        )}
                                    />
                                </FormControl>

                                {linkType === LinkTypes.Publications && (
                                    <Grid item sx={{ mt: 3, mb: 1 }} xs={12}>
                                        <PublicationArticleSelect
                                            control={control}
                                            linkedArticle={values.linkedArticle}
                                            linkedPage={values.linkedPage}
                                            publicationArticles={publicationArticles}
                                        />
                                    </Grid>
                                )}

                                {linkType === LinkTypes.Url && (
                                    <Grid item sx={{ mt: 1, mb: 1 }} xs={12}>
                                        <TextFieldElement
                                            control={control}
                                            fullWidth
                                            label='URL'
                                            margin='normal'
                                            name='url'
                                            variant='outlined'
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        )}
                    </Grid>
                </Grid>
                <Grid container spacing={4} sx={{ pt: 3 }}>
                    <Grid
                        display='flex'
                        justifyContent='flex-end'
                        xs={12}
                    >
                        <Button
                            disabled={submitState === 'loading' || errorBag.length}
                            endIcon={buttonEndIcon}
                            onClick={handleSubmit(submitDataWrapper)}
                            size='large'
                            type='submit'
                            variant='contained'
                        >
                            {isUpdate ? (
                                <FormattedMessage defaultMessage='Update' id='common.update' />
                            ) : (
                                <FormattedMessage defaultMessage='Create' id='common.create' />
                            )}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </FormContainer>
    );
};

AdvertsFormView.propTypes = {
    isUpdate: PropTypes.bool,
    publicationArticles: PropTypes.object.isRequired,
    retrieveData: PropTypes.func,
    submitData: PropTypes.func.isRequired,
};

AdvertsFormView.defaultProps = {
    isUpdate: false,
    retrieveData: () => {
    },
};

export default AdvertsFormView;
