import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm, FormContainer } from 'react-hook-form-mui';
import {
    Unstable_Grid2 as Grid,
    Typography, Button
} from '@mui/material';

import dayjs from 'dayjs';
import {
    FileDownloadOutlined as DownloadIcon
} from '@mui/icons-material';
import { useApi } from './base/Request';
import { actions } from '../utils/api';
import file from '../utils/file';

const Analytics = () => {
    const intlContext = useIntl();
    const { formatMessage } = intlContext;
    const { request } = useApi();

    // The page uses a different title than the item in the menu, so we need to declare it here to get it extracted
    formatMessage({ defaultMessage: 'Analytics', id: 'pages.analytics' });

    const generateDateForAnalyticsDownload = () => {
        const dateStart = dayjs().subtract(1, 'year').unix();
        const dateFinal = dayjs().unix();
        return { dateStart, dateFinal };
    };

    const downloadFile = async (blob, fileName) => {
        if (blob) {
            const fullFileName = `${fileName}_${dayjs().format('DD-MM-YYYY')}`;
            await file.download(blob, fullFileName);
        }
    };

    const downloadRawData = async () => {
        const { dateStart, dateFinal } = generateDateForAnalyticsDownload();

        const response = await request(actions.getStatistics).send({ typeId: 1, dateStart, dateFinal }, false);
        const blob = await response.blob();

        await downloadFile(blob, 'raw_data');
    };

    const downloadPublicationData = async () => {
        const { dateStart, dateFinal } = generateDateForAnalyticsDownload();

        const response = await request(actions.getStatistics).send({ typeId: 2, dateStart, dateFinal }, false);
        const blob = await response.blob();

        await downloadFile(blob, 'publication_data');
    };

    const downloadVisitorData = async () => {
        const { dateStart, dateFinal } = generateDateForAnalyticsDownload();

        const response = await request(actions.getStatistics).send({ typeId: 3, dateStart, dateFinal }, false);
        const blob = await response.blob();

        await downloadFile(blob, 'visitor_data');
    };

    return (
        <div id='analytics'>
            <AnalyticsView
                downloadPublicationData={downloadPublicationData}
                downloadRawData={downloadRawData}
                downloadVisitorData={downloadVisitorData}
            />
        </div>
    );
};

const AnalyticsView = ({ downloadRawData, downloadPublicationData, downloadVisitorData }) => {
    const { formContext } = useForm();

    return (
        <FormContainer formContext={formContext}>
            <Grid container direction='column' spacing={3} sx={{ px: 2 }} xs={12}>
                <Grid container>
                    <Grid xs={4}>
                        <Typography variant='sectionHeader'>
                            <FormattedMessage
                                defaultMessage='Download raw data'
                                id='analytics.downloadRawData'
                            />
                        </Typography>
                    </Grid>
                    <Grid xs={8}>
                        <Button
                            onClick={() => downloadRawData()}
                            size='large'
                            startIcon={<DownloadIcon />}
                            variant='outlined'
                        >
                            <FormattedMessage
                                defaultMessage='Download'
                                id='common.download'
                            />
                        </Button>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid xs={4}>
                        <Typography variant='sectionHeader'>
                            <FormattedMessage
                                defaultMessage='Download visitor data'
                                id='analytics.downloadVisitorData'
                            />
                        </Typography>
                    </Grid>
                    <Grid xs={8}>
                        <Button
                            onClick={() => downloadVisitorData()}
                            size='large'
                            startIcon={<DownloadIcon />}
                            variant='outlined'
                        >
                            <FormattedMessage
                                defaultMessage='Download'
                                id='common.download'
                            />
                        </Button>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid xs={4}>
                        <Typography variant='sectionHeader'>
                            <FormattedMessage
                                defaultMessage='Download publication data'
                                id='analytics.downloadPublicationData'
                            />
                        </Typography>
                    </Grid>
                    <Grid xs={8}>
                        <Button
                            onClick={() => downloadPublicationData()}
                            size='large'
                            startIcon={<DownloadIcon />}
                            variant='outlined'
                        >
                            <FormattedMessage
                                defaultMessage='Download'
                                id='common.download'
                            />
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </FormContainer>
    );
};

AnalyticsView.propTypes = {
    downloadPublicationData: PropTypes.func.isRequired,
    downloadRawData: PropTypes.func.isRequired,
    downloadVisitorData: PropTypes.func.isRequired,
};

export default Analytics;
