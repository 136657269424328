import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { locales } from './i18n-config';

const useDocL10n = (activePage) => {
    const { locale, formatMessage } = useIntl();

    useEffect(() => {
        document.dir = locales[locale].dir;
        document.title = `${formatMessage({
            id: 'app.title',
            defaultMessage: 'TouchTree',
        })} - ${activePage}`;
    }, [locale, formatMessage, activePage]);
};

export default useDocL10n;
