import React, {
    createContext,
    useMemo,
    useState,
    useRef,
} from 'react';

import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Button } from '@mui/material';
import DefaultDialog from '../modules/DefaultDialog';

/**
 * @typedef DialogContextOptions
 * @property {(callback: function) => void} onClose
 * @property {(callback: function) => void} onSubmit
 * @property {(open: boolean, options: object) => void} show
 */

/**
 * @type {React.Context<DialogContextOptions>}
 */
export const DialogContext = createContext({
    onClose: () => {
    },
    onSubmit: () => {
    },
    show: () => {
    },
});

export const DialogProvider = ({ children }) => {
    const [state, setState] = useState({
        open: false,
        options: {
            size: 'sm',
        },
    });

    const closeRef = useRef(() => {
    });
    const submitRef = useRef(() => {
    });

    const { formatMessage } = useIntl();

    const show = (value, options = {}) => {
        setState((prevState) => ({
            ...prevState,
            open: value,
            options: { ...prevState.options, ...options }
        }));
    };

    const onClose = (close) => {
        closeRef.current = close;
    };

    const onSubmit = (submit) => {
        submitRef.current = submit;
    };

    const handleClose = () => {
        show(false);
        closeRef.current();
    };

    const handleSubmit = () => {
        show(false);
        submitRef.current();
    };

    const value = useMemo(
        () => ({
            onClose,
            onSubmit,
            show
        }),
        [onClose, onSubmit, show]
    );

    return (
        <DialogContext.Provider value={value}>
            {children}
            <DefaultDialog
                cancelDisabled={state.options.disableCancelButton}
                closeHandler={handleClose}
                saveButton={(
                    <Button
                        onClick={handleSubmit}
                        size='large'
                        type='submit'
                        variant='contained'
                    >
                        {state.options.confirmButtonText || formatMessage({
                            defaultMessage: 'OK',
                            id: 'common.ok'
                        })}
                    </Button>
                )}
                show={state.open}
                size={state.options.size}
                title={state.options.title}
            >
                {state.options.body}
            </DefaultDialog>
        </DialogContext.Provider>
    );
};

DialogProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
