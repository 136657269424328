import React from 'react';

import {
    Box, Tabs, Tab
} from '@mui/material';

import PropTypes from 'prop-types';

function TabPabel(props) {
    const {
        children, value, page, ...other
    } = props;

    return (
        <div
            aria-labelledby={`tab-${page}`}
            hidden={value !== page}
            id={`tabpanel-${page}`}
            role='tabpanel'
            {...other}
        >
            {value === page && (
                <Box sx={{ pt: 5 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPabel.propTypes = {
    children: PropTypes.node.isRequired,
    page: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default function TabPage({ pages }) {
    const [activePage, setActivePage] = React.useState(0);

    const switchPage = (event, newPage) => {
        setActivePage(newPage);
    };

    const tabs = pages.map((page, index) => (
        <Tab key={page.name} label={page.name} value={index} />
    ));

    const contentPages = pages.map((page, index) => (
        <TabPabel key={page.name} page={index} value={activePage}>
            {page.component}
        </TabPabel>
    ));

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    aria-label='Settings pages'
                    onChange={switchPage}
                    value={activePage}
                >
                    {tabs}
                </Tabs>
            </Box>
            {contentPages}
        </Box>
    );
}

TabPage.propTypes = {
    pages: PropTypes.arrayOf(PropTypes.shape({
        component: PropTypes.node.isRequired,
        name: PropTypes.string.isRequired
    })).isRequired,
};
