import React from 'react';
import { Box, CircularProgress } from '@mui/material';

export default function CenteredCircularProgress() {
    return (
        <Box
            sx={{
                display: 'grid',
                placeItems: 'center',
                height: '60vh',
            }}
        >
            <CircularProgress size={50} />
        </Box>
    );
}
