import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { ListItemText, Typography, Unstable_Grid2 as Grid, } from '@mui/material';

import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { useApi, api, noCacheUrl } from '../base/Request';

import campaignTypes from './campaignTypes';
import DefaultFormattedDate from '../../components/DefaultFormattedDate';

const GeoLocations = ({ locations }) => {
    const { formatMessage } = useIntl();

    const geoCoordinatesColumns = [
        {
            field: 'latitude',
            headerName: formatMessage({ defaultMessage: 'Latitude', id: 'campaigns.view.geo.latitude' }),
            flex: 1,
            sortable: false
        },
        {
            field: 'longitude',
            headerName: formatMessage({ defaultMessage: 'Longitude', id: 'campaigns.view.geo.longitude' }),
            flex: 1,
            sortable: false
        },
        {
            field: 'radius',
            headerName: formatMessage({ defaultMessage: 'Radius', id: 'campaigns.view.geo.radius' }),
            flex: 0.7,
            sortable: false,
            valueGetter: ({ value }) => {
                const unit = formatMessage({
                    defaultMessage: value.unit,
                    id: `campaigns.form.geo.radius.${value.unit}`
                });

                return `${value.amount} ${unit}`;
            }
        }
    ];

    if (!locations) {
        return null;
    }

    return (
        <>
            <Grid xs={3}>
                <Typography variant='sectionHeader'>
                    <FormattedMessage
                        defaultMessage='Geo coordinates'
                        id='campaigns.view.geo.geoCoordinates'
                    />
                </Typography>
            </Grid>
            <Grid container xs={9}>
                <DataGrid
                    autoHeight
                    columns={geoCoordinatesColumns}
                    disableColumnMenu
                    hideFooter
                    loading={!locations}
                    rowHeight={52}
                    rows={locations}
                    sx={{
                        border: 'none',
                        '& .MuiDataGrid-columnHeaders': {
                            borderRadius: 1
                        },
                        '& .MuiDataGrid-columnHeader': {
                            backgroundColor: 'common.white'
                        },
                        '& .MuiDataGrid-row': {
                            borderRadius: 1,
                            '&:nth-of-type(even)': {
                                backgroundColor: 'background.lightGrayForm'
                            }
                        },
                        '& .MuiDataGrid-cell': {
                            borderBottom: 'none'
                        },
                        '& .MuiDataGrid-columnHeader:focus': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-cell:focus': {
                            outline: 'none',
                        },
                    }}
                />
            </Grid>
        </>
    );
};

GeoLocations.propTypes = {
    locations: PropTypes.array.isRequired,
};

const IpAddresses = ({ addresses }) => {
    const { formatMessage } = useIntl();

    const ipColumns = [
        {
            field: 'label',
            headerName: formatMessage({ defaultMessage: 'Reference', id: 'campaigns.form.ip.reference' }),
            flex: 1.5,
        },
        {
            field: 'address',
            headerName: formatMessage({ defaultMessage: 'Address', id: 'campaigns.form.ip.ipAddress' }),
            flex: 1,
        },
        {
            field: 'range',
            headerName: formatMessage({ defaultMessage: 'Range', id: 'campaigns.form.ip.range' }),
            flex: 1,
        },
    ];

    if (!addresses) {
        return null;
    }

    return (
        <Grid container sx={{ p: 1 }} xs={12}>
            <Grid xs={3}>
                <Typography variant='sectionHeader'>
                    <FormattedMessage
                        defaultMessage='Geo coordinates'
                        id='campaigns.view.geo.geoCoordinates'
                    />
                </Typography>
            </Grid>
            <Grid container xs={9}>
                <DataGrid
                    autoHeight
                    columns={ipColumns}
                    disableColumnMenu
                    hideFooter
                    loading={!addresses}
                    rowHeight={52}
                    rows={addresses}
                    sx={{
                        border: 'none',
                        '& .MuiDataGrid-columnHeaders': {
                            borderRadius: 1
                        },
                        '& .MuiDataGrid-columnHeader': {
                            backgroundColor: 'common.white'
                        },
                        '& .MuiDataGrid-row': {
                            borderRadius: 1,
                            '&:nth-of-type(even)': {
                                backgroundColor: 'background.lightGrayForm'
                            }
                        },
                        '& .MuiDataGrid-cell': {
                            borderBottom: 'none'
                        },
                        '& .MuiDataGrid-columnHeader:focus': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-cell:focus': {
                            outline: 'none',
                        },
                    }}
                />
            </Grid>
        </Grid>
    );
};

IpAddresses.propTypes = {
    addresses: PropTypes.array.isRequired,
};

export default function CampaignView() {
    const { id } = useParams();
    const [data, setData] = useState(null);
    const [type, setType] = useState(null);
    const [accessDuration, setAccessDuration] = useState(null);
    const { formatMessage } = useIntl();

    const { request } = useApi();

    const getAdverts = (adverts) => {
        if (!adverts) {
            return [];
        }

        return adverts.map((advert) => ({
            id: advert.id,
            image: noCacheUrl(advert.imageUrl)
        }));
    };

    const getIpAddressFromResponse = (response) => {
        const addresses = response?.campaignIpAddresses;

        if (response.success && Array.isArray(addresses)) {
            return addresses;
        }

        console.error('error retrieving ip addresses data');

        return [];
    };

    const getIpAddresses = async (campaignId) => {
        const response = await request(api.getIpAddresses).send({ campaignId });

        return getIpAddressFromResponse(response).map((address) => ({
            id: address.id,
            address: address.ip,
            range: address.ipMask,
            label: address.label,
        }));
    };

    const retrieveDurationAccess = async (tokenId) => {
        const response = await request(api.getTokenTypes).send();
        const tokens = response?.digiTokenTypes ?? [];
        const tokensObject = {};

        tokens.forEach((token) => {
            tokensObject[token.id] = token?.dictionary;
        });

        setAccessDuration(tokensObject[tokenId]);
    };

    const retrieveData = async () => {
        const response = await request(api.getCampaign).send({ campaignId: id });
        const campaign = response?.digiCampaign;

        if (campaign) {
            const model = {
                id: campaign.id,
                name: campaign.label,
                emailRequired: campaign.hasEmailRegistration,
                verifyEmail: campaign.hasEmailAccess,
                startDate: campaign.dateStart && dayjs.unix(campaign.dateStart),
                endDate: campaign.dateFinal && dayjs.unix(campaign.dateFinal),
                isLocked: campaign.isLocked,
                url: campaign.accessDigiUrl,
                type: campaign.type,
                geoCoordinates: campaign.geoCoordinates,
                tokenTypeId: campaign.tokenTypeId,
                adverts: getAdverts(campaign?.banners),
                ipAddresses: await getIpAddresses(id),
            };

            await retrieveDurationAccess(campaign.tokenTypeId);

            setData(model);

            setType(
                campaignTypes.find(
                    (campaignType) => campaignType.id === campaign?.type
                )
            );

            return model;
        }

        return null;
    };

    useEffect(() => {
        retrieveData();
    }, []);

    return (
        <Grid className='alternate-rows' container direction='column' spacing={2} sx={{ px: 2 }} xs={12}>
            <Grid container sx={{ my: 0.5 }}>
                <Grid xs={3}>
                    <Typography noWrap variant='sectionHeader'>
                        <FormattedMessage
                            defaultMessage='Campaign type'
                            id='campaigns.view.campaignType'
                        />
                    </Typography>
                </Grid>
                <Grid xs={9}>
                    <Typography noWrap variant='medium400'>
                        {type?.label}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container sx={{ my: 0.5 }}>
                <Grid xs={3}>
                    <Typography variant='sectionHeader'>
                        <FormattedMessage
                            defaultMessage='Dates'
                            id='campaigns.view.dates'
                        />
                    </Typography>
                </Grid>
                <Grid container xs={9}>
                    <Grid xs={3}>
                        <ListItemText
                            primary={(
                                <DefaultFormattedDate value={data?.startDate} />
                            )}
                            secondary={formatMessage({
                                defaultMessage: 'Start date',
                                id: 'common.item.startDate'
                            })}
                            sx={{ display: 'flex', flexDirection: 'column-reverse' }}
                        />
                    </Grid>
                    <Grid xs={3}>
                        <ListItemText
                            primary={(
                                <DefaultFormattedDate value={data?.endDate} />
                            )}
                            secondary={formatMessage({
                                defaultMessage: 'End date',
                                id: 'common.item.endDate'
                            })}
                            sx={{ display: 'flex', flexDirection: 'column-reverse' }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container sx={{ my: 0.5 }}>
                {type?.key === 'geolocation' && <GeoLocations locations={data?.geoCoordinates} />}
                {type?.key === 'ip' && <IpAddresses addresses={data?.ipAddresses} />}
            </Grid>
            <Grid container sx={{ my: 0.5 }}>
                <Grid xs={3}>
                    <Typography noWrap variant='sectionHeader'>
                        <FormattedMessage
                            defaultMessage='Duration of access'
                            id='campaigns.view.duration'
                        />
                    </Typography>
                </Grid>
                <Grid xs={9}>
                    <Typography noWrap variant='medium400'>
                        {accessDuration || formatMessage({
                            defaultMessage: 'No duration set',
                            id: 'campaigns.view.noDurationSet'
                        })}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container sx={{ my: 0.5 }}>
                <Grid xs={3}>
                    <Typography noWrap variant='sectionHeader'>
                        <FormattedMessage
                            defaultMessage='Linked adverts'
                            id='campaigns.view.linkedAdverts'
                        />
                    </Typography>
                </Grid>
                <Grid container direction='column' xs={9}>
                    {data?.adverts?.map((advert) => (
                        <Grid key={advert.id}>
                            <img
                                alt={advert.image}
                                src={advert.image}
                                style={{
                                    borderRadius: 8,
                                    width: 380,
                                    height: 150,
                                    objectFit: 'cover'
                                }}
                            />
                        </Grid>
                    ))}
                    {data?.adverts?.length === 0 && (
                        <Grid>
                            <Typography noWrap variant='medium400'>
                                <FormattedMessage
                                    defaultMessage='No adverts linked'
                                    id='campaigns.view.noAdverts'
                                />
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}
