import { FormattedMessage } from 'react-intl';
import React from 'react';

export default [
    {
        id: 3,
        key: 'time',
        label: (
            <FormattedMessage
                defaultMessage='Time based access'
                id='campaigns.form.campaignTypes.time'
            />
        ),
        description: (
            <FormattedMessage
                defaultMessage='Campaign that targets a specific time period'
                id='campaigns.form.campaignTypes.time.description'
            />
        )
    },
    {
        id: 1,
        key: 'geolocation',
        label: (
            <FormattedMessage
                defaultMessage='Geo-location campaign'
                id='campaigns.form.campaignTypes.geolocation'
            />
        ),
        description: (
            <FormattedMessage
                defaultMessage='Campaign that targets a specific location'
                id='campaigns.form.campaignTypes.geolocation.description'
            />
        )
    },
    {
        id: 2,
        key: 'ip',
        label: (
            <FormattedMessage
                defaultMessage='IP address campaign'
                id='campaigns.form.campaignTypes.ip'
            />
        ),
        description: (
            <FormattedMessage
                defaultMessage='Campaign that targets a specific IP address'
                id='campaigns.form.campaignTypes.ip.description'
            />
        )
    },
];
