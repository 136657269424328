import React from 'react';
import PropTypes from 'prop-types';

import { Unstable_Grid2 as Grid } from '@mui/material';
import { useIntl } from 'react-intl';

import ApiUtils from '../base/Api';
import { useAuth } from '../../contexts/Auth';

import TabsPage from '../../modules/TabsPage';
import ColorForm from './layout/Color';
import LogoForm from './layout/Logo';
import LayoutForm from './layout/Layout';
import Phone from '../../modules/Phone';
import { groupListByParent } from '../../utils/utils';
import { digiMagsDefaultColors } from '../../themes/default';

const LayoutView = (props) => {
    const { retrieveData, publicationArticles, submitData } = props;
    const { formatMessage } = useIntl();
    return (
        <Grid container spacing={3}>
            <Grid alignSelf='flex-start' container xs={8}>
                <TabsPage
                    pages={[
                        {
                            name: formatMessage({ defaultMessage: 'Color', id: 'newsstand.layout.subpages.color' }),
                            component: (
                                <ColorForm
                                    getData={retrieveData}
                                    submitData={(data) => submitData(data, 'color')}
                                />
                            )
                        },
                        {
                            name: formatMessage({ defaultMessage: 'Logo', id: 'newsstand.layout.subpages.logo' }),
                            component: (
                                <LogoForm
                                    getData={retrieveData}
                                    submitData={(data) => submitData(data, 'logo')}
                                />
                            )
                        },
                        {
                            name: formatMessage({ defaultMessage: 'Layout', id: 'newsstand.layout.subpages.layout' }),
                            component: (
                                <LayoutForm
                                    getData={retrieveData}
                                    publicationArticles={publicationArticles}
                                    submitData={(data) => submitData(data, 'layout')}
                                />
                            )
                        }
                    ]}
                />
            </Grid>
            <Grid xs={4}>
                <Phone />
            </Grid>
        </Grid>
    );
};
LayoutView.propTypes = {
    publicationArticles: PropTypes.object.isRequired,
    retrieveData: PropTypes.func.isRequired,
    submitData: PropTypes.func.isRequired,
};

const Layout = () => {
    const [publicationArticles, setPublicationPages] = React.useState([]);
    const authContext = useAuth();
    const intlContext = useIntl();
    const { Api } = ApiUtils.useApi(authContext, intlContext);
    const originalData = React.useRef({});

    // HOTFIX type is not received from the API
    const retrieveLinkageType = (linkage) => {
        if (linkage?.url) return 'url';
        if (linkage?.articleId) return 'article';
        if (linkage?.documentId) return 'document';
        return '';
    };

    const retrieveData = async () => {
        const response = await Api.getNewsstandCustom();

        if (response.success && response.digiPlatformCustomisation) {
            const data = response.digiPlatformCustomisation.customData || {};
            // Set original data to use in submitData
            originalData.current = data;
            // Check for rectangles
            let rectangles = null;
            if (data.rectangles && data.rectangles.length) {
                const positionsR = ['left', 'right'];
                rectangles = {};
                data.rectangles.forEach((rectangle, index) => {
                    const newR = {
                        image: rectangle.imgSrc,
                        link: {
                            type: rectangle.linkage?.type || retrieveLinkageType(rectangle.linkage),
                            url: rectangle.linkage?.url,
                            articleId: rectangle.linkage?.articleId,
                            documentId: rectangle.linkage?.documentId,
                            pageId: rectangle.linkage?.page,
                        }
                    };
                    rectangles[positionsR[index]] = newR;
                });
            }
            // Check for blocks
            let blocks = null;
            if (data.pods && data.pods.length) {
                const positionsB = ['topLeft', 'topRight', 'bottomLeft', 'bottomRight'];
                blocks = {};
                data.pods.forEach((block, index) => {
                    const newB = {
                        image: block.imgSrc,
                        link: {
                            type: block.linkage?.type || retrieveLinkageType(block.linkage),
                            url: block.linkage?.url,
                            articleId: block.linkage?.articleId,
                            documentId: block.linkage?.documentId,
                            pageId: block.linkage?.page,
                        }
                    };
                    blocks[positionsB[index]] = newB;
                });
            }
            return {
                // Color tab
                colors: {
                    general: {
                        background: data.colorMainBackground || digiMagsDefaultColors.general.background,
                        font: data.colorMainFont || digiMagsDefaultColors.general.font
                    },
                    header: {
                        background: data.colorHeaderBackground || digiMagsDefaultColors.header.background,
                        font: data.colorHeaderFont || digiMagsDefaultColors.header.font
                    },
                    footer: {
                        background: data.colorFooterBackground || digiMagsDefaultColors.footer.background,
                        font: data.colorFooterFont || digiMagsDefaultColors.footer.font
                    },
                    container: {
                        background: data.colorContainerBackground || digiMagsDefaultColors.container.background,
                        font: data.colorContainerFont || digiMagsDefaultColors.container.font
                    },
                    highlight: data.colorHighlightBackground || digiMagsDefaultColors.highlight,
                    line: data.colorLineBackground || digiMagsDefaultColors.line,
                },
                // Logo tab
                newsstandLogo: data.logo?.imgSrc,
                logoSize: data.logo?.scale || 100,
                logoLinkUrl: data.logo?.linkage?.url,
                newsstandFavicon: data.faviconSrc,
                title: data.webTitle,
                // Layout tab
                documents: response.digiPlatformCustomisation.relationDetails.objectPrivateDocuments,
                roundedEdges: data.hasRoundedEdges,
                rectangles,
                blocks,
                showBlocks: blocks && Object.keys(blocks).length,
                showRectangles: rectangles && Object.keys(rectangles).length,
            };
        }
        return null;
    };

    const submitData = async (data, tab) => {
        let toSend = {};
        if (tab === 'color') {
            const colorsData = data.colors;
            toSend = {
                objCustomisation: {
                    ...originalData.current,
                    colorMainBackground: colorsData.general.background,
                    colorMainFont: colorsData.general.font,
                    colorHeaderBackground: colorsData.header.background,
                    colorHeaderFont: colorsData.header.font,
                    colorFooterBackground: colorsData.footer.background,
                    colorFooterFont: colorsData.footer.font,
                    colorContainerBackground: colorsData.container.background,
                    colorContainerFont: colorsData.container.font,
                    colorHighlightBackground: colorsData.highlight,
                    colorLineBackground: colorsData.line,
                },
            };
        } else if (tab === 'logo') {
            // Logo
            let logo = null;
            if (data.newsstandLogo) {
                logo = {
                    scale: data.logoSize,
                    imgSrc: data.newsstandLogo,
                    linkage: data.logoLinkUrl ? {
                        url: data.logoLinkUrl,
                        articleId: null,
                        documentId: null,
                        page: null,
                    } : null
                };
            }
            toSend = {
                // Logo tab
                objCustomisation: {
                    ...originalData.current,
                    logo,
                    faviconSrc: data.newsstandFavicon,
                    webTitle: data.title,
                }
            };
        } else if (tab === 'layout') {
            let rectangles = null;
            if (data.showRectangles
                && data.rectangles
                && Object.keys(data.rectangles).length
            ) {
                const positionsR = ['left', 'right'];
                rectangles = [];
                positionsR.forEach((rectangleKey) => {
                    if (!data.rectangles[rectangleKey]) return;
                    const rectangle = data.rectangles[rectangleKey];
                    // API requires all the keys to exist in linkage
                    rectangles.push({
                        imgSrc: rectangle.image,
                        linkage: {
                            type: rectangle.link?.type || null,
                            url: rectangle.link?.url || null,
                            articleId: rectangle.link?.articleId || null,
                            documentId: rectangle.link?.documentId || null,
                            page: rectangle.link?.pageId || null,
                        }
                    });
                });
            }
            // Check for blocks
            let blocks = null;
            if (data.showBlocks
                && data.blocks
                && Object.keys(data.blocks).length
            ) {
                const positionsB = ['topLeft', 'topRight', 'bottomLeft', 'bottomRight'];
                blocks = [];
                positionsB.forEach((blockKey) => {
                    if (!data.blocks[blockKey]) return;
                    const block = data.blocks[blockKey];
                    // API requires all the keys to exist in linkage
                    blocks.push({
                        imgSrc: block.image,
                        linkage: {
                            type: block.link?.type || null,
                            url: block.link?.url || null,
                            articleId: block.link?.articleId || null,
                            documentId: block.link?.documentId || null,
                            page: block.link?.pageId || null,
                        }
                    });
                });
            }
            // Layout tab
            toSend = {
                objCustomisation: {
                    ...originalData.current,
                    hasRoundedEdges: data.roundedEdges,
                    rectangles,
                    pods: blocks,
                }
            };
        }

        return Api.updateNewsstandCustom(toSend);
    };

    const retrievePublicationPages = async () => {
        const response = await Api.getPublicationArticles();
        setPublicationPages(
            groupListByParent(response?.publicationArticles, 'rootName')
        );
    };

    React.useEffect(() => {
        retrievePublicationPages();
    }, []);

    return (
        <LayoutView
            publicationArticles={publicationArticles}
            retrieveData={retrieveData}
            submitData={submitData}
        />
    );
};

export default Layout;
