import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    CircularProgress,
} from '@mui/material';
import { Done as DoneIcon, Error as ErrorIcon } from '@mui/icons-material';

export default function RequestButton({
    children,
    injectFormData,
    onSuccess,
    onError,
    responseValidator,
    sendData,
    size,
    type,
    variant,
    disabled
}) {
    const [submitState, setSubmitState] = useState('idle');
    const isButtonDisabled = disabled || submitState === 'loading';

    const onClickWrapper = async (formData) => {
        setSubmitState('loading');
        const response = await sendData(formData);
        if (responseValidator(response)) {
            setSubmitState('completed');
            if (onSuccess) {
                onSuccess(response);
            }
        } else {
            setSubmitState('error');
            if (onError) {
                onError(response);
            }
        }
    };

    let buttonEndIcon = null;
    if (submitState === 'completed') {
        buttonEndIcon = <DoneIcon />;
    } else if (submitState === 'loading') {
        buttonEndIcon = <CircularProgress color='inherit' size={22} />;
    } else if (submitState === 'error') {
        buttonEndIcon = <ErrorIcon />;
    }

    let onClickFunc = onClickWrapper;
    if (injectFormData) {
        onClickFunc = injectFormData(onClickWrapper);
    }
    return (
        <Button
            disabled={isButtonDisabled}
            endIcon={buttonEndIcon}
            onClick={onClickFunc}
            size={size}
            type={type}
            variant={variant}
        >
            {children}
        </Button>
    );
}

RequestButton.propTypes = {
    children: PropTypes.node,
    disabled: PropTypes.bool,
    injectFormData: PropTypes.func,
    onError: PropTypes.func,
    onSuccess: PropTypes.func,
    responseValidator: PropTypes.func,
    sendData: PropTypes.func.isRequired,
    size: PropTypes.object,
    type: PropTypes.string,
    variant: PropTypes.number
};

RequestButton.defaultProps = {
    children: null,
    disabled: false,
    injectFormData: null,
    onError: null,
    onSuccess: null,
    responseValidator: (response) => !!response?.success,
    size: 'large',
    type: 'submit',
    variant: 'contained',
};
