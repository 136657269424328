// eslint-disable-next-line no-unused-vars
import React, { ElementType, ReactElement } from 'react';

// eslint-disable-next-line no-unused-vars
import { SvgIcon, SvgIconProps } from '@mui/material';
import dayjs from 'dayjs';

/**
 * @param {Object[]} list
 * @param {string} parentField
 * @param {(function(Object): bool)|null} [isParent=null]
 */
export const groupListByParent = (list, parentField, isParent = null) => {
    if (!Array.isArray(list)) {
        return {};
    }

    const grouped = {};

    list.forEach((item) => {
        if (isParent && isParent(item)) {
            return;
        }

        const parentValue = item[parentField];

        if (parentValue === null) {
            return;
        }

        grouped[parentValue] = grouped[parentValue] || [];

        grouped[parentValue].push(item);
    });

    return grouped;
};

/**
 * @param {ElementType} CustomIcon
 * @param {Object} [sx={}]
 * @param {SvgIconProps} [props={}]
 * @return {ReactElement}
 */
export const getSvgComponent = (CustomIcon, sx = {}, props = {}) => (
    <SvgIcon
        sx={{
            fontSize: 20,
            verticalAlign: 'middle',
            stroke: sx.color || 'inherit',
            ...sx,
        }}
        {...props}
    >
        <CustomIcon />
    </SvgIcon>
);

/**
 * @param {string} value
 * @param {number} [length=20]
 * @return {string}
 */
export const truncate = (value, length = 20) => {
    if (value.length <= length) {
        return value;
    }

    return `${value.slice(0, length - 4)}...${value.slice(-4)}`;
};

/**
 * @param {number} unixTimestamp
 * @param format
 */
export const formatDate = (unixTimestamp, format = 'L') => dayjs.unix(unixTimestamp).format(format);

/**
 * Validates whether a given string is a well-formed URL with a valid top-level domain (TLD).
 *
 * The function checks if the URL has a valid structure according to the URL specification
 * and ensures the hostname includes a dot followed by at least two alphabetic characters,
 * which is typical for standard domain names (e.g., `.com`, `.net`).
 *
 * @param {string} urlString - The URL string to validate.
 * @returns {boolean} Returns `true` if the URL is well-formed and contains a valid TLD; otherwise, returns `false`.
 */

export const isValidUrl = (urlString) => {
    try {
        const url = new URL(urlString);
        return /^[a-z0-9-]+(\.[a-z0-9-]+)*\.[a-z]{2,}$/i.test(url.hostname);
    } catch (_) {
        return false;
    }
};
