import React from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage } from 'react-intl';

import {
    Unstable_Grid2 as Grid,
    Button
} from '@mui/material';

import AccessDuration from './AccessDuration';

export default function TimeBased(props) {
    const {
        backHandler,
        control,
        formHandler,
        show,
        isUpdate
    } = props;

    const saveForm = () => {
        formHandler();
    };

    return (
        <Grid
            className='alternate-rows'
            container
            direction='column'
            spacing={2}
            sx={{ px: 2, my: 2, display: show ? 'flex' : 'none' }}
            xs={12}
        >
            <AccessDuration control={control} />
            <Grid container sx={{ my: 3 }}>
                <Grid
                    display='flex'
                    justifyContent='flex-end'
                    xs={12}
                >
                    <Button onClick={backHandler} size='large'>
                        <FormattedMessage defaultMessage='Back' id='button.back' />
                    </Button>
                    <Button
                        onClick={saveForm}
                        size='large'
                        type='submit'
                        variant='contained'
                    >
                        {isUpdate ? (
                            <FormattedMessage defaultMessage='Update campaign' id='campaigns.form.update' />
                        ) : (
                            <FormattedMessage defaultMessage='Create campaign' id='campaigns.form.create' />
                        )}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}

TimeBased.propTypes = {
    backHandler: PropTypes.func.isRequired,
    control: PropTypes.object.isRequired,
    formHandler: PropTypes.func.isRequired,
    isUpdate: PropTypes.bool.isRequired,
    show: PropTypes.bool.isRequired,
};
