import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
    Unstable_Grid2 as Grid,
    Button,
    ButtonGroup,
    Typography
} from '@mui/material';

import {
    EditOutlined as EditIcon,
    Delete as DeleteIcon,
    Add as AddIcon
} from '@mui/icons-material';

import {
    SliderElement,
    TextFieldElement,
    useWatch,
} from 'react-hook-form-mui';

import { DataGrid } from '@mui/x-data-grid';
import { useIntl, FormattedMessage } from 'react-intl';
import { useCustomForm } from '../../base/Form';
import { useDialog, DialogTypes } from '../../base/Dialog';
import { api, useApi } from '../../base/Request';

import AccessDuration from './AccessDuration';
import DefaultDialog from '../../../modules/DefaultDialog';

const IpAddressModal = (props) => {
    const {
        show,
        data,
        saveHandler,
        closeHandler
    } = props;

    const { formatMessage } = useIntl();

    const [updatedAddress, setUpdatedAddress] = useState(null);
    const [address, setAddress] = useState(null);
    const [rangeLimit, setRangeLimit] = useState(0);

    const {
        control,
        handleSubmit,
        reset,
        setValues,
        getValues
    } = useCustomForm();

    const [range] = useWatch({
        control,
        name: ['range']
    });

    useEffect(() => {
        if (range > getValues()?.address?.length) {
            setValues({
                range: getValues()?.address?.length,
            });
        }

        setUpdatedAddress(address?.substring?.(range, 0));
    }, [range, address]);

    useEffect(() => {
        const ip = data?.address || null;

        setAddress(ip);
        setRangeLimit(ip?.length);

        setValues({
            label: data?.label || null,
            range: data?.range || ip?.length,
            address: ip,
        });
    }, [data]);

    const saveForm = (formData) => {
        saveHandler({
            id: data?.id || null,
            ...formData
        });

        reset();
        closeHandler();
    };

    const sliderMarks = [
        {
            value: rangeLimit,
            label: rangeLimit,
        },
        {
            value: range,
            label: range,
        },
        {
            value: 0,
            label: 0,
        },
    ];

    return (
        <DefaultDialog
            closeHandler={() => {
                reset();
                closeHandler();
            }}
            saveButton={(
                <Button
                    onClick={handleSubmit(saveForm)}
                    size='large'
                    type='submit'
                    variant='contained'
                >
                    {data?.updated ? (
                        <FormattedMessage
                            defaultMessage='Update'
                            id='button.update'
                        />
                    ) : (
                        <FormattedMessage
                            defaultMessage='Add'
                            id='button.add'
                        />
                    )}
                </Button>
            )}
            show={show}
            size='modalMd'
            title={formatMessage({
                defaultMessage: 'Address',
                id: 'campaigns.form.ip.ipAddress'
            })}
        >
            <Grid container spacing={2}>
                <Grid xs={12}>
                    <TextFieldElement
                        control={control}
                        label={formatMessage({
                            defaultMessage: 'Reference',
                            id: 'campaigns.form.ip.reference'
                        })}
                        name='label'
                        required
                        type='text'
                    />
                </Grid>
                <Grid sx={{ mb: 2 }} xs={12}>
                    <TextFieldElement
                        control={control}
                        label={formatMessage({
                            defaultMessage: 'Address',
                            id: 'campaigns.form.ip.ipAddress'
                        })}
                        name='address'
                        onChange={(e) => {
                            setAddress(e.target.value);
                            setRangeLimit(e.target.value.length || null);
                        }}
                        required
                        type='text'
                    />
                </Grid>
                <Grid xs={6}>
                    <Typography variant='sectionHeader'>
                        {formatMessage({
                            defaultMessage: 'Range',
                            id: 'campaigns.form.ip.range',
                        })}
                        {` (${range || 0})`}
                    </Typography>
                </Grid>
                <Grid display='flex' justifyContent='flex-end' xs={6}>
                    <Typography variant='large400'>{updatedAddress}</Typography>
                </Grid>
                <Grid sx={{ mx: 1.5 }} xs={12}>
                    <SliderElement
                        control={control}
                        label=''
                        marks={sliderMarks}
                        max={rangeLimit}
                        min={0}
                        name='range'
                        size='small'
                        track='inverted'
                        valueLabelDisplay='off'
                    />
                </Grid>
            </Grid>
        </DefaultDialog>
    );
};

IpAddressModal.propTypes = {
    closeHandler: PropTypes.func.isRequired,
    data: PropTypes.object,
    saveHandler: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired
};

IpAddressModal.defaultProps = {
    data: null
};

const IpAddressActions = (props) => {
    const {
        address,
        id,
        editIp,
        deleteIp
    } = props;

    return (
        <ButtonGroup>
            <Button
                onClick={() => {
                    editIp(address);
                }}
                size='small'
                startIcon={<EditIcon />}
                variant='onlyIcon'
            />
            <Button
                onClick={() => {
                    deleteIp(address, id);
                }}
                size='small'
                startIcon={<DeleteIcon />}
                variant='onlyIcon'
            />
        </ButtonGroup>
    );
};

IpAddressActions.propTypes = {
    address: PropTypes.string.isRequired,
    deleteIp: PropTypes.func.isRequired,
    editIp: PropTypes.func.isRequired,
    id: PropTypes.number.isRequired,
};

const DefaultValues = {
    Address: '255.255.255.255',
};

export default function IpAddress(props) {
    const {
        backHandler,
        control,
        campaignId,
        formHandler,
        show,
        isUpdate
    } = props;

    const { formatMessage } = useIntl();
    const { createDialog } = useDialog();

    const [showIpModal, setShowIpModal] = useState(false);
    const [ipAddresses, setIpAddresses] = useState([]);
    const [currentIp, setCurrentIp] = useState(null);

    const { request } = useApi();

    const templateIpAddress = {
        id: null,
        address: DefaultValues.Address,
        label: null,
        range: null,
    };

    const getIpAddressFromResponse = (response) => {
        const addresses = response?.campaignIpAddresses;

        if (response.success && Array.isArray(addresses)) {
            return addresses;
        }

        console.error('error retrieving ip addresses data');

        return [];
    };

    const retrieveData = async () => {
        if (campaignId) {
            const response = await request(api.getIpAddresses)
                .send({ campaignId });

            const addresses = getIpAddressFromResponse(response)
                .map((address) => ({
                    id: address.id,
                    address: address.ip,
                    range: address.ipMask,
                    label: address.label,
                }));

            setIpAddresses(addresses);
        }
    };

    const saveForm = () => {
        formHandler({ ipAddresses });
    };

    const updateIp = async (formData) => {
        if (ipAddresses.filter(
            (ip) => ip.address === formData.address && currentIp.address !== formData.address).length
        ) {
            const dialog = createDialog({
                type: DialogTypes.Error,
                body: [
                    'Unable to update address as this address is already being used',
                    'campaigns.form.ip.error.alreadyUsed'
                ],
            });

            dialog.show();

            return;
        }

        if (campaignId) {
            await request(api.updateIpAddress)
                .send({
                    campaignId,
                    id: formData.id,
                    ip: formData.address,
                    label: formData.label,
                    ipMask: formData.range,
                });

            await retrieveData();
        } else {
            const toUpdate = {
                id: formData.id,
                address: formData.address,
                label: formData.label,
                range: formData.range,
            };

            setIpAddresses((addresses) => addresses.map(
                (addr) => (
                    addr.address === currentIp.address ? { ...addr, ...toUpdate } : addr
                )
            ));
        }
    };

    const createIp = async (formData) => {
        if (ipAddresses.filter((ip) => ip.address === formData.address).length) {
            createDialog({
                type: DialogTypes.Error,
                body: [
                    'Unable to add an address that already exists',
                    'campaigns.form.ip.error.duplicate'
                ],
            })
                .show();

            return;
        }

        if (campaignId) {
            await request(api.createIpAddress)
                .send({
                    campaignId,
                    ip: formData.address,
                    label: formData.label,
                    ipMask: formData.range,
                });
        } else {
            const index = ipAddresses.reduce((max, ip) => (ip.id > max ? ip.id : max), 0);

            setIpAddresses([...ipAddresses, {
                id: index + 1,
                address: formData.address,
                label: formData.label,
                range: formData.range,
            }]);
        }

        await retrieveData();
    };

    const saveIp = async (formData) => {
        const action = formData.id ? updateIp : createIp;
        await action(formData);

        setShowIpModal(false);
    };

    const editIp = (address) => {
        const item = ipAddresses.find((addr) => addr.address === address);

        setCurrentIp({
            ...item,
            updated: true
        });

        setShowIpModal(true);
    };

    const deleteIp = async (address, id) => {
        const dialog = createDialog({
            type: DialogTypes.Confirm,
        });

        dialog.onConfirm(async () => {
            if (campaignId) {
                await request(api.deleteIpAddress)
                    .send({
                        campaignId,
                        ip: address,
                        id,
                    });

                await retrieveData();
            } else {
                setIpAddresses(
                    ipAddresses.filter((addr) => addr.id !== id)
                );
            }
        });

        dialog.show();
    };

    const ipColumns = [
        {
            field: 'label',
            headerName: formatMessage({
                defaultMessage: 'Reference',
                id: 'campaigns.form.ip.reference'
            }),
            flex: 1.5,
        },
        {
            field: 'address',
            headerName: formatMessage({
                defaultMessage: 'Address',
                id: 'campaigns.form.ip.ipAddress'
            }),
            flex: 1,
        },
        {
            field: 'range',
            headerName: formatMessage({
                defaultMessage: 'Range',
                id: 'campaigns.form.ip.range'
            }),
            flex: 1,
        },
        {
            field: 'actions',
            headerName: '',
            flex: 0.6,
            align: 'right',
            sortable: false,
            renderCell: (value) => (
                <IpAddressActions address={value.row.address} deleteIp={deleteIp} editIp={editIp} id={value.row.id} />
            )
        }
    ];

    useEffect(() => {
        retrieveData();
    }, []);

    return (
        <Grid
            className='alternate-rows'
            container
            direction='column'
            spacing={2}
            sx={{
                px: 2,
                my: 2,
                display: show ? 'flex' : 'none'
            }}
            xs={12}
        >
            <Grid container sx={{ p: 3 }}>
                <Grid xs={3}>
                    <Typography variant='sectionHeader'>
                        <FormattedMessage
                            defaultMessage='Ip addresses'
                            id='campaigns.form.ip.ipAddresses'
                        />
                    </Typography>
                </Grid>
                <Grid container xs={9}>
                    <Grid sx={{ mb: 1 }} xs={12}>
                        <Button
                            onClick={() => {
                                setCurrentIp(templateIpAddress);
                                setShowIpModal(true);
                            }}
                            startIcon={<AddIcon />}
                            variant='contained'
                        >
                            <FormattedMessage
                                defaultMessage='Add IP address'
                                id='campaigns.form.ip.addIp'
                            />
                        </Button>
                    </Grid>
                    <Grid xs={12}>
                        <DataGrid
                            autoHeight
                            columns={ipColumns}
                            disableColumnMenu
                            hideFooter
                            loading={!ipAddresses}
                            rowHeight={52}
                            rows={ipAddresses}
                            sx={{
                                border: 'none',
                                '& .MuiDataGrid-columnHeaders': {
                                    borderRadius: 1
                                },
                                '& .MuiDataGrid-columnHeader': {
                                    backgroundColor: 'common.white'
                                },
                                '& .MuiDataGrid-row': {
                                    borderRadius: 1,
                                    '&:nth-of-type(even)': {
                                        backgroundColor: 'common.white'
                                    }
                                },
                                '& .MuiDataGrid-cell': {
                                    borderBottom: 'none'
                                },
                                '& .MuiDataGrid-columnHeader:focus': {
                                    outline: 'none',
                                },
                                '& .MuiDataGrid-cell:focus': {
                                    outline: 'none',
                                },
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <AccessDuration control={control} />
            <Grid container sx={{ my: 3 }}>
                <Grid
                    display='flex'
                    justifyContent='flex-end'
                    xs={12}
                >
                    <Button onClick={backHandler} size='large'>
                        <FormattedMessage defaultMessage='Back' id='button.back' />
                    </Button>
                    <Button
                        onClick={saveForm}
                        size='large'
                        type='submit'
                        variant='contained'
                    >
                        {isUpdate ? (
                            <FormattedMessage defaultMessage='Update campaign' id='campaigns.form.update' />
                        ) : (
                            <FormattedMessage defaultMessage='Create campaign' id='campaigns.form.create' />
                        )}
                    </Button>
                </Grid>
            </Grid>
            <IpAddressModal
                closeHandler={() => {
                    setCurrentIp(templateIpAddress);
                    setShowIpModal(false);
                }}
                data={currentIp}
                saveHandler={saveIp}
                show={showIpModal}
            />
        </Grid>
    );
}

IpAddress.propTypes = {
    backHandler: PropTypes.func.isRequired,
    campaignId: PropTypes.number.isRequired,
    control: PropTypes.object.isRequired,
    formHandler: PropTypes.func.isRequired,
    isUpdate: PropTypes.bool.isRequired,
    show: PropTypes.bool.isRequired,
};
