import React from 'react';
import { FormattedDate } from 'react-intl';
import PropTypes from 'prop-types';

const DefaultFormattedDate = ({ value, ...props }) => (
    <FormattedDate
        day='2-digit'
        month='2-digit'
        value={value}
        year='numeric'
        {...props}
    />
);

DefaultFormattedDate.propTypes = {
    value: PropTypes.instanceOf(Date).isRequired
};

export default DefaultFormattedDate;
