// eslint-disable-next-line no-unused-vars
import { useIntl, IntlShape } from 'react-intl';

/**
 * @typedef {object} TranslationType
 * @property {string} name
 * @property {string} key
 */

/**
 * @enum {TranslationType}
 * @type {Object<TranslationType>}
 */
export const StatusTranslations = {
    Active: {
        name: 'Active',
        key: 'common.status.active'
    },
    Draft: {
        name: 'Draft',
        key: 'common.status.draft'
    },
    Archived: {
        name: 'Archived',
        key: 'common.status.archived'
    },
    Locked: {
        name: 'Locked',
        key: 'common.status.locked'
    },
    Expired: {
        name: 'Expired',
        key: 'common.status.expired'
    },
    None: {
        name: 'None',
        key: 'common.status.none'
    },
    Status: {
        name: 'Status',
        key: 'common.filter.status'
    }
};

/**
 * @type {string[]}
 */
export const StatusFilters = [
    StatusTranslations.Active.name,
    StatusTranslations.Locked.name,
    StatusTranslations.Expired.name,
    StatusTranslations.Draft.name
];

/**
 * @enum {Record}
 * @type {Record<string, string>}
 */
const StatusTypes = {
    Active: 'success',
    Draft: 'secondary',
    Archived: 'warning',
    Expired: 'error',
    Locked: 'info'
};

/**
 * @param {?string} status
 * @param {IntlShape} intl
 */
export const useStatus = (status = null, intl = useIntl()) => {
    const { formatMessage } = intl;

    /**
     * @param str
     * @return {string}
     * @private
     */
    const _capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

    /**
     * @param {keyof StatusTranslations|string} _status
     * @return {string}
     */
    const getContent = (_status = status) => {
        const state = _capitalize(_status);

        if (!StatusTranslations?.[state]) {
            return formatMessage({
                defaultMessage: StatusTranslations.None.name,
                id: StatusTranslations.None.key
            });
        }

        return formatMessage({
            defaultMessage: StatusTranslations[state].name,
            id: StatusTranslations[state].key
        });
    };

    /**
     * @param {keyof StatusTranslations|string} _status
     * @return {string}
     */
    const getType = (_status = status) => StatusTypes?.[_capitalize(_status)] || StatusTypes.active;

    return {
        getContent,
        getType,
    };
};
