import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
    useWatch,
    CheckboxElement,
    DatePickerElement,
    TextFieldElement as TextField,
    FormContainer,
} from 'react-hook-form-mui';

import {
    Unstable_Grid2 as Grid,
    Button,
    CircularProgress,
    Typography, Skeleton, Alert
} from '@mui/material';

import {
    Done as DoneIcon,
    Error as ErrorIcon,
    DeleteOutlined as DeleteIcon,
    HorizontalRule as HorizontalRuleIcon,
    CropOutlined as CropIcon,
} from '@mui/icons-material';

import { FormattedMessage, useIntl } from 'react-intl';
import { api, useApi } from '../base/Request';
import { useCustomForm } from '../base/Form';

import PublicationArticleSelect from '../../components/PublicationArticleSelect';
import ImageCropDialog from '../../components/ImageCropDialog';
import PublicationImagePreview from '../../components/PublicationImagePreview';
import { useErrorBag } from '../base/ErrorBag';
import { cropImage } from '../../utils/image';

const ActionButtons = ({
    deleteUpload,
    cropUpload,
    name
}) => (
    <Grid display='flex' justifyContent='center'>
        <Button
            onClick={() => cropUpload()}
            size='large'
            startIcon={<CropIcon />}
            variant='onlyIcon'
        />
        <Button
            onClick={() => deleteUpload(name)}
            size='large'
            startIcon={<DeleteIcon />}
            variant='onlyIcon'
        />
    </Grid>
);

ActionButtons.propTypes = {
    cropUpload: PropTypes.func.isRequired,
    deleteUpload: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired
};

const imageCropDimensions = {
    x: 0,
    y: 0,
    width: 100,
    unit: '%'
};

const HighlightsFormView = (props) => {
    const {
        isUpdate,
        publicationArticles,
        retrieveData,
        submitData,
    } = props;

    const [showImageCrop, setShowImageCrop] = useState(false);
    const [submitState, setSubmitState] = useState(null);
    const [loadedValues, setLoadedValues] = useState(false);
    const [highlightImage, setHighlightImage] = useState(null);
    const [croppedHighlightImage, setCroppedHighlightImage] = useState(null);
    const [isLoading, setIsLoading] = useState(isUpdate);
    const [isInitialLoad, setIsInitialLoad] = useState(isUpdate);
    const errorBag = useErrorBag();

    const { formatMessage } = useIntl();
    const { request } = useApi();

    const formOptions = {
        defaultValues: isUpdate ? async () => (retrieveData()) : null,
    };

    const {
        control,
        formContext,
        handleSubmit,
        setValues,
    } = useCustomForm(formOptions);

    const values = useWatch({ control });

    const cropUpload = () => {
        setShowImageCrop(true);
    };

    const saveImage = (imageData) => {
        setValues({ highlightImage: imageData });
        setCroppedHighlightImage(imageData);
    };

    const retrieveDefaultData = async () => {
        const { defaultValues } = formOptions;

        if (!defaultValues) {
            setLoadedValues(true);

            return;
        }

        const defaults = await defaultValues();

        if (defaults.linkedArticle) {
            setValues({
                linkedArticle: defaults.linkedArticle,
                linkedPage: defaults.linkedPage,
            });
        }

        setLoadedValues(true);
    };

    const retrieveHighlightImageData = async () => {
        const response = await request(api.getPublicationArticle).send({
            articleId: values.linkedArticle,
            page: values.linkedPage,
        });

        if (!response?.success) {
            setIsLoading(false);
        }

        setCroppedHighlightImage(null);
        setHighlightImage(response?.publicationArticle?.imageData ?? null);
    };

    const submitDataWrapper = async (data) => {
        setSubmitState('loading');

        const response = await submitData(data);

        setSubmitState(response?.success ? 'completed' : 'error');
    };

    const icons = {
        completed: <DoneIcon />,
        loading: <CircularProgress color='inherit' size={22} />,
        error: <ErrorIcon />
    };

    const buttonEndIcon = icons[submitState] || null;

    useEffect(() => {
        if (!highlightImage) return;

        const processAndSetImage = async () => {
            if (isInitialLoad) {
                setCroppedHighlightImage(values.highlightImage);
                setIsInitialLoad(false);
            } else {
                const processedImageUrl = await cropImage(
                    highlightImage,
                    imageCropDimensions,
                    2,
                    false,
                    errorBag
                );

                setCroppedHighlightImage(processedImageUrl);
                setValues({ highlightImage: processedImageUrl });
            }

            setIsLoading(false);
        };

        processAndSetImage();
    }, [highlightImage]);

    useEffect(() => {
        if (values.linkedArticle && values.linkedPage) {
            retrieveHighlightImageData();
        }
    }, [values.linkedArticle, values.linkedPage]);

    useEffect(() => {
        retrieveDefaultData();
    }, [loadedValues]);

    return (
        <FormContainer formContext={formContext}>
            <Grid
                className='alternate-rows'
                container
                direction='column'
                spacing={2}
                sx={{
                    px: 2,
                    my: 2
                }}
                xs={12}
            >
                <Grid container sx={{ p: 3 }}>
                    <Grid xs={3}>
                        <Typography variant='sectionHeader'>
                            <FormattedMessage
                                defaultMessage='Text lines'
                                id='highlights.section.textLines'
                            />
                        </Typography>
                    </Grid>
                    <Grid container xs={9}>
                        <Grid container spacing={3} sx={{ pb: 2 }} xs={12}>
                            <Grid xs={6}>
                                <TextField
                                    control={control}
                                    label={formatMessage({
                                        defaultMessage: 'Title',
                                        id: 'highlights.form.title'
                                    })}
                                    name='title'
                                    type='text'
                                />
                            </Grid>
                            <Grid xs={6}>
                                <TextField
                                    control={control}
                                    label={formatMessage({
                                        defaultMessage: 'Subtitle',
                                        id: 'highlights.form..subtitle'
                                    })}
                                    name='subtitle'
                                    type='text'
                                />
                            </Grid>
                        </Grid>
                        <Grid sx={{ pb: 2 }} xs={12}>
                            <TextField
                                control={control}
                                label={formatMessage({
                                    defaultMessage: 'Tagline',
                                    id: 'highlights.form.tagline'
                                })}
                                name='tagline'
                                type='text'
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container sx={{ p: 3 }}>
                    <Grid xs={3}>
                        <Typography variant='sectionHeader'>
                            <FormattedMessage
                                defaultMessage='Dates'
                                id='highlights.section.dates'
                            />
                        </Typography>
                    </Grid>
                    <Grid container spacing={4} xs={9}>
                        <Grid container xs={12}>
                            <Grid xs={5.5}>
                                <DatePickerElement
                                    control={control}
                                    label={formatMessage({
                                        defaultMessage: 'Start date',
                                        id: 'highlights.form.startDate'
                                    })}
                                    name='startDate'
                                />
                            </Grid>
                            <Grid alignItems='center' display='flex' justifyContent='center' xs={1}>
                                <HorizontalRuleIcon
                                    sx={{
                                        color: 'border.input',
                                    }}
                                />
                            </Grid>
                            <Grid xs={5.5}>
                                <DatePickerElement
                                    control={control}
                                    label={formatMessage({
                                        defaultMessage: 'End date',
                                        id: 'highlights.form.endDate'
                                    })}
                                    name='endDate'
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    container
                    sx={{
                        p: 3,
                        my: 1
                    }}
                >
                    <Grid xs={3}>
                        <Typography variant='sectionHeader'>
                            <FormattedMessage
                                defaultMessage='Add link and image'
                                id='highlights.section.addLinkAndImage'
                            />
                        </Typography>
                    </Grid>
                    <Grid container spacing={4} xs={9}>
                        <Grid container xs={12}>
                            <Grid item xs={12}>
                                <PublicationArticleSelect
                                    control={control}
                                    linkedArticle={values.linkedArticle}
                                    linkedPage={values.linkedPage}
                                    publicationArticles={publicationArticles}
                                />
                            </Grid>
                            {errorBag.has(['imageCrop']) && (
                                <Grid sx={{ py: 0 }}>
                                    <Alert severity='error'>
                                        <FormattedMessage
                                            defaultMessage='Crop area is out of bounds of image'
                                            id='common.crop.outOfBounds'
                                        />
                                    </Alert>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                {isLoading
                                    ? <Skeleton animation='wave' height={250} variant='rounded' width={500} />
                                    : (
                                        <Grid container>
                                            <Grid sx={{ height: 250, width: 500 }} xs={6}>
                                                <PublicationImagePreview
                                                    src={croppedHighlightImage}
                                                    sx={{
                                                        width: '100%',
                                                        height: '100%'
                                                    }}
                                                />
                                            </Grid>
                                            <Grid
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    pl: 1,
                                                }}
                                            >
                                                {highlightImage && (
                                                    <Button
                                                        onClick={cropUpload}
                                                        size='large'
                                                        startIcon={<CropIcon />}
                                                        variant='onlyIcon'
                                                    />
                                                )}
                                            </Grid>
                                        </Grid>
                                    )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container sx={{ p: 3 }}>
                    <Grid xs={3} />
                    <Grid container spacing={2} xs={9}>
                        <Grid xs={12}>
                            <CheckboxElement
                                control={control}
                                label={formatMessage({
                                    defaultMessage: 'Lock this highlight',
                                    id: 'newsstand.settings.form.lockHighlight'
                                })}
                                name='lockHighlight'
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={4} sx={{ pt: 3 }}>
                    <Grid
                        display='flex'
                        justifyContent='flex-end'
                        xs={12}
                    >
                        <Button
                            disabled={submitState === 'loading' || errorBag.length}
                            endIcon={buttonEndIcon}
                            onClick={handleSubmit(submitDataWrapper)}
                            size='large'
                            type='submit'
                            variant='contained'
                        >
                            {isUpdate ? (
                                <FormattedMessage defaultMessage='Update' id='common.update' />
                            ) : (
                                <FormattedMessage defaultMessage='Create' id='common.create' />
                            )}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <ImageCropDialog
                closeHandler={() => setShowImageCrop(false)}
                handleSave={saveImage}
                show={showImageCrop}
                url={highlightImage}
            />
        </FormContainer>
    );
};

HighlightsFormView.propTypes = {
    isUpdate: PropTypes.bool,
    publicationArticles: PropTypes.object.isRequired,
    retrieveData: PropTypes.func,
    submitData: PropTypes.func.isRequired,
};

HighlightsFormView.defaultProps = {
    isUpdate: false,
    retrieveData: () => {
    },
};

export default HighlightsFormView;
