/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Paper, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import {
    TuneOutlined as SettingsIcon,
    ViewComfyOutlined as LayoutIcon,
    FileCopyOutlined as PublicationsIcon,
    PlaylistAddOutlined as SupplementsIcon
} from '@mui/icons-material';

import Phone from '../modules/Phone';
import { useAuth } from '../contexts/Auth';
import ApiUtils from './base/Api';

const fakeData = {
    newsstand: {
        currentUrl: (
            'https://yescrm.com/yescrm6000/web/digi/platform.php'
            + '?token=ZGlnaWRhdGFiYXNlX1Q4l9mMGVmODhlOTA5MmU5M2U5YTUzZmVlYzZiMTdkNzAxMw=='
        )
    }
};

const NewsstandItem = ({
    description, icon: Icon, target, title
}) => (
    <Grid xs={6}>
        <Link to={target}>
            <Paper sx={{ border: '1px solid #D9E0E7', p: 3, cursor: 'pointer' }}>
                <Grid container spacing={3}>
                    <Grid xs={3}>
                        <Paper
                            sx={{
                                width: 64,
                                height: 64,
                                backgroundColor: 'background.lightGreen',
                                p: 2,
                                border: 'none'
                            }}
                        >
                            <Icon sx={{ width: 32, height: 32, color: 'primary.main' }} />
                        </Paper>
                    </Grid>
                    <Grid display='flex' flexDirection='column' justifyContent='center' xs={9}>
                        <Typography component='p' variant='blockTitle'>{title}</Typography>
                        <Typography component='p' variant='blockSubtitle'>{description}</Typography>
                    </Grid>
                </Grid>
            </Paper>
        </Link>
    </Grid>
);

NewsstandItem.propTypes = {
    description: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired,
    target: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};

const NewsstandView = (props) => {
    const { data, retrieveData } = props;
    const { formatMessage } = useIntl();

    useEffect(() => {
        retrieveData();
    }, []);

    return (
        <Grid container spacing={3}>
            <Grid alignSelf='flex-start' container xs={8}>
                <NewsstandItem
                    description='Description'
                    icon={SettingsIcon}
                    target='/newsstand/settings'
                    title={formatMessage({ defaultMessage: 'General settings', id: 'pages.generalSettings' })}
                />
                <NewsstandItem
                    description='Description'
                    icon={LayoutIcon}
                    target='/newsstand/layout'
                    title={formatMessage({ defaultMessage: 'Visual layout', id: 'pages.visualLayout' })}
                />
                <NewsstandItem
                    description='Description'
                    icon={PublicationsIcon}
                    target='/newsstand/publications'
                    title={formatMessage({ defaultMessage: 'Publications', id: 'pages.publications' })}
                />
                <NewsstandItem
                    description='Description'
                    icon={SupplementsIcon}
                    target='/newsstand/supplements'
                    title={formatMessage({ defaultMessage: 'Supplements', id: 'pages.supplements' })}
                />
            </Grid>
            <Grid xs={4}>
                <Phone />
            </Grid>
        </Grid>
    );
};
NewsstandView.propTypes = {
    data: PropTypes.object.isRequired,
    retrieveData: PropTypes.func.isRequired
};

const Newsstand = () => {
    const [data, setData] = React.useState(null);
    const authContext = useAuth();
    const intlContext = useIntl();
    const { Api } = ApiUtils.useApi(authContext, intlContext);

    const retrieveData = async () => {
        // const response = await Api.getNewsstand();
        // if (response?.success && response?.digiRelation) {
        //     setData(response.digiRelation);
        // }
    };
    return (
        <NewsstandView
            data={data}
            retrieveData={retrieveData}
        />
    );
};

export default Newsstand;
