import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { Colors } from './default.js';

export default responsiveFontSizes(createTheme({
    palette: {
        primary: {
            main: Colors.main
        },
        background: {
            default: Colors.midnightBlue,
            paper: Colors.midnightBlue
        },
        active: {
            color: Colors.blue
        },
        mode: 'dark'
    },
    typography: {
        fontFamily: 'Montserrat, sans-serif',
        fontSize: 16,
        menuItem: {
            fontSize: 16,
            fontWeight: 500,
            color: Colors.inactiveWhite,
        },
        subMenuItem: {
            fontSize: 16,
            fontWeight: 400,
            color: Colors.inactiveWhite,
            '&.Mui-selected, &.Mui-focusVisible, &.Mui-selected:hover': {
                color: Colors.red,
            }
        }
    },
    components: {
        MuiDrawerPaper: {
            styleOverride: {
                width: 248
            }
        },
        MuiToolbar: {
            styleOverrides: {
                root: {
                    height: 80,
                    display: 'flex',
                    justifyContent: 'center',
                    img: {
                        width: 134
                    }
                }
            }
        },
        MuiList: {
            styleOverrides: {
                root: {
                    paddingTop: 20
                }
            }
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    paddingTop: 0,
                    paddingBottom: 4
                }
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: ({ ownerState }) => ({
                    borderRadius: 8,
                    transitionDuration: '0.3s',
                    height: 52,
                    '&.Mui-selected, &.Mui-focusVisible, &.Mui-selected:hover': {
                        backgroundColor: Colors.main,
                        span: {
                            color: Colors.white,
                            fontWeight: 700
                        }
                    },
                    ...(ownerState.variant === 'subMenuItem' && {
                        backgroundColor: 'transparent !important',
                        padding: 9,
                        paddingLeft: 16,
                    }),
                })
            }
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    '& .MuiTouchRipple-root': {
                        color: Colors.whiteTransparent30,
                    },
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    minWidth: 45
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                inset: {
                    paddingLeft: 45
                }
            }
        },
        MuiSvgIcon: {
            styleOverrides: {
                colorPrimary: {
                    color: Colors.white
                },
                colorSecondary: {
                    color: Colors.inactiveWhite
                }
            }
        }
    }
}));
