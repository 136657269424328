import dayjs from 'dayjs';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/nl';

const availableLocales = ['en-us', 'en-gb', 'nl'];
const defaultLocale = 'en-gb';

/**
 * Checks if a locale is available.
 * @param {string} locale - Locale code to check.
 * @returns {boolean} - True if the locale is available, false otherwise.
 */
export const isLocaleAvailable = (locale) => availableLocales.includes(locale);

/**
 * Gets the user's locale from the browser.
 * @returns {string} - The detected user locale.
 */
export const getUserLocale = () => navigator.language;

/**
 * Sets the locale globally in Day.js if available.
 */
export const setDayjsLocale = () => {
    const detectedLocale = getUserLocale().toLowerCase();

    if (isLocaleAvailable(detectedLocale)) {
        dayjs.locale(detectedLocale);
        return detectedLocale;
    }
    dayjs.locale(defaultLocale);

    return defaultLocale;
};
