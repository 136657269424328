import { Outlet, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import {
    Box, CssBaseline, Toolbar, Container
} from '@mui/material';
import { useIntl } from 'react-intl';

import { useAuth } from './contexts/Auth';
import { useAccountInfo } from './contexts/AccountInfo';
import { getTranslatedPageName } from './utils/routing';
import ApiUtils from './routes/base/Api';
import Menu from './modules/Menu';
import TopBar from './modules/TopBar';

import useDocL10n from './i18n/useDocL10n';

export default function AppContainer() {
    const { setAccountData } = useAccountInfo();
    const authContext = useAuth();
    const intlContext = useIntl();
    const { Api } = ApiUtils.useApi(authContext, intlContext);
    const { formatMessage } = intlContext;
    const translatedPageName = getTranslatedPageName(formatMessage, useLocation().pathname);
    useDocL10n(translatedPageName);

    // TEMPORARY SOLUTION FOR 404 ERROR ON PAGE RELOAD
    // useEffect(() => {
    //     const handleKeyDown = (e) => {
    //         if (e.key === 'F5' || ((e.ctrlKey || e.metaKey) && e.key === 'r')) {
    //             e.preventDefault();
    //             window.location.reload();
    //         }
    //     };
    //
    //     const handleBeforeUnload = (e) => {
    //         e.preventDefault();
    //         e.returnValue = ''; // This triggers the confirmation dialog in most browsers
    //     };
    //
    //     // Prevent back navigation
    //     const handlePopState = () => {
    //         window.history.pushState(null, '', window.location.href);
    //     };
    //
    //     window.addEventListener('keydown', handleKeyDown);
    //     window.addEventListener('beforeunload', handleBeforeUnload);
    //     window.history.pushState(null, '', window.location.href);
    //     window.addEventListener('popstate', handlePopState);
    //
    //     return () => {
    //         window.removeEventListener('keydown', handleKeyDown);
    //         window.removeEventListener('beforeunload', handleBeforeUnload);
    //         window.removeEventListener('popstate', handlePopState);
    //     };
    // }, []);

    // Get the preview digiUrl, and possibly we can add account data as well
    const retrieveAccountData = async () => {
        if (authContext.signedIn) {
            const response = await Api.getDashboard();
            if (response?.success && response?.dashboard) {
                const { digiUrl, digiUrlAccess } = response.dashboard;
                setAccountData({ previewUrl: digiUrl, sanitizedUrl: digiUrlAccess });
            }
        }
    };

    useEffect(() => {
        retrieveAccountData();
    }, [authContext.signedIn]);

    return (
        <Box sx={{ display: 'flex', minWidth: 1280 }}>
            <CssBaseline />
            <TopBar />
            <Menu />
            <Container disableGutters maxWidth='xl'>
                <Box
                    component='main'
                    sx={{ flexGrow: 1, pt: 4, pb: 4 }}
                >
                    <Toolbar />
                    <Outlet />
                </Box>
            </Container>

        </Box>
    );
}
