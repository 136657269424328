import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    useNavigate,
    useLocation,
    Link as RouterLink,
} from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm, TextFieldElement as TextField, FormContainer } from 'react-hook-form-mui';
import {
    Button,
    CircularProgress,
    Unstable_Grid2 as Grid,
    Typography,
} from '@mui/material';

import { useAuth } from '../contexts/Auth';

export default function Login() {
    const [error, setError] = useState(null);
    const location = useLocation();
    const navigate = useNavigate();
    const { formatMessage } = useIntl();
    const params = new URLSearchParams(location.search);
    const from = params.get('from') || '/';

    const auth = useAuth();

    const handleSubmit = (data, reset) => {
        const { database, email, password } = data;

        if (!email || !password) {
            setError(formatMessage({
                defaultMessage: 'Please enter your email and password',
                id: 'login.error.missing'
            }));
            return;
        }

        auth.signin(
            database,
            email,
            password,
            (authError) => {
                // Send them back to the page they tried to visit when they were
                // redirected to the login page. Use { replace: true } so we don't create
                // another entry in the history stack for the login page.  This means that
                // when they get to the protected page and click the back button, they
                // won't end up back on the login page, which is also really nice for the
                // user experience.
                if (!authError) {
                    navigate(from, { replace: true });
                } else {
                    reset();
                    setError(authError);
                }
            }
        );
    };

    // If user already logged in go to root page
    if (auth.token) {
        navigate('/', { replace: true });
    }

    return (
        <LoginView error={error} submitData={handleSubmit} />
    );
}

const LoginView = (props) => {
    const [loading, setLoading] = useState(false);
    const { error, submitData } = props;
    const { formatMessage } = useIntl();
    const {
        control,
        formContext,
        reset,
        handleSubmit
    } = useForm();
    const submitDataWrapper = (data) => {
        setLoading(true);
        submitData(data, reset);
        setLoading(false);
    };
    // Using Grid center 3 TextFields
    return (
        <FormContainer formContext={formContext}>
            <Grid
                alignItems='center'
                container
                direction='column'
                justifyContent='center'
                spacing={2}
            >
                <Grid item xs={6}>
                    <TextField
                        control={control}
                        fullWidth
                        label={formatMessage({ defaultMessage: 'Database', id: 'login.database' })}
                        name='database'
                        required
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        control={control}
                        fullWidth
                        label={formatMessage({ defaultMessage: 'Email', id: 'login.email' })}
                        name='email'
                        required
                        type='email'
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        control={control}
                        fullWidth
                        label={formatMessage({ defaultMessage: 'Password', id: 'login.password' })}
                        name='password'
                        required
                        type='password'
                    />
                </Grid>
                {error && (
                    <Grid item xs={6}>
                        <Typography color='error' variant='blockSubtitle'>
                            {error}
                        </Typography>
                    </Grid>
                )}
                <Grid container justifyContent='space-between' p={2} xs={6}>
                    <Grid
                        item
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            paddingLeft: 0
                        }}
                    >
                        <Button
                            component={RouterLink}
                            size='large'
                            to='/forgot-password'
                            variant='text'
                        >
                            <FormattedMessage defaultMessage='Forgot password?' id='login.forgotPassword' />
                        </Button>
                    </Grid>
                    <Grid
                        item
                        sx={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <Button
                            endIcon={loading ? <CircularProgress color='inherit' size={22} /> : null}
                            onClick={handleSubmit(submitDataWrapper)}
                            size='large'
                            type='submit'
                            variant='contained'
                        >
                            <FormattedMessage defaultMessage='Login' id='common.login' />
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </FormContainer>
    );
};
LoginView.propTypes = {
    error: PropTypes.string,
    submitData: PropTypes.func.isRequired
};
LoginView.defaultProps = {
    error: null
};
