import React, { useState, useRef, useEffect } from 'react';
import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop from 'react-image-crop';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

import { FormattedMessage, useIntl } from 'react-intl';

import DefaultDialog from '../modules/DefaultDialog';

const imageCropDimensions = {
    unit: '%',
    width: 100,
    height: 36.7,
    x: 0,
    y: 0
};

const ImageCropDialog = (props) => {
    const {
        show,
        url,
        closeHandler,
        handleSave
    } = props;

    const [crop, setCrop] = useState(imageCropDimensions);

    const { formatMessage } = useIntl();
    const [completedCrop, setCompletedCrop] = useState(null);
    const imgRef = useRef(null);
    const previewCanvasRef = useRef(null);
    const onCropChange = (value) => {
        setCrop(value);
    };

    const onCropComplete = (value) => {
        setCompletedCrop(value);
    };

    useEffect(() => {
        setCrop(imageCropDimensions);
    }, [url]);

    useEffect(() => {
        if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
            return;
        }

        const image = imgRef.current;
        const canvas = previewCanvasRef.current;
        const crop1 = completedCrop;

        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');

        canvas.width = crop1.width;
        canvas.height = crop1.height;

        ctx.drawImage(
            image,
            crop1.x * scaleX,
            crop1.y * scaleY,
            crop1.width * scaleX,
            crop1.height * scaleY,
            0,
            0,
            crop1.width,
            crop1.height
        );
    }, [completedCrop]);

    const onSave = () => {
        if (!previewCanvasRef.current) {
            return;
        }

        const data = previewCanvasRef.current.toDataURL('image/jpeg');

        handleSave(data);
        closeHandler();
    };

    return (
        <DefaultDialog
            closeHandler={closeHandler}
            saveButton={(
                <Button
                    disabled={!completedCrop || !imgRef.current}
                    onClick={onSave}
                    size='large'
                    type='submit'
                    variant='contained'
                >
                    <FormattedMessage
                        defaultMessage='Save'
                        id='button.save'
                    />
                </Button>
            )}
            show={show}
            size='sm'
            title={formatMessage({
                defaultMessage: 'Select image',
                id: 'common.imageCropDialogTitle'
            })}
        >
            <div style={{ maxWidth: '100%', maxHeight: '500px', overflow: 'auto' }}>
                <ReactCrop
                    aspect={2}
                    crop={crop}
                    onChange={onCropChange}
                    onComplete={onCropComplete}
                >
                    <img
                        ref={imgRef}
                        alt='Crop me'
                        src={url}
                        style={{ maxWidth: '100%' }}
                    />
                </ReactCrop>
            </div>
            <canvas
                ref={previewCanvasRef}
                style={{
                    width: completedCrop?.width ?? 0,
                    height: completedCrop?.height ?? 0,
                    display: 'none',
                }}
            />
        </DefaultDialog>
    );
};

ImageCropDialog.propTypes = {
    closeHandler: PropTypes.func.isRequired,
    handleSave: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    url: PropTypes.string.isRequired,
};

export default ImageCropDialog;
