import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { DeleteOutlined as DeleteIcon, } from '@mui/icons-material';

import {
    Controller, FormContainer, TextFieldElement, useForm
} from 'react-hook-form-mui';

import { useAccountInfo } from '../../../contexts/AccountInfo';

import PercentageSlider from '../../../components/PercentageSlider';
import ImageDropzone from '../../../components/ImageDropzone';
import RequestButton from '../../../components/RequestButton';
import { isValidUrl } from '../../../utils/utils';

const Logo = (props) => {
    const { getData, submitData } = props;
    const { formatMessage } = useIntl();
    const { triggerReload } = useAccountInfo();
    const {
        control,
        formContext,
        reset,
        setValue,
        handleSubmit
    } = useForm({ defaultValues: getData });

    const submitDataSuccess = async () => {
        const newData = await getData();
        reset(newData);
        triggerReload();
    };

    const handleUpload = (name, newValue) => {
        setValue(name, newValue);
    };

    const deleteUpload = (name) => {
        setValue(name, null);
    };

    return (
        <FormContainer formContext={formContext}>
            <Grid className='alternate-rows' container direction='column' spacing={2} sx={{ px: 2 }} xs={12}>
                <Grid container>
                    <Grid xs={4}>
                        <Typography variant='sectionHeader'>
                            <FormattedMessage
                                defaultMessage='Logo'
                                id='newsstand.layout.logo.logo'
                            />
                        </Typography>
                    </Grid>
                    <Grid alignItems='center' container direction='column' xs={8}>
                        <Controller
                            control={control}
                            name='newsstandLogo'
                            render={({ field }) => (
                                <Grid alignItems='center' container flexWrap='nowrap' xs={12}>
                                    <ImageDropzone
                                        handleUpload={handleUpload}
                                        maxFileSize={10}
                                        minimumImageSize={{ width: 1500, height: 1500 }}
                                        name='newsstandLogo'
                                        previewStyle={{ width: 224, height: 95 }}
                                        style={{ height: 180 }}
                                        value={field.value}
                                    />
                                    {field.value && (
                                        <>
                                            {/*
                                            <Button
                                                size='large'
                                                startIcon={<UploadIcon />}
                                                sx={{ ml: 5 }}
                                                variant='onlyIcon'
                                            />
                                            <Button size='large' startIcon={<LinkIcon />} variant='onlyIcon' />
                                            */}
                                            <Button
                                                onClick={() => deleteUpload('newsstandLogo')}
                                                size='large'
                                                startIcon={<DeleteIcon />}
                                                variant='onlyIcon'
                                            />
                                        </>
                                    )}
                                </Grid>
                            )}
                        />
                        <Grid sx={{ mt: 2 }} xs={12}>
                            <PercentageSlider
                                control={control}
                                max={100}
                                min={10}
                                name='logoSize'
                            />
                        </Grid>
                        <Grid sx={{ mt: 2 }} xs={12}>
                            <TextFieldElement
                                control={control}
                                label={formatMessage({
                                    defaultMessage: 'URL for logo link',
                                    id: 'newsstand.layout.logo.linkUrl'
                                })}
                                name='logoLinkUrl'
                                placeholder='https://'
                                type='text'
                                validation={{
                                    validate: (value) => {
                                        if (!value) {
                                            return true;
                                        }
                                        if (!isValidUrl(value)) {
                                            return formatMessage({
                                                defaultMessage: 'Invalid URL', id: 'form.validation.invalidUrl'
                                            });
                                        }
                                        return true;
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container sx={{ py: 3, my: 2 }}>
                    <Grid xs={4}>
                        <Typography variant='sectionHeader'>
                            <FormattedMessage
                                defaultMessage='Browser favicon'
                                id='newsstand.layout.logo.favicon'
                            />
                        </Typography>
                    </Grid>
                    <Grid container xs={8}>
                        <Controller
                            control={control}
                            name='newsstandFavicon'
                            render={({ field }) => (
                                <Grid alignItems='center' container flexWrap='nowrap' xs={12}>
                                    <ImageDropzone
                                        handleUpload={handleUpload}
                                        maxFileSize={5}
                                        minimumSize={{ width: 64, height: 64 }}
                                        name='newsstandFavicon'
                                        previewStyle={{ width: 95, height: 95 }}
                                        style={{ height: 180 }}
                                        value={field.value}
                                    />
                                    {field.value && (
                                        <>
                                            { /* <Button
                                                size='large'
                                                startIcon={<UploadIcon />}
                                                sx={{ ml: 5 }}
                                                variant='onlyIcon'
                                            /> */ }
                                            <Button
                                                onClick={() => deleteUpload('newsstandFavicon')}
                                                size='large'
                                                startIcon={<DeleteIcon />}
                                                variant='onlyIcon'
                                            />
                                        </>
                                    )}
                                </Grid>
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid container sx={{ mt: 1, mb: 2 }}>
                    <Grid xs={4}>
                        <Typography variant='sectionHeader'>
                            <FormattedMessage
                                defaultMessage='Browser tab title'
                                id='newsstand.layout.logo.browserTitle'
                            />
                        </Typography>
                    </Grid>
                    <Grid container xs={8}>
                        <Grid container xs={12}>
                            <TextFieldElement
                                control={control}
                                label={formatMessage({ defaultMessage: 'Title', id: 'newsstand.layout.logo.title' })}
                                name='title'
                                type='text'
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid
                        display='flex'
                        justifyContent='flex-end'
                        xs={12}
                    >
                        <RequestButton
                            injectFormData={handleSubmit}
                            onSuccess={submitDataSuccess}
                            sendData={submitData}
                            size='large'
                            type='submit'
                            variant='contained'
                        >
                            <FormattedMessage defaultMessage='Update' id='common.update' />
                        </RequestButton>
                    </Grid>
                </Grid>
            </Grid>
        </FormContainer>
    );
};
Logo.propTypes = {
    getData: PropTypes.object.isRequired,
    submitData: PropTypes.func.isRequired,
};

export default Logo;
