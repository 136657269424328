import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Button, Chip, Typography, Unstable_Grid2 as Grid
} from '@mui/material';
import { Check as CheckIcon } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import { TextFieldElement, useForm } from 'react-hook-form-mui';

import FilterSelect from '../../components/FilterSelect';
import RequestButton from '../../components/RequestButton';
import DefaultDialog from '../../modules/DefaultDialog';

import { useAuth } from '../../contexts/Auth';
import ApiUtils from '../base/Api';
import { formatDate, groupListByParent } from '../../utils/utils';
import { useSortFilterSearch } from '../../contexts/SortFilterSearch';

const PublicationsView = (props) => {
    const { getData, updateDescription } = props;
    const [showEditDescription, setShowEditDescription] = React.useState(false);
    const { formatMessage } = useIntl();
    const [data, setData] = React.useState(null);
    const { filter, setFilter } = useSortFilterSearch('publications');
    const magazineFilter = filter?.magazine || undefined;

    useEffect(() => {
        getData().then(setData);
    }, []);

    useEffect(() => {
        if (data?.magazines?.length && magazineFilter === undefined) setFilter('magazine', data.magazines[0].id);
    }, [data?.magazines]);

    const {
        control,
        handleSubmit,
        setValue
    } = useForm();

    // FOR FUTURE IMPLEMENTATION
    // const editPublication = (publiData) => {
    //     const publication = data?.publications?.find((doc) => doc.id === publiData.id);
    //     if (publication) {
    //         setValue('currentPublicationId', publication.id);
    //         setValue('publicationDescription', publication.description);
    //         setShowEditDescription(true);
    //     }
    // };

    const editMagazine = () => {
        const selectedMagazine = data?.magazines.find((magazine) => magazine.id === magazineFilter);
        if (selectedMagazine) {
            setValue('currentPublicationId', selectedMagazine.originalId);
            setValue('publicationDescription', selectedMagazine.description);
            setShowEditDescription(true);
        }
    };

    const updateDescriptionSuccess = () => {
        setShowEditDescription(false);
        getData().then(setData);
    };

    const publicationColumns = [
        {
            field: 'icon',
            headerName: '',
            flex: 0.3,
            sortable: false,
            renderCell: (value) => (
                <img
                    alt='icon'
                    src={value.value}
                    style={{ borderRadius: '4px', width: 56, height: 75.7 }}
                />
            )
        },
        {
            field: 'name',
            headerName: formatMessage({ defaultMessage: 'Name', id: 'newsstand.publications.name' }),
            flex: 1,
            sortable: false
        },
        {
            field: 'dateAdded',
            headerName: formatMessage({
                defaultMessage: 'Date added',
                id: 'newsstand.publications.dateAdded'
            }),
            flex: 0.5,
            sortable: false,
        },
        {
            field: 'html',
            headerName: formatMessage({ defaultMessage: 'HTML', id: 'newsstand.publications.html' }),
            flex: 0.4,
            sortable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (value) => (
                value.formattedValue === true && <CheckIcon sx={{ color: 'primary.main' }} />
            )
        },
        {
            field: 'tts',
            headerName: formatMessage({ defaultMessage: 'Text to speech', id: 'newsstand.publications.textToSpeech' }),
            flex: 0.6,
            sortable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (value) => (
                value.formattedValue === true && <CheckIcon sx={{ color: 'primary.main' }} />
            )
        },
        {
            field: 'live',
            headerName: formatMessage({ defaultMessage: 'Live', id: 'common.live' }),
            flex: 0.4,
            sortable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (value) => (
                value.formattedValue && (
                    <Chip
                        color='success'
                        label={formatMessage({ defaultMessage: 'Live', id: 'common.live' })}
                        size='small'
                        variant='outlined'
                    />
                )
            )
        },
        // FOR FUTURE IMPLEMENTATION
        // {
        //     field: 'actions',
        //     headerName: '',
        //     flex: 0.2,
        //     sortable: false,
        //     align: 'right',
        //     renderCell: (value) => (
        //         <Button
        //             onClick={() => { editPublication(value.row); }}
        //             size='large'
        //             startIcon={<EditIcon />}
        //             variant='onlyIcon'
        //         />
        //     )
        // }
    ];

    const changeMagazineFilter = (event) => {
        const { target: { value } } = event;
        setFilter('magazine', value);
    };

    let rows = data?.publications || [];
    if (magazineFilter !== undefined) rows = rows.filter((row) => row.magazineId === magazineFilter);

    return (
        <Grid container direction='column' spacing={2}>
            <Grid alignItems='center' container>
                <Grid>
                    {magazineFilter !== undefined && (
                        <FilterSelect
                            changeHandler={changeMagazineFilter}
                            getValueText={
                                (value) => data?.magazines.find((magazine) => magazine.id === value)?.label
                            }
                            minWidth={250}
                            options={data?.magazines.map(
                                (magazine) => ({ label: magazine.label, value: magazine.id })
                            )}
                            value={magazineFilter}
                        />
                    )}
                </Grid>
                <Grid>
                    <Button
                        onClick={() => { editMagazine(); }}
                        sx={{
                            py: 0,
                            fontSize: 'unset'
                        }}
                        variant='text'
                    >
                        <Typography color='primary' variant='medium600'>
                            <FormattedMessage
                                defaultMessage='Edit description...'
                                id='newsstand.publications.rootDescription'
                            />
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
            <Grid sx={{ py: 2, my: 1 }}>
                <DataGrid
                    autoHeight
                    columns={publicationColumns}
                    disableColumnMenu
                    hideFooter
                    initialState={{
                        sorting: {
                            sortModel: [{ field: 'id', sort: 'asc' }],
                        },
                    }}
                    loading={!data}
                    rowHeight={88}
                    rows={rows}
                    sx={{
                        border: 'none',
                        '& .MuiDataGrid-columnHeaders': {
                            borderRadius: 1
                        },
                        '& .MuiDataGrid-columnHeader': {
                            backgroundColor: 'common.white'
                        },
                        '& .MuiDataGrid-row': {
                            '&:nth-of-type(even)': {
                                backgroundColor: 'background.lightGrayForm'
                            }
                        },
                        '& .MuiDataGrid-cell': {
                            borderBottom: 'none'
                        },
                        '& .MuiDataGrid-columnHeader:focus': {
                            outline: 'none',
                        },
                        '& .MuiDataGrid-cell:focus': {
                            outline: 'none',
                        },
                    }}
                />
            </Grid>
            <DefaultDialog
                closeHandler={() => { setShowEditDescription(false); }}
                saveButton={(
                    <RequestButton
                        injectFormData={handleSubmit}
                        onSuccess={updateDescriptionSuccess}
                        sendData={updateDescription}
                        size='large'
                        type='submit'
                        variant='contained'
                    >
                        <FormattedMessage
                            defaultMessage='Update'
                            id='common.update'
                        />
                    </RequestButton>
                )}
                show={showEditDescription}
                size='modalSm'
                title={(
                    <FormattedMessage
                        defaultMessage='Update description'
                        id='newsstand.publications.updatePublication'
                    />
                )}
            >
                <Grid container direction='column' spacing={3}>
                    <Grid>
                        <TextFieldElement
                            control={control}
                            label={formatMessage({
                                defaultMessage: 'Description', id: 'common.description'
                            })}
                            multiline
                            name='publicationDescription'
                            required
                            type='text'
                        />
                    </Grid>
                </Grid>
            </DefaultDialog>
        </Grid>
    );
};
PublicationsView.propTypes = {
    getData: PropTypes.func.isRequired,
    updateDescription: PropTypes.func.isRequired
};

const Publications = () => {
    const authContext = useAuth();
    const intlContext = useIntl();
    const { Api } = ApiUtils.useApi(authContext, intlContext);

    const retrieveData = async () => {
        const response = await Api.getPublicationArticles({ includeRoot: true });
        if (response.success && response.publicationArticles) {
            const grouped = groupListByParent(response.publicationArticles, 'rootName', (x) => (!x.parentId));
            const magazines = [];
            const publications = [];
            Object.keys(grouped).forEach((key, index) => {
                const magazineId = index + 1;
                // magazines.push({ id: magazineId, label: key, originalId: null });
                grouped[key].forEach((publication, pubIndex) => {
                    publications.push({
                        magazineId,
                        id: publication.id,
                        dateAdded: publication.dateCreated && formatDate(publication.dateCreated),
                        name: publication.branchName || publication.fullName,
                        html: publication.hasHtmlContent,
                        tts: publication.hasSpeechContent,
                        live: publication.publicationStatus === 'live',
                        icon: publication.publicationCoverImageMedium,
                        description: publication.mediaDescription,
                        variant: '',
                    });
                    if (pubIndex === 0) {
                        const magazine = response.publicationArticles.find((x) => x.id === publication.parentId);
                        if (magazine) {
                            magazines.push({
                                id: magazineId,
                                label: key,
                                originalId: magazine.id,
                                description: magazine.mediaDescription
                            });
                        }
                    }
                });
            });
            console.log(magazines);
            console.log(publications);
            return {
                magazines,
                publications
            };
        }
        return {
            magazines: [],
            publications: []
        };
    };

    const updateDescription = async (data) => {
        const sendData = {
            id: data.currentPublicationId,
            mediaDescription: data.publicationDescription
        };
        return Api.updatePublicationArticle(sendData);
    };

    return (
        <PublicationsView
            getData={retrieveData}
            updateDescription={updateDescription}
        />
    );
};

export default Publications;
