/**
 * Converts an image URL to a base64-encoded string.
 *
 * @param {string} url - The URL of the image to be converted.
 * @return {Promise<string>} A promise that resolves to a base64-encoded string representing the image.
 * @throws {Error} Will throw an error if the fetch operation or file reading fails.
 */
export const imageUrlToBase64 = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
        reader.onloadend = () => {
            resolve(reader.result);
        };

        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
};

/**
 * Creates a canvas element and sets its dimensions.
 * @param {number} width - The width of the canvas.
 * @param {number} height - The height of the canvas.
 * @returns {HTMLCanvasElement} - The created canvas element.
 */
export const createCanvas = (width, height) => {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    return canvas;
};

/**
 * Calculates the height of the image based on aspect ratio or scale factor.
 * @param {number} width - The width of the image or canvas.
 * @param {number} imageWidth - The original width of the image.
 * @param {number} imageHeight - The original height of the image.
 * @param {number} [aspectRatio] - The desired aspect ratio.
 * @returns {number} - The calculated height.
 */
export const calculateHeight = (width, imageWidth, imageHeight, aspectRatio) => {
    if (aspectRatio) {
        return width / aspectRatio;
    }
    const scaleFactor = width / imageWidth;
    return imageHeight * scaleFactor;
};

/**
 * Resizes an image to a specified maximum width while maintaining the aspect ratio.
 * @param {string} imageUrl - The URL of the image to resize.
 * @param {number} [maxWidth=1024] - The maximum width for the resized image.
 * @param {number|null} [aspectRatio] - The desired aspect ratio (width/height) for the resized image.
 * @returns {Promise<string>} - The resized image as a base64 encoded string.
 */
export const resizeImage = (imageUrl, aspectRatio, maxWidth = 1024) => {
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.src = imageUrl;

    return new Promise((resolve) => {
        image.onload = () => {
            const height = calculateHeight(maxWidth, image.width, image.height, aspectRatio);
            const canvas = createCanvas(maxWidth, height);
            const ctx = canvas.getContext('2d');

            ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

            resolve(canvas.toDataURL('image/jpeg'));
        };
    });
};

/**
 * @param image
 * @param canvas
 * @param crop
 */
export const cropImageFromCanvas = (image, canvas, crop) => {
    canvas
        .getContext('2d')
        .drawImage(
            image,
            crop.x,
            crop.y,
            crop.width,
            crop.height,
            0,
            0,
            crop.width,
            crop.height
        );
};

/**
 * Crops an image based on the provided crop options and aspect ratio.
 * @param {string} imageUrl - The URL of the image to crop.
 * @param {Object} cropOptions - The crop options including unit, width, x, and y.
 * @param {'%' | 'px'} cropOptions.unit - The unit of the crop dimensions (percentage or pixels).
 * @param {number} cropOptions.width - The width of the crop area.
 * @param {number} cropOptions.x - The x-coordinate of the crop area.
 * @param {number} cropOptions.y - The y-coordinate of the crop area.
 * @param {number} [aspectRatio=null] - The desired aspect ratio (width/height)
 * for the crop. If null, the original aspect ratio is maintained.
 * @param {boolean} [centered=false] - Whether the crop should be centered within the image.
 * @param {Object} [errorBag=null] - An optional object for collecting errors.
 * @returns {Promise<string>} - A promise that resolves to the cropped image as a base64 encoded string.
 */
export const cropImage = (
    imageUrl,
    cropOptions,
    aspectRatio,
    centered = false,
    errorBag = null
) => {
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.src = imageUrl;

    return new Promise((resolve) => {
        image.onload = () => {
            let width; let x; let y;

            if (cropOptions.unit === '%') {
                width = (cropOptions.width / 100) * image.width;
            } else {
                width = cropOptions.width;
            }

            const height = calculateHeight(width, image.width, image.height, aspectRatio);

            if (centered) {
                x = (image.width - width) / 2;
                y = (image.height - height) / 2;
            } else {
                x = cropOptions.x;
                y = cropOptions.y;
            }

            const crop = {
                x, y, width, height
            };

            const isInBounds = (
                crop.y >= 0
                && crop.x >= 0
                && crop.y + crop.height <= image.height
                && crop.x + crop.width <= image.width
            );

            if (!isInBounds && errorBag) {
                errorBag.set({ imageCrop: !isInBounds });
                return;
            }
            const canvas = createCanvas(crop.width, crop.height);

            cropImageFromCanvas(
                image,
                canvas,
                crop
            );

            resolve(canvas.toDataURL('image/jpeg'));
        };
    });
};

/**
 * Gets the dimensions (width and height) of an image from a given data URL.
 *
 * @param {string} dataUrl - The data URL of the image, typically in base64 format.
 * @returns {Promise<{width: number, height: number}>} A promise that resolves with an object containing
 * the image's width and height.
 * @throws Will reject the promise if the image fails to load.
 */
export const getImageDimensions = (dataUrl) => new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
        resolve({
            width: img.width,
            height: img.height
        });
    };

    img.onerror = reject;
    img.src = dataUrl;
});

/**
 * Checks if an image should be cropped based on its original dimensions
 * and the maximum allowed dimensions.
 *
 * @param {string} imageUrl - The URL of the image to check.
 * @param {number} [maxWidth=1024] - The maximum allowed width.
 * @returns {Promise<boolean>} - A promise that resolves to a boolean value
 * indicating whether the image should be cropped.
 */
export const shouldResizeImage = async (imageUrl, maxWidth = 1024) => {
    const { width } = await getImageDimensions(imageUrl);
    return width > maxWidth;
};

/**
 * Resizes an image if necessary based on the original image dimensions.
 * @param {string} imageUrl - The URL of the image to check and resize if needed.
 * @returns {Promise<string>} - A promise that resolves to the resized image as
 * a base64 encoded string, or the original image if resizing is not needed.
 */
export const resizeImageIfNeeded = async (imageUrl) => {
    if (await shouldResizeImage(imageUrl)) {
        return resizeImage(imageUrl);
    }
    return imageUrl;
};

/**
 * Determines if an image should be cropped to a square based on its dimensions and aspect ratio.
 * @param {number} imgWidth - The width of the image.
 * @param {number} imgHeight - The height of the image.
 * @param {number} aspectRatio - The desired aspect ratio (1 for square).
 * @returns {boolean} - True if the image should be cropped to a square, false otherwise.
 */
export const shouldCropToSquare = (imgWidth, imgHeight, aspectRatio) => imgWidth !== imgHeight && aspectRatio === 1;

/**
 * Determines if an image should be cropped to an aspect ratio of 2:1.
 * @param {number} imgWidth - The width of the image.
 * @param {number} imgHeight - The height of the image.
 * @param {number} aspectRatio - The desired aspect ratio.
 * @returns {boolean} - True if the image should be cropped to an aspect ratio of 2:1, false otherwise.
 */
export const shouldCropToAspectRatioTwo = (imgWidth, imgHeight, aspectRatio) => {
    const isNotAspectRatioTwo = imgWidth / 2 !== imgHeight;
    return isNotAspectRatioTwo && aspectRatio === 2;
};

/**
 * Crops an image if necessary based on its dimensions, the smallest side, and the desired aspect ratio.
 * @param {string} imageUrl - The URL of the image to potentially crop.
 * @param {number} imgWidth - The width of the image.
 * @param {number} imgHeight - The height of the image.
 * @param {number} smallestSide - The smallest dimension (width or height) of the image.
 * @param {number} aspectRatio - The desired aspect ratio for the cropped image.
 * @param {function} handleImageCrop - A function that performs the cropping.
 * @returns {Promise<string>} - A promise that resolves to the cropped image as
 * a base64 encoded string, or the original image if no cropping was necessary.
 */
export const cropImageIfNeeded = async (imageUrl, imgWidth, imgHeight, smallestSide, aspectRatio, handleImageCrop) => {
    if (!aspectRatio) return imageUrl;

    if (shouldCropToSquare(imgWidth, imgHeight, aspectRatio)) {
        return handleImageCrop(imageUrl, smallestSide);
    }

    if (shouldCropToAspectRatioTwo(imgWidth, imgHeight, aspectRatio)) {
        return handleImageCrop(imageUrl, smallestSide);
    }

    return imageUrl;
};
