import React from 'react';
import PropTypes from 'prop-types';
import {
    Box, Button, Chip, Typography, Unstable_Grid2 as Grid
} from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    FileUploadOutlined as UploadIcon,
    FileDownloadOutlined as DownloadIcon
} from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';

import FileButton from '../../../components/FileButton';

// Example
// {
//     id: 4,
//     date: new Date('2024-01-11'),
//     name: 'emails3.xlsx',
//     entries: 3600
// },

const AccessData = (props) => {
    const {
        getData, downloadRegistrations, submitData
    } = props;
    const { formatMessage } = useIntl();
    const [data, setData] = React.useState(null);

    // Get the data entry with the latest date
    const latestUpload = data?.uploads.reduce((latest, upload) => (
        latest.date > upload.date ? latest : upload
    ));

    React.useEffect(() => {
        getData().then(setData);
    }, []);

    const uploadColumns = [
        {
            field: 'date',
            headerName: formatMessage({ defaultMessage: 'Date uploaded', id: 'newsstand.accessData.dateUploaded' }),
            flex: 1,
            sortable: false
        },
        {
            field: 'name',
            headerName: formatMessage({ defaultMessage: 'Name', id: 'newsstand.accessData.fileName' }),
            flex: 1,
            sortable: false
        },
        {
            field: 'entries',
            headerName: formatMessage({ defaultMessage: 'Line entries', id: 'newsstand.accessData.lineEntries' }),
            flex: 0.7,
            sortable: false,
            headerAlign: 'right',
            align: 'right'
        },
        {
            field: 'actions',
            headerName: '',
            flex: 0.6,
            sortable: false,
            align: 'center',
            renderCell: (entry) => (
                entry.id === latestUpload?.id && (
                    <Chip
                        color='success'
                        label={formatMessage({ defaultMessage: 'Live', id: 'common.live' })}
                        size='small'
                        variant='outlined'
                    />
                )
            )
        }
    ];

    return (
        <Grid className='alternate-rows' container direction='column' spacing={3} sx={{ px: 2 }} xs={12}>
            <Grid container>
                <Grid xs={4}>
                    <Typography variant='sectionHeader'>
                        <FormattedMessage
                            defaultMessage='Subscriber data upload'
                            id='newsstand.accessData.accessDataUpload'
                        />
                    </Typography>
                </Grid>
                <Grid xs={8}>
                    <Box display='flex'>
                        <FileButton
                            accept={['.xlsx', '.csv']}
                            handleUpload={(fileName, fileData) => (
                                submitData(fileName, fileData).then(getData).then(setData)
                            )}
                            size='large'
                            startIcon={<UploadIcon />}
                            sx={{ mr: 3 }}
                            variant='outlined'
                        >
                            <FormattedMessage
                                defaultMessage='Upload'
                                id='common.upload'
                            />
                        </FileButton>
                        <Typography variant='blockSubtitle'>
                            <FormattedMessage
                                defaultMessage='File format: *.xlsx, *.csv'
                                id='newsstand.accessData.uploadFile.allowedTypes'
                            />
                            <br />
                            <FormattedMessage
                                defaultMessage='First row is assumed to be a header-row'
                                id='newsstand.accessData.uploadFile.headerRow'
                            />
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <Grid container sx={{ py: 3, my: 1 }}>
                <Grid xs={4}>
                    <Typography variant='sectionHeader'>
                        <FormattedMessage
                            defaultMessage='Recent uploads'
                            id='newsstand.accessData.recentUploads'
                        />
                    </Typography>
                </Grid>
                <Grid container sx={{ px: 3 }} xs={8}>
                    <DataGrid
                        columns={uploadColumns}
                        disableColumnMenu
                        hideFooter
                        initialState={{
                            sorting: {
                                sortModel: [{ field: 'date', sort: 'desc' }],
                            },
                        }}
                        loading={!data}
                        rows={data?.uploads || []}
                        sx={{
                            border: 'none',
                            '& .MuiDataGrid-columnHeaders': {
                                borderRadius: 1
                            },
                            '& .MuiDataGrid-columnHeader': {
                                backgroundColor: 'common.white'
                            },
                            '& .MuiDataGrid-row': {
                                '&:nth-of-type(even)': {
                                    backgroundColor: 'common.white'
                                }
                            },
                            '& .MuiDataGrid-cell': {
                                borderBottom: 'none'
                            },
                            '& .MuiDataGrid-columnHeader:focus': {
                                outline: 'none',
                            },
                            '& .MuiDataGrid-cell:focus': {
                                outline: 'none',
                            },
                            maxHeight: 300
                        }}
                    />
                </Grid>
            </Grid>
            {/* TODO: The commented code at Jason's request */}
            {/* <Grid container> */}
            {/*     <Grid xs={4}> */}
            {/*         <Typography variant='sectionHeader'> */}
            {/*             <FormattedMessage */}
            {/*                 defaultMessage='Download access list' */}
            {/*                 id='newsstand.accessData.registrationEmailDownload' */}
            {/*             /> */}
            {/*         </Typography> */}
            {/*     </Grid> */}
            {/*     <Grid xs={8}> */}
            {/*         <Button */}
            {/*             onClick={() => downloadData()} */}
            {/*             size='large' */}
            {/*             startIcon={<DownloadIcon />} */}
            {/*             variant='outlined' */}
            {/*         > */}
            {/*             <FormattedMessage */}
            {/*                 defaultMessage='Download' */}
            {/*                 id='common.download' */}
            {/*             /> */}
            {/*         </Button> */}
            {/*     </Grid> */}
            {/* </Grid> */}
            <Grid container>
                <Grid xs={4}>
                    <Typography variant='sectionHeader'>
                        <FormattedMessage
                            defaultMessage='Download registrations'
                            id='newsstand.accessData.downloadRegistrations'
                        />
                    </Typography>
                </Grid>
                <Grid xs={8}>
                    <Button
                        onClick={() => downloadRegistrations()}
                        size='large'
                        startIcon={<DownloadIcon />}
                        variant='outlined'
                    >
                        <FormattedMessage
                            defaultMessage='Download'
                            id='common.download'
                        />
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};
AccessData.propTypes = {
    downloadRegistrations: PropTypes.func.isRequired,
    getData: PropTypes.func.isRequired,
    submitData: PropTypes.func.isRequired,
};
export default AccessData;
