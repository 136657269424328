import React from 'react';
import ReactDOM from 'react-dom/client';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import {
    RouterProvider,
    createBrowserRouter,
} from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';

import '@fontsource/montserrat/300.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/montserrat/800.css';

import { IntlProvider } from 'react-intl';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { getLocale, getTranslations } from './i18n/i18n-config';

import defaultTheme from './themes/default';

import AppContainer from './AppContainer';
import ErrorPage from './errorPage';
import LoginPage from './routes/Login';
import ForgotPasswordPage from './routes/ForgotPassword';
import LogoutPage from './routes/Logout';
import Dashboard from './routes/Dashboard';
import Account from './routes/Account';
import Newsstand from './routes/Newsstand';
import Campaigns from './routes/Campaigns';
import CampaignsAdd from './routes/campaigns/Add';
import CampaignsView from './routes/campaigns/View';
import CampaignsEdit from './routes/campaigns/Edit';
import Settings from './routes/newsstand/Settings';
import Layout from './routes/newsstand/Layout';
import Publications from './routes/newsstand/Publications';
import Supplements from './routes/newsstand/Supplements';
import Adverts from './routes/Adverts';
import AdvertsAdd from './routes/adverts/Add';
import AdvertsEdit from './routes/adverts/Edit';
import Highlights from './routes/Highlights';
import HighlightsAdd from './routes/highlights/Add';
import HighlightsEdit from './routes/highlights/Edit';
import Analytics from './routes/Analytics';

import { AuthProvider, RequireAuth } from './contexts/Auth';
import { SortFilterSearchProvider } from './contexts/SortFilterSearch';
import { AccountInfoProvider } from './contexts/AccountInfo';

import { DialogProvider } from './contexts/Dialog';
import { getUserLocale, setDayjsLocale } from './utils/dayjsLocale';

const router = createBrowserRouter([
    {
        key: 'root',
        path: '/',
        Component: AppContainer,
        errorElement: <ErrorPage />,
        children: [
            {
                index: true,
                element: (
                    <RequireAuth>
                        <Dashboard />
                    </RequireAuth>
                ),
            },
            {

                path: 'login',
                Component: LoginPage,
            },
            {
                path: 'forgot-password',
                Component: ForgotPasswordPage,
            },
            {
                path: 'analytics',
                element: (
                    <RequireAuth>
                        <Analytics />
                    </RequireAuth>
                ),
            },
            {
                path: 'account',
                element: (
                    <RequireAuth>
                        <Account />
                    </RequireAuth>
                ),
            },
            {
                path: 'newsstand',
                element: (
                    <RequireAuth>
                        <Newsstand />
                    </RequireAuth>
                ),
            },
            {
                path: 'newsstand/settings',
                element: (
                    <RequireAuth>
                        <Settings />
                    </RequireAuth>
                ),
            },
            {
                path: 'newsstand/layout',
                element: (
                    <RequireAuth>
                        <Layout />
                    </RequireAuth>
                ),
            },
            {
                path: 'newsstand/publications',
                element: (
                    <RequireAuth>
                        <Publications />
                    </RequireAuth>
                ),
            },
            {
                path: 'newsstand/supplements',
                element: (
                    <RequireAuth>
                        <Supplements />
                    </RequireAuth>
                ),
            },
            {
                path: 'campaigns',
                element: (
                    <RequireAuth>
                        <Campaigns />
                    </RequireAuth>
                ),
            },
            {
                path: 'campaigns/add',
                element: (
                    <RequireAuth>
                        <CampaignsAdd />
                    </RequireAuth>
                ),
            },
            {
                path: 'campaigns/view/:id',
                element: (
                    <RequireAuth>
                        <CampaignsView />
                    </RequireAuth>
                ),
            },
            {
                path: 'campaigns/edit/:id',
                element: (
                    <RequireAuth>
                        <CampaignsEdit />
                    </RequireAuth>
                ),
            },
            {
                path: 'highlights',
                element: (
                    <RequireAuth>
                        <Highlights />
                    </RequireAuth>
                )
            },
            {
                path: 'highlights/add',
                element: (
                    <RequireAuth>
                        <HighlightsAdd />
                    </RequireAuth>
                )
            },
            {
                path: 'highlights/edit/:id',
                element: (
                    <RequireAuth>
                        <HighlightsEdit />
                    </RequireAuth>
                )
            },
            {
                path: 'adverts',
                element: (
                    <RequireAuth>
                        <Adverts />
                    </RequireAuth>
                )
            },
            {
                path: 'adverts/add',
                element: (
                    <RequireAuth>
                        <AdvertsAdd />
                    </RequireAuth>
                )
            },
            {
                path: 'adverts/edit/:id',
                element: (
                    <RequireAuth>
                        <AdvertsEdit />
                    </RequireAuth>
                )
            },
            {
                path: 'logout',
                element: (
                    <RequireAuth>
                        <LogoutPage />
                    </RequireAuth>
                ),
            },
        ],
    },
], { basename: '/publishers' });

const root = ReactDOM.createRoot(document.getElementById('root'));
const localeToUse = getLocale(getUserLocale());
const isDevelopment = process.env.REACT_APP_NODE_ENV === 'development';
const isProduction = process.env.REACT_APP_NODE_ENV === 'production';
const dayjsUserLocale = setDayjsLocale();

if (isProduction) {
    // Disable react dev tools
    disableReactDevTools();
}

/**
 * While developing it can be annoying to see the missing translation errors. This can be toggled on and off.
 * @type {boolean}
 */
const showIntlErrors = false;
const ignoreTranslationError = (error) => {
    // Only show errors if the showIntlErrors is true or if the error is not a missing translation error.
    if (showIntlErrors === true || typeof error.message !== 'string' || !error.code.includes('MISSING_TRANSLATION')) {
        console.error(error);
    }
};

const App = (
    <IntlProvider locale={localeToUse} messages={getTranslations(localeToUse)} onError={ignoreTranslationError}>
        <LocalizationProvider adapterLocale={dayjsUserLocale} dateAdapter={AdapterDayjs}>
            <ThemeProvider theme={defaultTheme}>
                <DialogProvider>
                    <SortFilterSearchProvider>
                        <AuthProvider>
                            <AccountInfoProvider>
                                <RouterProvider router={router} />
                            </AccountInfoProvider>
                        </AuthProvider>
                    </SortFilterSearchProvider>
                </DialogProvider>
            </ThemeProvider>
        </LocalizationProvider>
    </IntlProvider>
);

if (isDevelopment) {
    root.render(
        <React.StrictMode>
            {App}
        </React.StrictMode>
    );
} else {
    root.render(
        App
    );
}
