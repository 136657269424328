import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm, TextFieldElement as TextField, FormContainer } from 'react-hook-form-mui';
import {
    Unstable_Grid2 as Grid,
    Typography
} from '@mui/material';

import RequestButton from '../components/RequestButton';
import { useAuth } from '../contexts/Auth';
import { ReactComponent as IdIcon } from '../assets/correspondenceIcon.svg';
import { ReactComponent as ClientIcon } from '../assets/clientIcon.svg';
import { ReactComponent as LockIcon } from '../assets/lockIcon.svg';
import ApiUtils from './base/Api';
import { getSvgComponent } from '../utils/utils';

// TODO remove disable eslint after development
/* eslint-disable no-unused-vars */
const Account = (props) => {
    const authContext = useAuth();
    const intlContext = useIntl();
    const { formatMessage } = intlContext;
    const { Api } = ApiUtils.useApi(authContext, intlContext);
    const hiddenData = {};

    // The page uses a different title than the item in the menu, so we need to declare it here to get it extracted
    formatMessage({ defaultMessage: 'Account settings', id: 'pages.accountSettings' });

    const submitData = (data) => {
        const toUpdate = {};
        toUpdate.id = hiddenData.clientno;
        toUpdate.contactId = hiddenData.contactId;
        toUpdate.businessNumber = data.businessNumber;
        toUpdate.vatNumber = data.vatNumber;
        toUpdate.firstName = data.firstName;
        toUpdate.lastName = data.lastName;
        toUpdate.email = data.email;
        toUpdate.company = data.company;

        if (data.currentPassword && data.newPassword) {
            toUpdate.oldPassword = data.currentPassword;
            toUpdate.password = data.newPassword;
        }

        return Api.updateAccount(toUpdate);
    };

    const retrieveData = async () => {
        // TODO: Get the real data
        // const fakeData = {
        //     firstName: 'Rik',
        //     lastName: 'Smit',
        //     email: 'rik@upinion.com',
        //     clientno: 12345,
        //     company: 'Upinion',
        //     businessNumber: '+3161234567',
        //     vatNumber: 'NL123456789B01'
        // };
        // return fakeData;

        const response = await Api.getAccount();
        if (response.success && response.relation) {
            const { relation } = response;
            const { id: clientno, businessNumber, vatNumber } = relation;
            let firstName = '';
            let lastName = '';
            let email = '';
            let company = '';
            if (relation.addresses && relation.addresses.correspondence) {
                const { correspondence } = relation.addresses;
                firstName = correspondence.firstName;
                lastName = correspondence.lastName;
                email = correspondence.email;
                hiddenData.contactId = correspondence.contactId;
            }
            if (relation.names) {
                const { names } = relation;
                company = names.company;
            }
            hiddenData.clientno = clientno;
            return {
                firstName,
                lastName,
                email,
                clientno,
                company,
                businessNumber,
                vatNumber
            };
        }
        console.error('Error retrieving account data');
        return null;
    };

    return (
        <div id='account'>
            <AccountView
                retrieveData={retrieveData}
                submitData={submitData}
            />
        </div>
    );
};

const AccountView = (props) => {
    const { submitData, retrieveData } = props;
    const { formatMessage } = useIntl();
    const {
        control,
        formContext,
        reset,
        handleSubmit
    } = useForm({ defaultValues: async () => (retrieveData()) });

    const submitDataSuccess = async () => {
        const newData = await retrieveData();
        reset(newData);
    };

    return (
        <FormContainer formContext={formContext}>
            <Grid className='alternate-rows' container spacing={2} sx={{ paddingLeft: 2, paddingRight: 2 }}>
                <Grid container sx={{ m: 1 }} xs={12}>
                    <Grid xs={3}>
                        <Typography sx={{ display: 'flex', alignItems: 'center' }} variant='sectionHeader'>
                            {getSvgComponent(
                                IdIcon,
                                { marginRight: 2 },
                                { viewBox: '0 0 22 18' }
                            )}
                            <FormattedMessage
                                defaultMessage='Correspondence'
                                id='account.correspondence'
                            />
                        </Typography>
                    </Grid>
                    <Grid container spacing={4} xs={9}>
                        <Grid xs={6}>
                            <TextField
                                control={control}
                                label={formatMessage({ defaultMessage: 'First name', id: 'account.firstName' })}
                                name='firstName'
                                required
                                type='text'
                            />
                        </Grid>
                        <Grid xs={6}>
                            <TextField
                                control={control}
                                label={formatMessage({ defaultMessage: 'Last name', id: 'account.lastName' })}
                                name='lastName'
                                required
                                type='text'
                            />
                        </Grid>
                        <Grid xs={6}>
                            <TextField
                                control={control}
                                label={formatMessage({ defaultMessage: 'Email', id: 'account.email' })}
                                name='email'
                                required
                                type='email'
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container sx={{ m: 1 }} xs={12}>
                    <Grid xs={3}>
                        <Typography sx={{ display: 'flex', alignItems: 'center' }} variant='sectionHeader'>
                            {getSvgComponent(
                                ClientIcon,
                                { marginRight: 2 },
                                { viewBox: '0 0 22 20' }
                            )}
                            <FormattedMessage
                                defaultMessage='Client'
                                id='account.client'
                            />
                        </Typography>
                    </Grid>
                    <Grid container spacing={4} xs={9}>
                        <Grid xs={12}>
                            <TextField
                                control={control}
                                InputProps={{ disableUnderline: true, readOnly: true }}
                                label={formatMessage({ defaultMessage: 'Client no.', id: 'account.clientno' })}
                                name='clientno'
                                type='text'
                                variant='standard'
                            />
                        </Grid>
                        <Grid xs={12}>
                            <TextField
                                control={control}
                                label={formatMessage({ defaultMessage: 'Company', id: 'account.company' })}
                                name='company'
                                required
                                type='text'
                            />
                        </Grid>
                        <Grid xs={6}>
                            <TextField
                                control={control}
                                label={formatMessage({
                                    defaultMessage: 'Business number',
                                    id: 'account.businessNumber'
                                })}
                                name='businessNumber'
                                required
                                type='text'
                            />
                        </Grid>
                        <Grid xs={6}>
                            <TextField
                                control={control}
                                label={formatMessage({ defaultMessage: 'VAT number', id: 'account.vatNumber' })}
                                name='vatNumber'
                                required
                                type='text'
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container sx={{ m: 1 }} xs={12}>
                    <Grid xs={3}>
                        <Typography sx={{ display: 'flex', alignItems: 'center' }} variant='sectionHeader'>
                            {getSvgComponent(
                                LockIcon,
                                { marginRight: 2 },
                                { viewBox: '0 0 16 20' }
                            )}
                            <FormattedMessage
                                defaultMessage='Change password'
                                id='account.changePassword'
                            />
                        </Typography>
                    </Grid>
                    <Grid container spacing={4} xs={9}>
                        <Grid container xs={12}>
                            <Grid xs={6}>
                                <TextField
                                    control={control}
                                    label={formatMessage({
                                        defaultMessage: 'Current password',
                                        id: 'account.currentPassword'
                                    })}
                                    name='currentPassword'
                                    type='password'
                                    validation={{
                                        validate: (value, values) => !!value
                                            === (!!values.newPassword || !!values.newPassword2)
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container xs={12}>
                            <Grid xs={6}>
                                <TextField
                                    control={control}
                                    label={formatMessage({
                                        defaultMessage: 'New password',
                                        id: 'account.newPassword'
                                    })}
                                    name='newPassword'
                                    type='password'
                                    validation={{
                                        validate: (value, values) => value === values.newPassword2
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container xs={12}>
                            <Grid xs={6}>
                                <TextField
                                    control={control}
                                    label={formatMessage({
                                        defaultMessage: 'Confirm new password',
                                        id: 'account.newPasswordConfirm'
                                    })}
                                    name='newPassword2'
                                    type='password'
                                    validation={{
                                        validate: (value, values) => value === values.newPassword
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid sx={{ padding: 2, display: 'flex', justifyContent: 'flex-end' }} xs={12}>
                    <RequestButton
                        injectFormData={handleSubmit}
                        onSuccess={submitDataSuccess}
                        sendData={submitData}
                        size='large'
                        type='submit'
                        variant='contained'
                    >
                        <FormattedMessage defaultMessage='Update' id='common.update' />
                    </RequestButton>
                </Grid>
            </Grid>
        </FormContainer>
    );
};
AccountView.propTypes = {
    retrieveData: PropTypes.func.isRequired,
    submitData: PropTypes.func.isRequired,
};

export default Account;
