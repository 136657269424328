import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { groupListByParent } from '../../utils/utils';
import { useApi, api } from '../base/Request';

import HighlightsFormView from './FormView';

export default function HighlightsAdd() {
    const [publicationArticles, setPublicationArticles] = useState({});

    const { request } = useApi();
    const navigate = useNavigate();

    const submitData = async (data) => {
        try {
            const response = await request(api.createHighlight).send({
                dateStart: data.startDate?.unix(),
                dateFinal: data.endDate?.unix(),
                imageData: data.highlightImage,
                highlightTitle1: data.title,
                highlightTitle2: data.subtitle,
                highlightTitle3: data.tagline,
                isLocked: data.lockHighlight,
                linkedArticleId: data?.linkedArticle ?? null,
                linkedPage: data?.linkedPage ?? null,
                isHighlight: true,
            });

            if (response?.success) {
                navigate('/highlights');
            }
        } catch (err) {
            console.error(err);
        }

        return null;
    };

    const retrievePublicationArticles = async () => {
        const response = await request(api.getPublicationArticles).send();

        setPublicationArticles(
            groupListByParent(response?.publicationArticles, 'rootName')
        );
    };

    useEffect(() => {
        retrievePublicationArticles();
    }, []);

    return (
        <HighlightsFormView
            isUpdate={false}
            publicationArticles={publicationArticles}
            submitData={submitData}
        />
    );
}
