import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FormattedMessage, useIntl } from 'react-intl';
import { copyImageToClipboard } from 'copy-image-clipboard';
import {
    Button, TextField, Unstable_Grid2 as Grid, Snackbar, Link
} from '@mui/material';
import { ContentCopy as CopyIcon } from '@mui/icons-material';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import DefaultDialog from './DefaultDialog';

export default function QrModal({ show, closeHandler, url }) {
    const qrImage = `https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${encodeURIComponent(url)}`;
    const { formatMessage } = useIntl();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const copyQrImage = async () => {
        await copyImageToClipboard(qrImage);
        setSnackbarOpen(true);
    };

    return (
        <DefaultDialog
            closeHandler={closeHandler}
            show={show}
            size='modalMd'
            title={formatMessage({ defaultMessage: 'QR code and URL', id: 'phone.modal.title' })}
        >
            <Grid alignItems='center' columnSpacing={3} container rowSpacing={2.5}>
                <Grid alignItems='center' container xs={12}>
                    <Grid display='flex' justifyContent='center' xs={5}>
                        <Link href={url} rel='noopener noreferrer' target='_blank'>
                            <img alt='QR code' src={qrImage} style={{ cursor: 'pointer' }} />
                        </Link>
                    </Grid>
                    <Grid display='flex' justifyContent='center' xs={7}>
                        <TextField
                            color='secondary'
                            defaultValue={url}
                            disabled
                            fullWidth
                            multiline
                            variant='outlined'
                        />
                    </Grid>
                </Grid>
                <Grid alignItems='center' container xs={12}>
                    <Grid display='flex' justifyContent='center' xs={5}>
                        <Button onClick={copyQrImage} size='large' startIcon={<CopyIcon />} variant='outlined'>
                            <FormattedMessage defaultMessage='Copy QR Code' id='phone.modal.copyQrButton' />
                        </Button>
                    </Grid>
                    <Grid display='flex' justifyContent='center' xs={7}>
                        <CopyToClipboard text={url}>
                            <Button size='large' startIcon={<CopyIcon />} variant='outlined'>
                                <FormattedMessage defaultMessage='Copy Link' id='phone.modal.copyLinkButton' />
                            </Button>
                        </CopyToClipboard>
                    </Grid>
                </Grid>
            </Grid>
            <Snackbar
                autoHideDuration={3000}
                message={formatMessage(
                    { defaultMessage: 'QR code copied!', id: 'phone.modal.snackbarMessage' })}
                onClose={handleSnackbarClose}
                open={snackbarOpen}
            />
        </DefaultDialog>
    );
}

QrModal.propTypes = {
    closeHandler: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    url: PropTypes.string.isRequired,
};
