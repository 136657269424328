import React, {
    createContext,
    useContext,
    useMemo,
    useState
} from 'react';

import PropTypes from 'prop-types';

const SortFilterSearchContext = createContext(null);

function SortFilterSearchProvider({ children }) {
    const [filterData, setFilterData] = useState({});
    const [searchData, setSearchData] = useState({});
    const [sortData, setSortData] = useState({});

    const getFilter = (key) => filterData[key];
    const getSearch = (key) => searchData[key];
    const getSort = (key) => sortData[key];

    const setFilter = (key, subkey, value) => {
        setFilterData({ ...filterData, [key]: { ...filterData[key], [subkey]: value } });
    };

    const setSearch = (key, subkey, value) => {
        setSearchData({ ...searchData, [key]: { ...searchData[key], [subkey]: value } });
    };

    const setSort = (key, subkey, value) => {
        setSortData({ ...sortData, [key]: { ...sortData[key], [subkey]: value } });
    };

    const cleanFilter = (key) => {
        const { [key]: _, ...rest } = filterData;
        setFilterData(rest);
    };

    const cleanSearch = (key) => {
        const { [key]: _, ...rest } = searchData;
        setSearchData(rest);
    };

    const cleanSort = (key) => {
        const { [key]: _, ...rest } = sortData;
        setSortData(rest);
    };

    const clearAllFilters = (key) => {
        cleanFilter(key);
        cleanSearch(key);
        cleanSort(key);
    };

    const value = useMemo(
        () => ((key) => ({
            filter: getFilter(key),
            search: getSearch(key),
            sort: getSort(key),
            setFilter: (subkey, val) => setFilter(key, subkey, val),
            setSearch: (subkey, val) => setSearch(key, subkey, val),
            setSort: (subkey, val) => setSort(key, subkey, val),
            cleanFilter: () => cleanFilter(key),
            cleanSearch: () => cleanSearch(key),
            cleanSort: () => cleanSort(key),
            clearAllFilters: () => clearAllFilters(key),
        })),
        [filterData, searchData, sortData]
    );

    return (
        <SortFilterSearchContext.Provider value={value}>
            {children}
        </SortFilterSearchContext.Provider>
    );
}

SortFilterSearchProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

function useSortFilterSearch(id) {
    const context = useContext(SortFilterSearchContext);

    if (!context) {
        throw new Error('useSortFilterSearch must be in SortFilterSearchContext.Provider');
    }

    return context(id);
}

export { SortFilterSearchContext, SortFilterSearchProvider, useSortFilterSearch };
