import enMessages from './translations/en-GB.json';
import nlMessages from './translations/nl-NL.json';

// We'll use the English-USA locale when
// our app loads. It will also be used as
// a fallback when there's a missing
// translation in another locale.
export const defaultLocale = 'en-GB';

// The locales our app supports. We'll work
// with English-USA and Arabic-Egypt here.
// Feel free to add any locales you want.
export const locales = {
    'en-GB': {
        name: 'English',
        messages: { ...enMessages },
        dir: 'ltr'
    },
    'en-US': {
        name: 'English',
        messages: { ...enMessages },
        dir: 'ltr'
    },
    'nl-NL': {
        name: 'Nederlands',
        messages: { ...nlMessages },
        dir: 'ltr'
    },
};

export const getLocale = (preferredLocale) => {
    if (locales.hasOwnProperty(preferredLocale)) return preferredLocale;

    // Check the key with the country  code as the language, for example nl -> nl-NL
    const doubleLocale = `${preferredLocale}-${preferredLocale.toUpperCase()}`;
    if (locales.hasOwnProperty(doubleLocale)) return doubleLocale;

    return defaultLocale;
};

export const getTranslations = (locale) => {
    const localeWithFallback = getLocale(locale);
    return locales[localeWithFallback].messages;
};
