import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
    Link,
    Box,
    Toolbar,
    List,
    ListItem,
    ListItemButton,
    Drawer,
    ListItemIcon,
    ListItemText,
} from '@mui/material';

import {
    GridViewOutlined as DashboardIcon,
    AccountCircleOutlined as AccountIcon,
    TableChartOutlined as NewsstandIcon,
    CampaignOutlined as AdvertsIcon,
    ArticleOutlined as ArticlesIcon,
    LoginOutlined as LoginIcon,
    LogoutOutlined as LogoutIcon,
    RestartAltOutlined as PasswordResetIcon,
    AssessmentOutlined as AnalyticsIcon,
} from '@mui/icons-material';

import { FormattedMessage } from 'react-intl';
import { NavLink, useLocation } from 'react-router-dom';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import { api, useApi } from '../routes/base/Request';
import { ReactComponent as CampaignsIcon } from '../assets/campaignsIcon.svg';

import { useAuth } from '../contexts/Auth';
import { getMainPageName, getPageName } from '../utils/routing';
import { getSvgComponent, truncate } from '../utils/utils';

import logoImage from '../assets/logo.png';
import menuTheme from '../themes/menu';

const MenuItem = (props) => {
    const {
        icon,
        target,
        children,
        linkSx,
        buttonSx
    } = props;

    const title = getPageName(target);
    const theme = useTheme();

    const getIcon = (pageActive = false) => {
        const color = pageActive ? 'primary' : 'secondary';
        const themeColor = (pageActive ? 'colorPrimary' : 'colorSecondary');

        switch (title) {
            case 'Dashboard':
                return <DashboardIcon color={color} />;
            case 'Login':
                return <LoginIcon color={color} />;
            case 'Forgot Password':
                return <PasswordResetIcon color={color} />;
            case 'Account Settings':
                return <AccountIcon color={color} />;
            case 'Newsstand':
                return <NewsstandIcon color={color} />;
            case 'Campaigns':
                return getSvgComponent(
                    CampaignsIcon,
                    { ml: 0.5, color: theme.components.MuiSvgIcon.styleOverrides[themeColor].color },
                    { viewBox: '0 0 22 18' }
                );
            case 'Adverts':
                return <AdvertsIcon color={color} />;
            case 'Highlights':
                return <ArticlesIcon color={color} />;
            case 'Analytics':
                return <AnalyticsIcon color={color} />;
            case 'Logout':
                return <LogoutIcon color={color} />;
            default:
                return null;
        }
    };

    return (
        <Link
            component={NavLink}
            sx={{ ...linkSx }}
            to={target}
            underline='none'
        >
            {({ isActive }) => (
                <ListItem key={target}>
                    <ListItemButton selected={isActive} sx={{ ...buttonSx }}>
                        <ListItemIcon>
                            {icon || getIcon(isActive)}
                        </ListItemIcon>
                        <ListItemText primaryTypographyProps={{ variant: 'menuItem' }}>
                            {children}
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
            )}
        </Link>
    );
};

MenuItem.propTypes = {
    buttonSx: PropTypes.object,
    children: PropTypes.node.isRequired,
    icon: PropTypes.element,
    linkSx: PropTypes.object,
    target: PropTypes.string.isRequired,
};

MenuItem.defaultProps = {
    icon: null,
    linkSx: {},
    buttonSx: {}
};

const SubMenuItem = ({ target, children }) => (
    <NavLink end to={target}>
        {({ isActive }) => (
            <ListItem key={target} sx={{ height: 40 }}>
                <ListItemButton selected={isActive} sx={{ height: 40 }} variant='subMenuItem'>
                    <ListItemText inset primaryTypographyProps={{ variant: 'subMenuItem' }}>
                        {children}
                    </ListItemText>
                </ListItemButton>
            </ListItem>
        )}
    </NavLink>
);

SubMenuItem.propTypes = {
    children: PropTypes.object.isRequired,
    target: PropTypes.string.isRequired,
};

export default function Menu() {
    const auth = useAuth();
    const { request } = useApi();

    const mainPage = getMainPageName(useLocation().pathname);

    const [username, setUsername] = useState(null);

    const retrieveAccountData = async () => {
        const response = await request(api.getAccount).send();

        setUsername(response?.relation?.addresses?.correspondence?.firstName ?? null);
    };

    useEffect(() => {
        if (auth.signedIn) {
            retrieveAccountData();
        }
    }, []);

    return (
        <ThemeProvider theme={menuTheme}>
            <Drawer
                anchor='left'
                sx={{
                    width: 248,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: 248
                    },
                }}
                variant='permanent'
            >
                <Link href='https://touchtree.tech/' rel='noopener noreferrer' target='_blank'>
                    <Toolbar>
                        <img alt='logo' id='logo-image' src={logoImage} />
                    </Toolbar>
                </Link>
                {auth.signedIn ? (
                    <List
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            height: '100%',
                            mt: 2
                        }}
                    >
                        <Box>
                            <MenuItem target='/'>
                                <FormattedMessage defaultMessage='Dashboard' id='pages.dashboard' />
                            </MenuItem>
                            <MenuItem target='/newsstand'>
                                <FormattedMessage defaultMessage='Newsstand' id='pages.newsstand' />
                            </MenuItem>
                            {mainPage === 'Newsstand' && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: '1px',
                                        flexDirection: 'column',
                                        mt: 1.25,
                                        mb: 1.25,
                                    }}
                                >
                                    <SubMenuItem target='/newsstand/settings'>
                                        <FormattedMessage
                                            defaultMessage='General settings'
                                            id='pages.generalSettings'
                                        />
                                    </SubMenuItem>
                                    <SubMenuItem target='/newsstand/layout'>
                                        <FormattedMessage defaultMessage='Visual layout' id='pages.visualLayout' />
                                    </SubMenuItem>
                                    <SubMenuItem target='/newsstand/publications'>
                                        <FormattedMessage defaultMessage='Publications' id='pages.publications' />
                                    </SubMenuItem>
                                    <SubMenuItem target='/newsstand/supplements'>
                                        <FormattedMessage defaultMessage='Supplements' id='pages.supplements' />
                                    </SubMenuItem>
                                </Box>
                            )}
                            <MenuItem target='/campaigns'>
                                <FormattedMessage defaultMessage='Campaigns' id='pages.campaigns' />
                            </MenuItem>
                            <MenuItem target='/adverts'>
                                <FormattedMessage defaultMessage='Adverts' id='pages.adverts' />
                            </MenuItem>
                            <MenuItem target='/highlights'>
                                <FormattedMessage defaultMessage='Highlights' id='pages.highlights' />
                            </MenuItem>
                            <MenuItem target='/analytics'>
                                <FormattedMessage defaultMessage='Analytics' id='pages.analytics' />
                            </MenuItem>
                        </Box>
                        <Box>
                            <MenuItem target='/account'>
                                {username
                                    ? truncate(username.split(' ')[0], 13)
                                    : (
                                        <FormattedMessage defaultMessage='Account' id='pages.account' />
                                    )}
                            </MenuItem>
                            <MenuItem target='/logout'>
                                <FormattedMessage defaultMessage='Logout' id='pages.logout' />
                            </MenuItem>
                        </Box>
                    </List>
                ) : (
                    <List>
                        <MenuItem target='/login'>
                            <FormattedMessage defaultMessage='Login' id='pages.login' />
                        </MenuItem>
                        <MenuItem target='/forgot-password'>
                            <FormattedMessage defaultMessage='Forgot Password' id='forgotPassword.resetPassword' />
                        </MenuItem>
                    </List>
                )}
            </Drawer>
        </ThemeProvider>
    );
}
