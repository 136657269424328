import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { CloudUploadOutlined as UploadFileIcon } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { Dropzone } from '@files-ui/react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import useDimension from '../hooks/useDimension';
import { resizeImageIfNeeded } from '../utils/image';

export default function ImageDropzone({
    disabled, value, name, handleUpload, maxFileSize, minimumImageSize, style, previewStyle
}) {
    const [imageDataUrl, setImageDataUrl] = useState(null);
    const divRef = useRef(null);
    const theme = useTheme();

    const { height, width } = useDimension(divRef);

    useEffect(() => {
        if (!imageDataUrl) return;

        const resizeAndSendImage = async () => {
            const resizedImage = await resizeImageIfNeeded(imageDataUrl);
            handleUpload(name, resizedImage);
            setImageDataUrl(null);
        };

        resizeAndSendImage();
    }, [imageDataUrl]);

    if (value) {
        return (
            <Box
                ref={divRef}
                className='dropzone-image'
                sx={{
                    border: `1px dashed ${theme.palette.border.imageDropzone}`,
                    borderRadius: previewStyle.borderRadius === 1 ? 1 : null,
                    display: 'flex',
                    position: 'relative',
                    width: '100%',
                    ...previewStyle
                }}
            >
                <div style={{ height, width }} />
                <img
                    alt={`dropzone ${name}`}
                    src={value}
                    style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: previewStyle.borderRadius === 1 ? 8 : null,
                    }}
                />
            </Box>
        );
    }

    const onFileSelected = (files) => {
        const selectedFile = files[0].file;
        const reader = new FileReader();

        reader.readAsDataURL(selectedFile);

        reader.onload = (event) => {
            setImageDataUrl(event.target.result);
        };
    };

    return (
        <Box ref={divRef} sx={{ ...style }}>
            <Dropzone
                accept='image/png, image/jpeg'
                background={theme.palette.background.lightCyan}
                color={theme.palette.border.imageDropzone}
                disabled={disabled}
                footer={false}
                header={false}
                label='Upload a file'
                onChange={onFileSelected}
                style={{
                    alignSelf: 'flex-start',
                    width: '100%',
                    height: 200,
                    paddingLeft: 8,
                    paddingRight: 8,
                    borderRadius: previewStyle.borderRadius === 0 ? 0 : null
                }}
            >
                <Grid
                    alignItems='center'
                    container
                    direction='column'
                >
                    <UploadFileIcon
                        sx={{
                            color: 'text.light',
                            height: 38,
                            width: 38
                        }}
                    />
                    <span>
                        <Typography sx={{ color: 'text.darkCharcoal' }} variant='small500'>
                            <FormattedMessage
                                defaultMessage='Drag an image here or '
                                id='fileInput.dragImage'
                            />
                            <Typography
                                component='span'
                                sx={{
                                    color: 'primary.main', cursor: 'pointer'
                                }}
                                variant='small600'
                            >
                                <FormattedMessage
                                    defaultMessage='upload a file'
                                    id='fileInput.uploadFile'
                                />
                            </Typography>
                            .
                        </Typography>
                    </span>
                    <Typography sx={{ color: 'text.darkCharcoal', mt: 1.5 }} variant='smaller400'>
                        <FormattedMessage
                            defaultMessage='Max {fileSize}MB. '
                            id='fileInput.maxFileSize'
                            values={{
                                fileSize: maxFileSize
                            }}
                        />
                        {minimumImageSize && (
                            <FormattedMessage
                                defaultMessage='Min image size: {width}x{height}px'
                                id='fileInput.minimumImageSize'
                                values={{
                                    width: minimumImageSize?.width,
                                    height: minimumImageSize?.height
                                }}
                            />
                        )}
                    </Typography>
                </Grid>
            </Dropzone>
        </Box>
    );
}

ImageDropzone.propTypes = {
    disabled: PropTypes.bool,
    handleUpload: PropTypes.func.isRequired,
    maxFileSize: PropTypes.number,
    minimumImageSize: PropTypes.object,
    name: PropTypes.string.isRequired,
    previewStyle: PropTypes.object,
    style: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

ImageDropzone.defaultProps = {
    disabled: false,
    value: null,
    maxFileSize: 5,
    minimumImageSize: null,
    previewStyle: {},
    style: {}
};
