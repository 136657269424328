import { FormContainer, TextFieldElement as TextField, useForm } from 'react-hook-form-mui';
import {
    List, ListItem, ListItemText, Typography, Unstable_Grid2 as Grid
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useApi, api } from './base/Request';
import { useDialog } from './base/Dialog';
import RequestButton from '../components/RequestButton';

export default function ForgotPassword() {
    const { request } = useApi();

    const handleSubmit = async (data) => {
        const apiRequest = await request(api.resetPassword)
            .send(data);

        return { hasError: !apiRequest?.success || false };
    };

    return (
        <ForgotPasswordView submitData={handleSubmit} />
    );
}

const PasswordResetMessage = () => (
    <Grid container>
        <Grid item xs={12}>
            <Typography variant='body1'>
                <FormattedMessage
                    defaultMessage='If you don&apos;t receive this email:'
                    id='forgotPassword.emailSentInstructions.emailNotReceived'
                />
            </Typography>
            <List sx={{ listStyleType: 'disc', pl: 4, pt: 0 }}>
                <ListItem sx={{ display: 'list-item', pl: 0, pb: 0 }}>
                    <ListItemText
                        primary={(
                            <FormattedMessage
                                defaultMessage='The email may not be valid.'
                                id='forgotPassword.emailSentInstructions.emailInvalid'
                            />
                        )}
                    />
                </ListItem>
                <ListItem sx={{ display: 'list-item', pl: 0, pt: 0 }}>
                    <ListItemText
                        primary={(
                            <FormattedMessage
                                defaultMessage='Check your spam folder.'
                                id='forgotPassword.emailSentInstructions.checkSpamFolder'
                            />
                        )}
                    />
                </ListItem>
            </List>
        </Grid>
    </Grid>
);

const ForgotPasswordView = ({ submitData }) => {
    const [isSuccess, setIsSuccess] = useState(false);
    const { createDialog } = useDialog();
    const navigate = useNavigate();
    const { formatMessage } = useIntl();
    const {
        control,
        formContext,
        reset,
        handleSubmit
    } = useForm();

    const submitDataWrapper = (response) => {
        const err = !response.hasError;
        setIsSuccess(err);
        reset();
        return err;
    };

    const validateDatabaseName = (value) => {
        const specialCharRegex = /[^a-zA-Z0-9]/;
        const messages = {
            empty: {
                defaultMessage: 'Database name cannot be empty',
                id: 'login.databaseEmpty'
            },
            specialChars: {
                defaultMessage: 'Database name cannot contain special characters',
                id: 'login.invalidDatabaseName'
            },
        };

        const trimmedValue = value.trim();

        if (trimmedValue === '') {
            return formatMessage(messages.empty);
        }

        if (specialCharRegex.test(trimmedValue)) {
            return formatMessage(messages.specialChars);
        }

        return true;
    };

    const validateEmail = (value) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (!emailRegex.test(value)) {
            return formatMessage({
                defaultMessage: 'Email is not in a valid format',
                id: 'login.invalidEmailFormat'
            });
        }

        return true;
    };

    useEffect(() => {
        if (isSuccess) {
            const confirmationDialog = createDialog({
                title: [
                    'We just sent you an email to reset your password.',
                    'forgotPassword.emailSentInstructions'
                ],
                body: <PasswordResetMessage />,
                disableCancelButton: true,
                confirmButtonText: ['Go to Login', 'forgotPassword.goToLogin']
            });
            confirmationDialog
                .onConfirm(() => {
                    setIsSuccess(false);
                    navigate('/', { replace: true });
                })
                .onDismissed(() => {
                    setIsSuccess(false);
                })
                .show();
        }
    }, [isSuccess]);

    return (
        <FormContainer formContext={formContext}>
            <Grid
                alignItems='center'
                container
                direction='column'
                justifyContent='center'
                spacing={2}
            >
                <Grid item pb={0.5} xs={6}>
                    <Typography
                        variant='h4'
                    >
                        {formatMessage({
                            defaultMessage: 'Account Recovery',
                            id: 'forgotPassword.accountRecovery'
                        })}
                    </Typography>
                </Grid>
                <Grid item pb={4} pt={0} xs={6}>
                    <Typography
                        variant='layoutOption'
                    >
                        {formatMessage({
                            defaultMessage: 'Provide the database name and the email'
                                + 'address associated with your account to recover your password.',
                            id: 'forgotPassword.description'
                        })}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        control={control}
                        fullWidth
                        label={formatMessage({
                            defaultMessage: 'Database',
                            id: 'login.database'
                        })}
                        name='databaseName'
                        required
                        validation={{
                            validate: validateDatabaseName
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        control={control}
                        fullWidth
                        label={formatMessage({
                            defaultMessage: 'Email',
                            id: 'login.email'
                        })}
                        name='email'
                        required
                        type='email'
                        validation={{ validate: validateEmail }}
                    />
                </Grid>
                <Grid
                    item
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        padding: 2
                    }}
                    xs={6}
                >
                    <RequestButton
                        injectFormData={handleSubmit}
                        responseValidator={submitDataWrapper}
                        sendData={submitData}
                        size='large'
                        type='submit'
                        variant='contained'
                    >
                        <FormattedMessage defaultMessage='Reset Password' id='forgotPassword.resetPassword' />
                    </RequestButton>
                </Grid>
            </Grid>
        </FormContainer>
    );
};

ForgotPasswordView.propTypes = {
    submitData: PropTypes.func.isRequired,
};
