import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    FormContainer, SelectElement, SwitchElement, TextFieldElement, useForm, useWatch
} from 'react-hook-form-mui';
import {
    Button, Typography, Unstable_Grid2 as Grid
} from '@mui/material';
import {
    DeleteOutlined as DeleteIcon, LinkOutlined as LinkIcon
} from '@mui/icons-material';

import { useAccountInfo } from '../../../contexts/AccountInfo';

import InputImageDropzone from '../../../components/InputImageDropzone';
import DefaultDialog from '../../../modules/DefaultDialog';
import Select from '../../../components/Select';
import RequestButton from '../../../components/RequestButton';
import { isValidUrl } from '../../../utils/utils';

/**
 * SwitchBox component
 * Custom form element where the children are only displayed when the switch is on
 *
 * @param {string} label
 * @param {string} name
 * @param {object} control
 * @param {boolean} switchedOn
 * @param {React.JSX.Element} children
 * @returns {React.JSX.Element}
 * @constructor
 */
const SwitchBox = ({
    label, name, control, children
}) => {
    const switchedOn = useWatch({ control, name });

    return (
        <Grid
            container
            direction='column'
            spacing={0}
            sx={{
                border: '1px solid',
                borderColor: 'border.listItem',
                borderRadius: 1.5,
                height: 'fit-content',
                padding: 2,
                mb: 4,
                pr: 0,
                '&:last-child': {
                    mb: 0
                }
            }}
            xs={12}
        >
            <Grid container justifyContent='space-between' sx={{ flexWrap: 'nowrap' }}>
                <Grid alignItems='center' display='flex' sx={{ px: 1 }}>
                    <Typography variant='medium500'>
                        {label}
                    </Typography>
                </Grid>
                <Grid sx={{ pr: 0 }}>
                    <SwitchElement
                        color='primary'
                        control={control}
                        label=''
                        name={name}
                    />
                </Grid>
            </Grid>
            {switchedOn && children}
        </Grid>
    );
};

SwitchBox.propTypes = {
    children: PropTypes.node,
    control: PropTypes.object.isRequired,
    label: PropTypes.node.isRequired,
    name: PropTypes.string.isRequired,
};

SwitchBox.defaultProps = {
    children: null,
};

/**
 * ActionButtons component that displays the action buttons for the image upload
 *
 * @param deleteUpload
 * @param editLink
 * @param errorLink
 * @param linkData
 * @param name
 * @returns {React.JSX.Element}
 * @constructor
 */
const ActionButtons = ({
    deleteUpload, editLink, errorLink, linkData, name
}) => (
    <Grid display='flex' justifyContent='center'>
        {/*
             <Button
                size='large'
                startIcon={<UploadIcon />}
                variant='onlyIcon'
            />
        */}
        <Button
            onClick={() => editLink(name, linkData)}
            size='large'
            startIcon={<LinkIcon sx={{ transform: 'rotate(-45deg)' }} />}
            sx={{ color: errorLink ? 'error.main' : 'primary.main' }}
            variant={linkData?.hasOwnProperty('type') ? 'iconWithCheck' : 'onlyIcon'}
        />
        <Button
            onClick={() => deleteUpload(name)}
            size='large'
            startIcon={<DeleteIcon />}
            variant='onlyIcon'
        />
    </Grid>
);

ActionButtons.propTypes = {
    deleteUpload: PropTypes.func.isRequired,
    editLink: PropTypes.func.isRequired,
    errorLink: PropTypes.bool,
    linkData: PropTypes.object,
    name: PropTypes.string.isRequired
};

ActionButtons.defaultProps = {
    errorLink: false, linkData: {}
};

/**
 * LinkModal component which holds the form with the link data
 * @param articles
 * @param closeHandler
 * @param values
 * @param documents
 * @param saveHandler
 * @param show
 * @returns {Element}
 * @constructor
 */
const LinkModal = ({
    publicationArticles, closeHandler, values, documents, saveHandler, show
}) => {
    const [options1, setOptions1] = React.useState([]);
    const [options2, setOptions2] = React.useState([]);
    const { formatMessage } = useIntl();
    const [docs, setDocs] = React.useState([]);

    useEffect(() => {
        setDocs(documents);
    }, [documents]);

    React.useEffect(() => {
        const opts1 = [];
        const opts2 = [];
        Object.keys(publicationArticles).forEach((key) => {
            const value = publicationArticles[key];
            opts1.push({
                key, value: key, groupHead: true,
            });
            value.forEach((pubArticle) => {
                opts1.push({
                    key: pubArticle.id, value: pubArticle.fullName,
                });
                const pages = Array.from(Array(pubArticle.quantityPages))
                    .map((_, i) => ({
                        key: i + 1, value: i + 1,
                    }));
                opts2[pubArticle.id] = pages;
            });
        });
        setOptions1(opts1);
        setOptions2(opts2);
    }, [publicationArticles]);

    const linkTypes = [{
        id: 'url',
        label: formatMessage({
            defaultMessage: 'URL', id: 'newsstand.layout.linkModal.linkTypes.url'
        })
    }, {
        id: 'article',
        label: formatMessage({
            defaultMessage: 'Publication', id: 'newsstand.layout.linkModal.linkTypes.article'
        })
    }, {
        id: 'document',
        label: formatMessage({
            defaultMessage: 'Supplement', id: 'newsstand.layout.linkModal.linkTypes.supplement'
        })
    }];

    const emptyValues = {
        type: null, url: null, articleId: null, pageId: null, documentId: null
    };

    const {
        control, reset, handleSubmit, formState: { isValid }
    } = useForm({
        mode: 'onBlur'
    });

    // Set the previously entered values when the modal is shown
    useEffect(() => {
        if (show) reset(values);
    }, [show]);

    const [linkType, articleId] = useWatch({ control, name: ['type', 'articleId'] });

    const closeModal = () => {
        reset(emptyValues);
        closeHandler();
    };

    const submitHandler = async (formData) => {
        await saveHandler(formData);
        reset(emptyValues);
    };

    return (
        <FormContainer>
            <DefaultDialog
                closeHandler={closeModal}
                saveButton={(
                    <Button
                        disabled={!isValid}
                        onClick={handleSubmit(submitHandler)}
                        size='large'
                        type='submit'
                        variant='contained'
                    >
                        <FormattedMessage
                            defaultMessage='Add link'
                            id='newsstand.layout.linkModal.submitButton'
                        />
                    </Button>
                )}
                show={show}
                size='modalSm'
                title={(
                    formatMessage({
                        defaultMessage: 'Add link',
                        id: 'newsstand.layout.linkModal.title'
                    })
                )}
            >
                <Grid container direction='column' spacing={3}>
                    {show && (
                        <Grid alignItems='center' container>
                            <Grid xs={5}>
                                <Typography variant='medium500'>
                                    <FormattedMessage
                                        defaultMessage='Link this block to'
                                        id='newsstand.layout.linkModal.link'
                                    />
                                </Typography>
                            </Grid>
                            <Grid xs={7}>
                                <SelectElement
                                    control={control}
                                    label={(
                                        <FormattedMessage
                                            defaultMessage='Link type'
                                            id='newsstand.layout.linkModal.linkType'
                                        />
                                    )}
                                    name='type'
                                    options={linkTypes}
                                    validation={{ required: true }}
                                />
                            </Grid>
                        </Grid>
                    )}
                    {linkType === 'url' && (
                        <Grid alignItems='center' container>
                            <Grid xs={5}>
                                <Typography variant='medium500'>
                                    <FormattedMessage
                                        defaultMessage='Enter the web link'
                                        id='newsstand.layout.linkModal.url'
                                    />
                                </Typography>
                            </Grid>
                            <Grid xs={7}>
                                <TextFieldElement
                                    control={control}
                                    name='url'
                                    placeholder='https://'
                                    required
                                    type='url'
                                    validation={{
                                        validate: (value) => {
                                            if (!isValidUrl(value)) {
                                                return formatMessage({
                                                    defaultMessage: 'Invalid URL', id: 'form.validation.invalidUrl'
                                                });
                                            }
                                            return true;
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    )}
                    {linkType === 'article' && (
                        <Grid container direction='column' rowSpacing={3}>
                            <Grid alignItems='center' container>
                                <Grid xs={5}>
                                    <Typography variant='medium500'>
                                        <FormattedMessage
                                            defaultMessage='Select a publication'
                                            id='newsstand.layout.linkModal.publication'
                                        />
                                    </Typography>
                                </Grid>
                                <Grid xs={7}>
                                    <Select
                                        control={control}
                                        label={formatMessage({
                                            defaultMessage: 'Select a publication',
                                            id: 'newsstand.layout.linkModal.publication'
                                        })}
                                        name='articleId'
                                        options={options1}
                                        sx={{ width: '100%' }}
                                        validation={{ required: 'Publication is required' }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid alignItems='center' container>
                                <Grid xs={5}>
                                    <Typography variant='medium500'>
                                        <FormattedMessage
                                            defaultMessage='Select a page'
                                            id='newsstand.layout.linkModal.article.page'
                                        />
                                    </Typography>
                                </Grid>
                                <Grid xs={7}>
                                    <Select
                                        control={control}
                                        label={formatMessage({
                                            defaultMessage: 'Select a page',
                                            id: 'newsstand.layout.linkModal.article.page'
                                        })}
                                        name='pageId'
                                        options={options2[articleId] || []}
                                        sx={{ width: '100%' }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                    {linkType === 'document' && (
                        <Grid alignItems='center' container>
                            <Grid xs={5}>
                                <Typography variant='medium500'>
                                    <FormattedMessage
                                        defaultMessage='Select a document'
                                        id='newsstand.layout.linkModal.article.document'
                                    />
                                </Typography>
                            </Grid>
                            <Grid xs={7}>
                                <SelectElement
                                    control={control}
                                    disabled={!docs.length}
                                    label={(
                                        <FormattedMessage
                                            defaultMessage='Select a document'
                                            id='newsstand.layout.linkModal.article.document'
                                        />
                                    )}
                                    name='documentId'
                                    options={[
                                        ...docs.map((value) => ({
                                            id: value.id, label: value.label,
                                        }))
                                    ]}
                                    validation={{ required: true }}
                                />
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </DefaultDialog>
        </FormContainer>
    );
};

LinkModal.propTypes = {
    closeHandler: PropTypes.func.isRequired,
    documents: PropTypes.array,
    publicationArticles: PropTypes.object,
    saveHandler: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    values: PropTypes.object.isRequired,
};

LinkModal.defaultProps = {
    publicationArticles: {}, documents: []
};

const Layout = (props) => {
    const {
        getData, publicationArticles, submitData
    } = props;
    const [showLinkModal, setShowLinkModal] = React.useState(false);
    const [currentEditElement, setCurrentEditElement] = React.useState(null);
    const [data, setData] = React.useState(null);
    const { triggerReload } = useAccountInfo();

    const blockPositions = ['topLeft', 'topRight', 'bottomLeft', 'bottomRight'];
    const rectanglePositions = ['left', 'right'];

    const {
        clearErrors, control, handleSubmit,
        reset, setValue, formState: { isValid },
    } = useForm({
        defaultValues: async () => {
            const response = await getData();
            setData(response);
            return response;
        }
    });
    const values = useWatch({ control });
    const { roundedEdges } = values;
    const blocks = values.blocks || {};
    const rectangles = values.rectangles || {};
    const errorLink = {
        blocks: {}, rectangles: {}
    };
    blockPositions.forEach((position) => {
        errorLink.blocks[position] = blocks[position] && blocks[position].image && !blocks[position].link;
    });
    rectanglePositions.forEach((position) => {
        errorLink.rectangles[position] = (
            rectangles[position]
            && rectangles[position].image
            && !rectangles[position].link
        );
    });

    const updateImageValue = (name, value, returnValue) => {
        const [name1, name2] = name.split('.');
        let toEdit = name2 ? (values[name1] && values[name1][name2]) : (values[name1]);

        if (!toEdit) toEdit = { image: null, link: null };

        toEdit.image = value;

        if (returnValue) {
            return toEdit;
        }
        setValue(name, toEdit, { shouldValidate: true });
        return null;
    };

    const updateLinkValue = (name, value) => {
        const [name1, name2] = name.split('.');
        let toEdit = name2 ? (values[name1] && values[name1][name2]) : (values[name1]);
        if (!toEdit) toEdit = { image: null, link: null };
        toEdit.link = value;
        setValue(name, toEdit, { shouldValidate: true });
        clearErrors(name);
    };

    /**
     * Delete the uploaded image
     * @param {string} name
     */
    const deleteUpload = (name) => {
        updateImageValue(name, null);
        updateLinkValue(name, null);
    };

    /**
     * Get the current link settings for the selected block/rectangle
     * @param {string} element
     * @returns {*|{}}
     */
    const getLinkSettings = (element) => {
        const [name1, name2] = element.split('.');
        if (!values[name1]) return {};
        return name2 ? values[name1][name2]?.link || {} : values[name1]?.link || {};
    };

    /**
     * Update the link settings for the selected block/rectangle
     * @param {string} element
     * @param {object} linkSettings
     * @returns {*|{}}
     */
    const setLinkSettings = (element, linkSettings) => {
        const newLinkSettings = {
            type: linkSettings.type,
        };

        switch (linkSettings.type) {
            case 'url':
                newLinkSettings.url = linkSettings.url;
                break;
            case 'article':
                newLinkSettings.articleId = linkSettings.articleId;
                newLinkSettings.pageId = linkSettings.pageId;
                break;
            case 'document':
                newLinkSettings.documentId = data?.documents.some(
                    (item) => item.id === linkSettings.documentId
                ) ? linkSettings.documentId : null;

                newLinkSettings.pageId = 1;
                break;
            default:
                break;
        }

        updateLinkValue(element, newLinkSettings);
    };

    /**
     * Open the modal to edit the link settings of the selected block/rectangle
     * @param {string} element
     * @param linkData
     */
    const editLink = (element, linkData) => {
        setCurrentEditElement(element);
        if (linkData) {
            setLinkSettings(element, linkData);
        }
        setShowLinkModal(true);
    };

    /**
     * Save the new link settings for the selected block/rectangle
     * @param {object} linkValues
     */
    const saveLink = (linkValues) => {
        setLinkSettings(currentEditElement, linkValues);
        setShowLinkModal(false);
    };

    const submitDataSuccess = async () => {
        const newData = await getData();
        reset(newData);
        triggerReload();
    };

    return (
        <>
            <FormContainer>
                <Grid className='alternate-rows' container direction='column' spacing={2} sx={{ px: 2 }} xs={12}>
                    <Grid container>
                        <Grid xs={4}>
                            <Typography variant='sectionHeader'>
                                <FormattedMessage
                                    defaultMessage='Layout'
                                    id='newsstand.layout.layout'
                                />
                            </Typography>
                        </Grid>
                        <Grid alignItems='center' container direction='column' xs={8}>
                            <Grid container direction='column' sx={{ pb: 4 }} xs={12}>
                                <SwitchBox
                                    control={control}
                                    label={(
                                        <FormattedMessage
                                            defaultMessage='4 x Block'
                                            id='newsstand.layout.showBlocks'
                                        />
                                    )}
                                    name='showBlocks'
                                >
                                    <Grid container spacing={2} sx={{ mt: 1, pr: 2 }} xs={12}>
                                        {blockPositions.map((position) => (
                                            <Grid
                                                key={`block.${position}`}
                                                container
                                                direction='column'
                                                spacing={0}
                                                xs={6}
                                            >
                                                <Grid>
                                                    <InputImageDropzone
                                                        control={control}
                                                        customValue={{
                                                            get: () => blocks[`${position}`]?.image,
                                                            set: (value) => (
                                                                updateImageValue(
                                                                    `blocks.${position}`,
                                                                    value,
                                                                    true
                                                                )
                                                            )
                                                        }}
                                                        maxFileSize={10}
                                                        minimumImageSize={{ width: 300, height: 300 }}
                                                        name={`blocks.${position}`}
                                                        onChange={(value) => {
                                                            if (value) {
                                                                editLink(`blocks.${position}`);
                                                            }
                                                        }}
                                                        previewStyle={{
                                                            aspectRatio: 1, borderRadius: roundedEdges ? 1 : 0
                                                        }}
                                                        required
                                                        style={{ aspectRatio: 1 }}
                                                        validate={(value) => {
                                                            if (!value) {
                                                                return 'The image field is required';
                                                            }
                                                            if (!blocks[`${position}`]?.link) {
                                                                return 'The link field is required';
                                                            }
                                                            return true;
                                                        }}
                                                    />
                                                </Grid>
                                                {blocks[`${position}`]?.image && (
                                                    <ActionButtons
                                                        deleteUpload={deleteUpload}
                                                        editLink={() => {
                                                            editLink(`blocks.${position}`, blocks[`${position}`]?.link);
                                                        }}
                                                        errorLink={errorLink.blocks[position]}
                                                        linkData={blocks[`${position}`]?.link}
                                                        name={`blocks.${position}`}
                                                    />
                                                )}
                                            </Grid>
                                        ))}
                                    </Grid>
                                </SwitchBox>
                                <SwitchBox
                                    control={control}
                                    label={(
                                        <FormattedMessage
                                            defaultMessage='2 x Rectangle'
                                            id='newsstand.layout.showRectangles'
                                        />
                                    )}
                                    name='showRectangles'
                                >
                                    <Grid container spacing={2} sx={{ mt: 1, pr: 2 }} xs={12}>
                                        {rectanglePositions.map((position) => (
                                            <Grid
                                                key={`rectangle.${position}`}
                                                container
                                                direction='column'
                                                spacing={0}
                                                xs={6}
                                            >
                                                <Grid>
                                                    <InputImageDropzone
                                                        control={control}
                                                        customValue={{
                                                            get: () => rectangles[`${position}`]?.image,
                                                            set: (value) => (
                                                                updateImageValue(
                                                                    `rectangles.${position}`,
                                                                    value,
                                                                    true
                                                                )
                                                            )
                                                        }}
                                                        maxFileSize={10}
                                                        minimumImageSize={{ width: 300, height: 150 }}
                                                        name={`rectangles.${position}`}
                                                        onChange={(value) => {
                                                            if (value) {
                                                                editLink(`rectangles.${position}`);
                                                            }
                                                        }}
                                                        previewStyle={{
                                                            aspectRatio: 2, borderRadius: roundedEdges ? 1 : 0
                                                        }}
                                                        previewVariant='top-stretch'
                                                        required
                                                        style={{ aspectRatio: 2 }}
                                                        validate={(value) => {
                                                            if (!value) {
                                                                return 'The image field is required';
                                                            }
                                                            if (!rectangles[`${position}`]?.link) {
                                                                return 'The link field is required';
                                                            }
                                                            return true;
                                                        }}
                                                    />
                                                </Grid>
                                                {rectangles[`${position}`]?.image && (
                                                    <ActionButtons
                                                        deleteUpload={deleteUpload}
                                                        editLink={() => {
                                                            editLink(
                                                                `rectangles.${position}`,
                                                                rectangles[`${position}`]?.link
                                                            );
                                                        }}
                                                        errorLink={errorLink.rectangles[position]}
                                                        linkData={rectangles[`${position}`]?.link}
                                                        name={`rectangles.${position}`}
                                                    />
                                                )}
                                            </Grid>
                                        ))}
                                    </Grid>
                                </SwitchBox>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        sx={{
                            pb: 2, pt: 3, mt: 1, mb: 2
                        }}
                    >
                        <Grid xs={4}>
                            <Typography variant='sectionHeader'>
                                <FormattedMessage
                                    defaultMessage='Corner radius'
                                    id='newsstand.layout.cornerRadius'
                                />
                            </Typography>
                        </Grid>
                        <Grid container xs={8}>
                            <SwitchBox
                                control={control}
                                label={(
                                    <FormattedMessage
                                        defaultMessage='Apply rounded edges to interface'
                                        id='newsstand.layout.cornerRadius.label'
                                    />
                                )}
                                name='roundedEdges'
                            />
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid
                            display='flex'
                            justifyContent='flex-end'
                            xs={12}
                        >
                            <RequestButton
                                disabled={!isValid}
                                injectFormData={handleSubmit}
                                onSuccess={submitDataSuccess}
                                sendData={submitData}
                                size='large'
                                type='submit'
                                variant='contained'
                            >
                                <FormattedMessage defaultMessage='Update' id='common.update' />
                            </RequestButton>
                        </Grid>
                    </Grid>
                </Grid>
            </FormContainer>
            <LinkModal
                closeHandler={() => setShowLinkModal(false)}
                documents={data?.documents}
                publicationArticles={publicationArticles}
                saveHandler={saveLink}
                show={showLinkModal}
                values={currentEditElement ? getLinkSettings(currentEditElement) : {}}
            />
        </>
    );
};
Layout.propTypes = {
    getData: PropTypes.func.isRequired,
    publicationArticles: PropTypes.object.isRequired,
    submitData: PropTypes.func.isRequired,
};

export default Layout;
