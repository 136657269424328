import { saveAs } from 'file-saver';

/**
 * Downloads a file.
 *
 * @param blob The content of the file to download, either as a Blob or a string.
 * @param filename The name to give the downloaded file.
 */
async function download(blob, filename) {
    saveAs(blob, filename);
}

export default {
    download,
};
