import React, {
    memo, useEffect, useRef, useState
} from 'react';
import { Box, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { CloudUploadOutlined as UploadFileIcon } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { Dropzone } from '@files-ui/react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form-mui';

import { useTheme } from '@mui/material/styles';
import useDimension from '../hooks/useDimension';
import {
    cropImage, cropImageIfNeeded, getImageDimensions, resizeImageIfNeeded
} from '../utils/image';

const defaultCropConfig = {
    unit: 'px',
    x: 0,
    y: 0
};

function InputImageDropzone({
    control,
    customValue,
    disabled,
    name,
    maxFileSize,
    minimumImageSize,
    onChange,
    required,
    style,
    previewStyle,
    previewVariant,
    validate
}) {
    const [imageDataUrl, setImageDataUrl] = useState(null);

    const divRef = useRef(null);
    const theme = useTheme();

    const { height, width } = useDimension(divRef);

    const { field, fieldState: { invalid } } = useController({
        name,
        control,
        disabled,
        rules: {
            required,
            validate
        }
    });
    let { value } = field;

    if (customValue && customValue.get) {
        value = customValue.get(value);
    }

    // Preview style based on variant
    let imageStyle = {};
    let extraPreviewStyle = {};
    switch (previewVariant) {
        case 'center-full':
            imageStyle = {
                maxWidth: '99%',
                maxHeight: '99%',
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                borderRadius: previewStyle.borderRadius === 1 ? 8 : null,
            };
            break;
        case 'top-stretch':
            imageStyle = {
                width: '100%',
                objectFit: 'fill',
                position: 'absolute',
                top: 0,
                left: 0,
                borderRadius: previewStyle.borderRadius === 1 ? 8 : null,
            };
            extraPreviewStyle = {
                overflow: 'hidden',
            };
            break;
        default:
            break;
    }

    const handleImageChange = (imageUrl) => {
        let newValue = imageUrl;

        if (customValue && customValue.set) {
            newValue = customValue.set(newValue);
        }
        field.onChange(newValue);
        if (onChange) {
            onChange(newValue);
        }
    };

    const handleImageCrop = async (imgUrl, cropWidth) => {
        const calculatedCropConfig = { width: cropWidth, ...defaultCropConfig };

        return cropImage(
            imgUrl,
            calculatedCropConfig,
            style.aspectRatio,
            true
        );
    };

    useEffect(() => {
        if (!imageDataUrl) return;

        const processImage = async () => {
            let processedImageUrl = await resizeImageIfNeeded(imageDataUrl);

            const { width: imgWidth, height: imgHeight } = await getImageDimensions(processedImageUrl);
            const smallestSide = Math.min(imgWidth, imgHeight);

            processedImageUrl = await cropImageIfNeeded(
                processedImageUrl,
                imgWidth,
                imgHeight,
                smallestSide,
                style.aspectRatio,
                handleImageCrop
            );

            handleImageChange(processedImageUrl);
            setImageDataUrl(null);
        };

        processImage();
    }, [imageDataUrl]);

    if (value) {
        return (
            <Box
                ref={divRef}
                className='dropzone-image'
                sx={{
                    border: `1px dashed ${theme.palette.border.imageDropzone}`,
                    borderRadius: previewStyle.borderRadius === 1 ? 1 : null,
                    display: 'flex',
                    position: 'relative',
                    width: '100%',
                    ...extraPreviewStyle,
                    ...previewStyle,
                    ...(invalid && { border: `1px solid ${theme.palette.primary.delete}` })
                }}
            >
                <div style={{
                    height,
                    width
                }}
                />
                <img
                    alt={`dropzone ${name}`}
                    src={value}
                    style={imageStyle}
                />
            </Box>
        );
    }

    const onFileSelected = (files) => {
        const selectedFile = files[0].file;
        const reader = new FileReader();

        reader.onload = async (event) => {
            setImageDataUrl(event.target.result);
        };

        reader.readAsDataURL(selectedFile);
    };

    return (
        <Box ref={divRef} sx={{ ...style }}>
            <Dropzone
                accept='image/png, image/jpeg'
                background={theme.palette.background.lightCyan}
                color={theme.palette.border.imageDropzone}
                disabled={field.disabled}
                footer={false}
                header={false}
                label='Upload a file'
                minHeight='124px'
                onChange={onFileSelected}
                style={{
                    alignSelf: 'flex-start',
                    width: '100%',
                    height: '100%',
                    paddingLeft: 8,
                    paddingRight: 8,
                    borderRadius: previewStyle.borderRadius === 0 ? 0 : null,
                    ...(invalid && { border: `1px solid ${theme.palette.primary.delete}` })
                }}
            >
                <Grid
                    alignItems='center'
                    container
                    direction='column'
                    sx={{
                        py: 1,
                        px: 1
                    }}
                >
                    <UploadFileIcon
                        sx={{
                            color: 'text.light',
                            height: 38,
                            width: 38
                        }}
                    />
                    <span>
                        <Typography sx={{ color: 'text.darkCharcoal' }} variant='small500'>
                            <FormattedMessage
                                defaultMessage='Drag an image here or '
                                id='fileInput.dragImage'
                            />
                            <Typography
                                component='span'
                                sx={{
                                    color: 'primary.main',
                                    cursor: 'pointer'
                                }}
                                variant='small600'
                            >
                                <FormattedMessage
                                    defaultMessage='upload a file'
                                    id='fileInput.uploadFile'
                                />
                            </Typography>
                            .
                        </Typography>
                    </span>
                    <Typography
                        sx={{
                            color: 'text.darkCharcoal',
                            mt: 1.5
                        }}
                        variant='smaller400'
                    >
                        <FormattedMessage
                            defaultMessage='Max {fileSize}MB. '
                            id='fileInput.maxFileSize'
                            values={{
                                fileSize: maxFileSize
                            }}
                        />
                        {minimumImageSize && (
                            <FormattedMessage
                                defaultMessage='Min image size: {width}x{height}px'
                                id='fileInput.minimumImageSize'
                                values={{
                                    width: minimumImageSize?.width,
                                    height: minimumImageSize?.height
                                }}
                            />
                        )}
                    </Typography>
                </Grid>
            </Dropzone>
        </Box>
    );
}

InputImageDropzone.propTypes = {
    control: PropTypes.object.isRequired,
    customValue: PropTypes.shape({
        get: PropTypes.func,
        set: PropTypes.func
    }),
    disabled: PropTypes.bool,
    maxFileSize: PropTypes.number,
    minimumImageSize: PropTypes.object,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    previewStyle: PropTypes.object,
    previewVariant: PropTypes.oneOf(['center-full', 'top-stretch']),
    required: PropTypes.bool,
    style: PropTypes.object,
    validate: PropTypes.func
};

InputImageDropzone.defaultProps = {
    customValue: null,
    disabled: false,
    maxFileSize: 5,
    minimumImageSize: null,
    onChange: null,
    previewVariant: 'center-full',
    previewStyle: {},
    required: false,
    style: {},
    validate: null
};

export default memo(InputImageDropzone);
