import React from 'react';
import PropTypes from 'prop-types';

import {
    Checkbox,
    InputAdornment,
    MenuItem,
    FormControl,
    Select,
    OutlinedInput,
} from '@mui/material';

import {
    ExpandMoreOutlined,
    FilterAltOutlined,
} from '@mui/icons-material';

const ExpandIcon = (props) => (
    <ExpandMoreOutlined
        {...props}
        sx={{
            mr: 0.9,
        }}
    />
);

export default function FilterSelect(props) {
    const {
        changeHandler,
        value,
        getValueText,
        options,
        multiple,
        minWidth
    } = props;

    return (
        <FormControl sx={{ minWidth, width: 'auto' }} variant='outlined'>
            <Select
                displayEmpty
                IconComponent={ExpandIcon}
                input={(
                    <OutlinedInput
                        startAdornment={(
                            <InputAdornment position='start'>
                                <FilterAltOutlined />
                            </InputAdornment>
                        )}
                    />
                )}
                multiple={multiple}
                onChange={changeHandler}
                renderValue={(currentValue) => getValueText(currentValue)}
                sx={{
                    borderRadius: '5px',
                    '.MuiSelect-select': { py: 1 },
                    '.MuiSvgIcon-root': { color: 'icon.mutedGray' }
                }}
                value={value}
            >
                {options?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {multiple && (
                            <Checkbox
                                checked={option.checked || false}
                            />
                        )}
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

FilterSelect.propTypes = {
    changeHandler: PropTypes.func.isRequired,
    getValueText: PropTypes.func.isRequired,
    minWidth: PropTypes.number,
    multiple: PropTypes.bool,
    options: PropTypes.array.isRequired,
    value: PropTypes.oneOfType([PropTypes.array, PropTypes.number]).isRequired,
};

FilterSelect.defaultProps = {
    minWidth: 260,
    multiple: false
};
