import PropTypes from 'prop-types';
import React from 'react';

import { styled } from '@mui/material/styles';
import { linearProgressClasses, LinearProgress } from '@mui/material';

const BlueGreenSlider = styled(LinearProgress)(({ theme }) => ({
    height: 8,
    borderRadius: theme.shape.borderRadius,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.common.white,
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: theme.shape.borderRadius,
        background: `linear-gradient(90deg, ${theme.palette.custom.azureBlue} 0%, ${theme.palette.primary.main} 100%)`
    },
}));

const ProgressSlider = (props) => {
    const { progress } = props;
    const value = Math.round(progress * 100);

    return (
        <BlueGreenSlider value={value} variant='determinate' />
    );
};

ProgressSlider.propTypes = {
    progress: PropTypes.number.isRequired
};

export default ProgressSlider;
