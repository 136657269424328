import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import StarterKit from '@tiptap/starter-kit';
import {
    MenuButtonBold,
    MenuButtonItalic,
    MenuButtonEditLink,
    MenuButtonBulletedList,
    MenuButtonOrderedList,
    MenuButtonRemoveFormatting,
    MenuButtonUndo,
    MenuButtonRedo,
    MenuControlsContainer,
    MenuDivider,
    MenuSelectHeading,
    RichTextEditor,
    LinkBubbleMenu,
    LinkBubbleMenuHandler
} from 'mui-tiptap';
import { Link } from '@tiptap/extension-link';
import PropTypes from 'prop-types';

const TextEditor = ({ value, onChange, onBlur }) => {
    const ref = React.createRef();
    const changeHandlerWrapper = () => {
        const editor = ref.current?.editor;
        if (editor && editor.getText()) {
            onChange(editor.getHTML());
        }
        onBlur();
    };

    useEffect(() => {
        const editor = ref.current?.editor;
        if (editor) {
            queueMicrotask(() => { editor.chain().setContent(value).run(); });
        }
    }, [value]);

    return (
        <Box
            onBlur={changeHandlerWrapper}
            sx={{
                '& .ProseMirror': {
                    height: 300,
                    overflowY: 'scroll',
                },
            }}
        >
            <RichTextEditor
                ref={ref}
                content={value}
                extensions={[StarterKit, LinkBubbleMenuHandler, Link]}
                renderControls={() => (
                    <MenuControlsContainer>
                        <MenuSelectHeading />
                        <MenuDivider />
                        <MenuButtonBold />
                        <MenuButtonItalic />
                        <MenuDivider />
                        <MenuButtonEditLink />
                        <MenuDivider />
                        <MenuButtonBulletedList />
                        <MenuButtonOrderedList />
                        <MenuDivider />
                        <MenuButtonRemoveFormatting />
                        <MenuDivider />
                        <MenuButtonUndo />
                        <MenuButtonRedo />
                        {/* Add more controls of your choosing here */}
                    </MenuControlsContainer>
                )}
                sx={{ maxHeight: 400 }}
            >
                {() => (
                    <LinkBubbleMenu />
                )}
            </RichTextEditor>
        </Box>
    );
};

TextEditor.propTypes = {
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
};

TextEditor.defaultProps = {
    value: '',
};

export default TextEditor;
