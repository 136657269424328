import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { useNavigate } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';

import {
    Unstable_Grid2 as Grid,
    List,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Typography,
    Popper,
    Fade,
    Paper,
    ClickAwayListener,
    MenuList,
    MenuItem,
    Chip,
    Stack,
    Button
} from '@mui/material';

import {
    MoreVert as MoreVertIcon,
    DeleteOutlined as DeleteIcon
} from '@mui/icons-material';

import { useSortFilterSearch } from '../contexts/SortFilterSearch';
import { useStatus } from './base/Status';
import { useApi, api, noCacheUrl } from './base/Request';

import StatusFilterSelect from '../components/StatusFilterSelect';
import { DialogTypes, useDialog } from './base/Dialog';
import CenteredCircularProgress from '../components/CenteredCircularProgress';
import FadeInBox from '../components/Animation/FadeInBox';
import DefaultFormattedDate from '../components/DefaultFormattedDate';

const Highlights = () => {
    const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const expiredIdsRef = useRef([]);

    const { setFilter, filter, clearAllFilters } = useSortFilterSearch('highlights');
    const { request } = useApi();
    const { createDialog } = useDialog();

    const getHighlightsFromResponse = (response) => {
        const highlights = response?.digiBanners;

        if (response.success && Array.isArray(highlights)) {
            return highlights;
        }

        console.error('error retrieving highlight data');

        return [];
    };

    const retrieveData = async () => {
        setIsLoading(true);
        const response = await request(api.getHighlights).send();

        expiredIdsRef.current.splice(0);

        const highlights = getHighlightsFromResponse(response).map((highlight) => {
            let status = 'active';

            if (highlight.isLocked) {
                status = 'locked';
            } else if (highlight.status === 'expired') {
                status = 'expired';
            } else if (highlight.dateFinal) {
                const endOfDay = dayjs.unix(highlight.dateFinal).endOf('day');

                if (dayjs().isAfter(endOfDay)) {
                    status = 'expired';
                    expiredIdsRef.current.push(highlight.id);
                }
            }
            return {
                id: highlight.id,
                image: noCacheUrl(highlight.imageUrl),
                startDate: highlight.dateStart && dayjs.unix(highlight.dateStart).toDate(),
                endDate: highlight.dateFinal && dayjs.unix(highlight.dateFinal).toDate(),
                linkedArticle: highlight.linkedArticleId,
                linkedPage: highlight.linkedPage,
                isLocked: highlight.isLocked,
                status
            };
        });

        setData({ highlights });
        setIsLoading(false);
    };

    useEffect(() => {
        retrieveData();
    }, []);

    const deleteHighlight = async (id) => {
        const dialog = createDialog({
            type: DialogTypes.Confirm
        });

        dialog.onConfirm(async () => {
            await request(api.deleteHighlight).send({ id });
            await retrieveData();
        });

        dialog.show();
    };

    const handleDeleteExpired = async () => {
        const dialog = createDialog({
            type: DialogTypes.Confirm
        });

        dialog.onConfirm(async () => {
            expiredIdsRef.current.forEach((id) => {
                request(api.deleteHighlight).send({ id });
            });

            await retrieveData();
        });

        dialog.show();
    };

    const handleDelete = async (id) => {
        await deleteHighlight(id);
        await retrieveData();
    };

    const handleLockToggle = async (id, highlight) => {
        await request(api.updateHighlight).send({ id, isLocked: !highlight.isLocked, });
        await retrieveData();
    };

    return (
        <HighlightsView
            clearAllFilters={clearAllFilters}
            data={data}
            filter={filter}
            handleDelete={handleDelete}
            handleDeleteExpired={handleDeleteExpired}
            handleLockToggle={handleLockToggle}
            isLoading={isLoading}
            setFilter={setFilter}
        />
    );
};

const HighlightsView = (props) => {
    const {
        data,
        filter,
        setFilter,
        clearAllFilters,
        handleDelete,
        handleDeleteExpired,
        handleLockToggle,
        isLoading
    } = props;

    const { formatMessage } = useIntl();

    return (
        <Grid container spacing={1}>
            <Grid
                alignItems='center'
                container
                direction='row'
                justifyContent='space-between'
                spacing={2}
                xs={12}
            >
                <Grid
                    alignItems='center'
                    direction='row'
                    display='flex'
                    item
                    xs={4}
                >
                    <StatusFilterSelect
                        changeHandler={(event) => {
                            setFilter('status', event.target.value);
                        }}
                        clearAllFilters={clearAllFilters}
                        filter={filter}
                    />
                </Grid>
                <Grid>
                    <Button
                        onClick={handleDeleteExpired}
                        variant='outlined'
                    >
                        <Stack
                            alignItems='center'
                            direction='row'
                            gap={1}
                        >
                            <DeleteIcon sx={{ height: 24 }} />
                            <Typography variant='medium500'>
                                <FormattedMessage defaultMessage='Delete expired' id='button.delete.expired' />
                            </Typography>
                        </Stack>
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <List>
                    {isLoading ? (
                        <CenteredCircularProgress />
                    ) : (
                        <FadeInBox>
                            {data?.highlights
                                ?.filter(
                                    (highlight) => !filter?.status?.length || filter.status.includes(highlight.status)
                                )
                                .map((highlight) => (
                                    <ListItem
                                        key={highlight.id}
                                        sx={{
                                            backgroundColor: 'common.white',
                                            borderRadius: 1,
                                            border: ({ palette }) => `1px solid ${palette.border.listItem}`,
                                            px: 1,
                                            py: 2,
                                            mb: 2
                                        }}
                                    >
                                        <ListItemRow
                                            formatMessage={formatMessage}
                                            handleDelete={() => handleDelete(highlight.id)}
                                            handleLockToggle={() => handleLockToggle(highlight.id, highlight)}
                                            highlight={highlight}
                                        />
                                    </ListItem>
                                ))}
                        </FadeInBox>
                    )}
                </List>
            </Grid>
        </Grid>
    );
};

HighlightsView.propTypes = {
    clearAllFilters: PropTypes.func.isRequired,
    data: PropTypes.object,
    filter: PropTypes.object,
    handleDelete: PropTypes.func.isRequired,
    handleDeleteExpired: PropTypes.func.isRequired,
    handleLockToggle: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    setFilter: PropTypes.func.isRequired,
};

HighlightsView.defaultProps = {
    data: null,
    filter: null
};

const ListItemRow = (props) => {
    const {
        highlight,
        formatMessage,
        handleDelete,
        handleLockToggle
    } = props;

    const [openMenu, setOpenMenu] = useState(false);
    const anchorRef = useRef(null);
    const navigate = useNavigate();
    const { getContent, getType } = useStatus(highlight.status);

    const handleToggle = () => {
        setOpenMenu((prevState) => !prevState);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpenMenu(false);
    };

    const handleEdit = () => {
        navigate(`/highlights/edit/${highlight.id}`);
    };

    const prevOpenMenu = React.useRef(openMenu);

    useEffect(() => {
        if (prevOpenMenu.current === true && openMenu === false) {
            anchorRef.current.focus();
        }

        prevOpenMenu.current = openMenu;
    }, [openMenu]);

    return (
        <Grid container spacing={4} xs={12}>
            <Grid xs>
                <ListItemAvatar sx={{
                    height: 75,
                    paddingLeft: 1
                }}
                >
                    <Avatar
                        src={highlight.image}
                        sx={{
                            height: '100%',
                            width: '150px',
                            borderRadius: 0.5
                        }}
                        variant='square'
                    />
                </ListItemAvatar>
            </Grid>
            <Grid alignItems='center' container spacing={2} xs={4}>
                <Grid xs={6}>
                    <ListItemText
                        primary={highlight.startDate
                            ? <DefaultFormattedDate value={highlight.startDate} />
                            : formatMessage({
                                defaultMessage: 'Date not set',
                                id: 'common.label.noDateSet',
                            })}
                        secondary={formatMessage({
                            defaultMessage: 'Start date',
                            id: 'common.item.startDate'
                        })}
                    />
                </Grid>
                <Grid xs={6}>
                    <ListItemText
                        primary={highlight.endDate
                            ? <DefaultFormattedDate value={highlight.endDate} />
                            : formatMessage({
                                defaultMessage: 'Date not set',
                                id: 'common.label.noDateSet',
                            })}
                        secondary={formatMessage({
                            defaultMessage: 'End date',
                            id: 'common.item.endDate'
                        })}
                    />
                </Grid>
            </Grid>
            <Grid alignItems='center' container spacing={2} xs={6}>
                <Grid rowSpacing={0} xs={9} />
                <Grid display='flex' xs={3}>
                    <Chip
                        color={getType()}
                        label={getContent()}
                        size='small'
                        variant='outlined'
                    />
                    <ListItemSecondaryAction>
                        <IconButton
                            ref={anchorRef}
                            edge='end'
                            onClick={handleToggle}
                            size='large'
                        >
                            <MoreVertIcon />
                        </IconButton>
                        <Popper
                            anchorEl={anchorRef.current}
                            open={openMenu}
                            placement='bottom-start'
                            role={undefined}
                            sx={{
                                borderRadius: 0.5,
                                boxShadow: 'none',
                            }}
                            transition
                        >
                            {({ TransitionProps, placement }) => (
                                <Fade
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin:
                                            placement === 'bottom-start' ? 'left top' : 'left bottom',
                                    }}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <MenuList
                                                aria-labelledby='composition-button'
                                                autoFocusItem={openMenu}
                                                id='composition-menu'
                                                sx={{
                                                    padding: 0
                                                }}
                                            >
                                                <MenuItem onClick={handleEdit}>
                                                    <FormattedMessage defaultMessage='Edit' id='common.item.edit' />
                                                </MenuItem>
                                                <MenuItem onClick={handleLockToggle}>
                                                    {highlight.isLocked ? (
                                                        <FormattedMessage
                                                            defaultMessage='Unlock'
                                                            id='common.item.unlock'
                                                        />
                                                    ) : (
                                                        <FormattedMessage
                                                            defaultMessage='Lock'
                                                            id='common.item.lock'
                                                        />
                                                    )}
                                                </MenuItem>
                                                <MenuItem onClick={handleDelete}>
                                                    <FormattedMessage
                                                        defaultMessage='Delete'
                                                        id='common.item.delete'
                                                    />
                                                </MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Fade>
                            )}
                        </Popper>
                    </ListItemSecondaryAction>
                </Grid>
            </Grid>
        </Grid>
    );
};

ListItemRow.propTypes = {
    formatMessage: PropTypes.func.isRequired,
    handleDelete: PropTypes.func.isRequired,
    handleLockToggle: PropTypes.func.isRequired,
    highlight: PropTypes.shape({
        endDate: PropTypes.instanceOf(Date).isRequired,
        id: PropTypes.number.isRequired,
        image: PropTypes.string.isRequired,
        isLocked: PropTypes.bool.isRequired,
        startDate: PropTypes.instanceOf(Date).isRequired,
        status: PropTypes.string.isRequired,
    }).isRequired,
};

export default Highlights;
