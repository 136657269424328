import React from 'react';
import PropTypes from 'prop-types';
import {
    Box, FormControl, InputLabel, MenuItem, Select
} from '@mui/material';
import { Controller } from 'react-hook-form-mui';

const CustomMenuItem = ({ children, groupHead, ...props }) => {
    const { sx } = props;
    let disabled = false;
    let newSx = { ...sx, opacity: 0.5 };

    if (groupHead) {
        disabled = true;
        newSx = { ...newSx, fontWeight: 'bold', opacity: 1 };
    }

    return (
        <MenuItem
            key={props.value}
            disabled={disabled}
            {...props}
            sx={newSx}
        >
            {children}
        </MenuItem>
    );
};
CustomMenuItem.propTypes = {
    children: PropTypes.node,
    groupHead: PropTypes.bool,
    sx: PropTypes.object,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
CustomMenuItem.defaultProps = {
    children: null,
    groupHead: false,
    value: null,
    sx: {}
};

export default function SelectCustom({
    control, label, defaultValue, options, name, sx, required
}) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                ...sx
            }}
        >
            <FormControl sx={{ width: '100%' }} variant='outlined'>
                <InputLabel
                    id={`${name}-selectlabel`}
                    sx={{ backgroundColor: 'common.white' }}
                >
                    {label}
                </InputLabel>
                <Controller
                    control={control}
                    defaultValue={defaultValue}
                    id={name}
                    name={name}
                    render={({ field }) => (
                        <Select
                            labelId={`${name}-select`}
                            sx={{ minWidth: 200 }}
                            {...field}
                        >
                            {options.map((option) => (
                                <CustomMenuItem
                                    key={option.key}
                                    groupHead={option.groupHead}
                                    value={option.key}
                                >
                                    {option.value}
                                </CustomMenuItem>
                            ))}
                        </Select>
                    )}
                    rules={{
                        required,
                    }}
                />
            </FormControl>
        </Box>
    );
}

SelectCustom.propTypes = {
    control: PropTypes.object.isRequired,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.array.isRequired,
    required: PropTypes.bool,
    sx: PropTypes.object,
};
SelectCustom.defaultProps = {
    required: true,
    defaultValue: '',
    sx: {}
};
