import { styled, keyframes, Box } from '@mui/material';

const fadeInKeyframes = keyframes`
  from { opacity: 0;  transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
`;

const FadeInBox = styled(Box)`
  animation: ${fadeInKeyframes} 0.3s ease-in-out;
`;

export default FadeInBox;
